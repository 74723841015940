import Icon from "../../Assets/icons/sadIcon.svg";
import { EmptyContent } from "../../styles/main";

type Props = {
    text: string;
}

const EmptyResult = (props: Props) => {
    return (
        <EmptyContent>
            <img src={Icon} alt="sem item"/> 
            <p>
                {props.text}
            </p>
        </EmptyContent>
    )
}

export default EmptyResult;