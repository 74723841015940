import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { MeetingGroupContainer, MeetingGroupItem, WithouEventCt } from "./styles";
import { FiCheckSquare } from "react-icons/fi";
import { LuCalendarX2 } from "react-icons/lu";

type Props = {
    meetings: any;
}

const AvailableMeetings = (props: Props) => {

    const meeting = props.meetings.map(meeting => {
        return (
            <MeetingGroupItem key={meeting.key}>
                <FiCheckSquare size={24} />
                <p>
                {format(new Date(meeting.start_date), 'E, dd \'de\' LLLL \'de\' yyyy \'das\' HH:mm ' , { locale: ptBR } )} 
                às {format(new Date(meeting.end_date),'HH:mm')}
                </p>
            </MeetingGroupItem>
        )
    })

    const content = () => {
        if (props.meetings.length >= 1 ) {
            return <>{meeting}</>
        } else {
            return <WithouEventCt>
                <LuCalendarX2 size={64} color="#ccc"/>
                <p>
                Nenhuma reunião <br></br> adicionada.
                </p>
            </WithouEventCt>
        }
    }

    return (
        <MeetingGroupContainer>
            {content()}
        </MeetingGroupContainer>
    )
}

export default AvailableMeetings;