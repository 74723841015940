import { addMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { TimerRecovery } from "./styles";

const CountdownTimer = (props) => {
    const [ endTimer, setEndTimer ] = useState(0);
    const [ minutes, setMinutes ] = useState('00');
    const [ seconds, setSeconds ] = useState('00');
    const [ timerStarted, setTimerStarted ] = useState(false);

    useEffect(() => {
        getTimer();
    },[])

    useEffect(() => {
        if (endTimer > 0) {
            startTimer();
        }
    }, [endTimer])

    const getTimer = () => {
        let countDownDate = addMinutes(new Date(), 5).getTime();
        setEndTimer(countDownDate);
    }

    const formatTime = (time) => {
        return (time < 10) ? '0' + time.toString() : time.toString();
    }

    const startTimer = () => {
        setTimerStarted(true);
        const interval = setInterval(() => {
            let now = new Date().getTime();

            let distance = endTimer - now;

            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            let valueBar = parseInt(minutes.toString() + formatTime(seconds)); 
            
            if (valueBar >= 0) {
                setMinutes(formatTime(minutes));
                setSeconds(formatTime(seconds));
            } else {
                clearInterval(interval);
            }
        }, 1000);
    }


    return (
        <TimerRecovery>
            <p>Seu código expira em: <strong>{minutes}m e {seconds}s</strong></p>
        </TimerRecovery>
    )
}

export default CountdownTimer;