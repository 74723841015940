import { Dropdown, User, Button } from "@nextui-org/react";
import { useAuth } from "../../Auth/AuthProvider";
import LogoutIcon from "../../Assets/icons/logoutIcon.svg";

const AvatarMenu = () => {
    const { user, signOut } = useAuth();
    
    const renderUserRole = () => {
        switch(user.userRole) {
            case 'MENTOR':
                return 'Mentor'
            case 'AFFILIATED':
                return 'Afiliado'
            case 'CUSTOMER':
                return 'Aluno'
            case 'ADMIN': 
                return 'Administrativo'
        }
    }

    return (
        <Dropdown placement="bottom-left">
            <Dropdown.Trigger>
            <User
              bordered
              squared
              as="button"
              size="md"
              text={user.name}
              name={user.name}
            //   description={(user.userRole === "MENTOR" ? 'Mentor': 'Afiliado')}
              description={renderUserRole()}
            />
            </Dropdown.Trigger>
            <Dropdown.Menu aria-label="User Actions">
            <Dropdown.Item
            >
                <Button 
                light 
                color="default"
                icon={<img src={LogoutIcon} alt="sair"/>}
                onPress={() => signOut()}
                >
                Sair
                </Button>
              
            </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        // <AvatarCt>  
        //     <Avatar 
        //     squared 
        //     zoomed
        //     size="md"
        //     text={user.name} 
        //     />
            
        // </AvatarCt>
    )
}

export default AvatarMenu;

// <Dropdown placement="bottom-left">
//           <Dropdown.Trigger>
//             <User
//               bordered
//               as="button"
//               size="lg"
//               color="primary"
//               name="Tony Reichert"
//               description="@tonyreichert"
//               src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
//             />
//           </Dropdown.Trigger>
//           <Dropdown.Menu color="primary" aria-label="User Actions">
//             <Dropdown.Item key="profile" css={{ height: "$18" }}>
//               <Text b color="inherit" css={{ d: "flex" }}>
//                 Signed in as
//               </Text>
//               <Text b color="inherit" css={{ d: "flex" }}>
//                 zoey@example.com
//               </Text>
//             </Dropdown.Item>
//             <Dropdown.Item key="settings" withDivider>
//               My Settings
//             </Dropdown.Item>
//             <Dropdown.Item key="team_settings">Team Settings</Dropdown.Item>
//             <Dropdown.Item key="analytics" withDivider>
//               Analytics
//             </Dropdown.Item>
//             <Dropdown.Item key="system">System</Dropdown.Item>
//             <Dropdown.Item key="configurations">Configurations</Dropdown.Item>
//             <Dropdown.Item key="help_and_feedback" withDivider>
//               Help & Feedback
//             </Dropdown.Item>
//             <Dropdown.Item key="logout" color="error" withDivider>
//               Log Out
//             </Dropdown.Item>
//           </Dropdown.Menu>
//         </Dropdown>