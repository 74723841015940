import { Table, Row, Col, Text, Badge, Tooltip } from "@nextui-org/react";
import TimeIcon from '../../Assets/icons/timeIcon.svg';
import { useEffect } from "react";
import { format } from "date-fns";
import { BsClockHistory, BsClock, BsExclamationCircle } from "react-icons/bs";
import { FiRepeat, FiCheckCircle } from "react-icons/fi";
import { EmptyTableLine } from "../../styles/main";

export const OrdersList = (props) => {
    const columns = [
        { name: "DATA", uid: "data" },
        { name: "MENTORIA", uid: "mentoria" },
        { name: "ALUNO", uid: "aluno" },
        { name: "VALOR", uid: "valor" },
        { name: "TAXAS", uid: 'taxas'},
        { name: "", uid: 'transferred'},
        { name: "STATUS", uid: 'status'}
      ];

    const renderBadgeStatus = (status) => {
      switch(status) {
        case 'PROCESSING':
          return <Badge size="sm" variant="bordered">Pendente</Badge>
        case 'APPROVED':
          return <Badge size="sm" color="success">Aprovada</Badge>
      }
    }

    const renderOrderTransferred = (transferred, status) => {
      
      switch(transferred) {
        case 0:
          if (status !== "PROCESSING") {
            return (
              <Tooltip content={"Aguardando liberação"}>
                <BsClockHistory color="#e47e00"/>
              </Tooltip>  
            ) 
          } else {
            return (
              <Tooltip content={"Aguardando Pagamento"}>
                <BsExclamationCircle color="#8b8b8b"/>
              </Tooltip>
            )
          }
        case 1:
          return (
            <Tooltip content={"Disponível para saque"}>
              <BsClock color="#029d7c"/>
            </Tooltip>  
          )
        case 2:
          return (
            <Tooltip content={"Transferência em andamento"}>
              <FiRepeat color='#17a2b8'/>
            </Tooltip>  
          )
        case 3:
          return (
            <Tooltip content={"Saque concluído"}>
              <FiCheckCircle color='#139440'/>
            </Tooltip>  
          ) 
      }
    }

    const renderCell = (item, columnKey) => {
        // console.log(item);
        const cellValue = item[columnKey];
        switch (columnKey) {
          case "data":
            return (
                <Col>
                    <Row>
                    <Text size={14}>
                        {format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                    </Text>
                    </Row>
                    <Row>
                    <Text size={12}>
                        Id: {item.transactionId}
                    </Text>
                    </Row>
                </Col>
            );
          case "mentoria":
            return (
              <Col>
                <Row>
                  <Text b size={14} css={{ tt: "capitalize" }}>
                    {item.product.productName}
                  </Text>
                </Row>
                <Row>
                  <Text size={13} css={{ tt: "capitalize", color: "$accents7", display:"flex" }}>
                    Sessões: {item.slots}
                  </Text>
                </Row>
              </Col>
            );
          case "aluno":
            return (
              <Col>
                <Row>
                  <Text b size={14}>
                    {item.user.name}
                  </Text>
                </Row>
                <Row>
                  <Text size={12}>
                    {item.user.email}
                  </Text>
                </Row>
              </Col>
            );
          case "valor":
            return (
              <Col>
                <Row>
                  <Text b size={14}>
                    R${item.product.productPrice}
                  </Text>
                </Row>
                <Row>
                  <Text size={12}>
                    Saldo: R${item.mentorValue}
                  </Text>
                </Row>
              </Col>
            );
          case "taxas":
            return (
              <Col>
                <Row>
                  <Text size={12}>
                    {(item.creditCardInterest) && <>Juros - R${item.creditCardInterest}</>}
                  </Text>
                </Row>
                <Row>
                  <Text size={12}>
                     Taxa: R${item.mentorTaxValue}
                  </Text>
                </Row>
                <Row>
                  <Text size={12}>
                    {(item.affiliatedValue) && <>Afiliado - R${item.affiliatedValue}</>}
                  </Text>
                </Row>
              </Col>
            );
          case "transferred":
            return renderOrderTransferred(item.transferred, item.paymentStatus);
          case "status":
            return renderBadgeStatus(item.paymentStatus);
    
          case "actions":
            return (
              <Row justify="center" align="center">
                {/* <Col css={{ d: "flex" }}>
                  <Tooltip content="Details">
                    View
                  </Tooltip>
                </Col>
                <Col css={{ d: "flex" }}>
                  <Tooltip content="Edit user">
                    Edit
                  </Tooltip>
                </Col> */}
              </Row>
            );
          default:
            return cellValue;
        }
      };

      return (
        <>
        <Table
            striped
            sticked
            bordered={false}
            shadow={false}
            selectionMode="single"
            aria-label="Example static striped collection table"
            css={{
                height: "auto",
                minWidth: "100%",
            }}
            className="class-border-less"
            >
          <Table.Header columns={columns}>
            {(column) => (
              <Table.Column
                key={column.uid}
                hideHeader={column.uid === "actions"}
                align={column.uid === "actions" ? "center" : "start"}
              >
                {column.name}
              </Table.Column>
            )}
          </Table.Header>
          <Table.Body items={props.orders}>
            {(item) => (
              <Table.Row>
                {(columnKey) => (
                  <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                )}
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {props.orders.length < 1 && <EmptyTableLine>
          sem registro  
        </EmptyTableLine>}
        </>
      );


}

export default OrdersList;