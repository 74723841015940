import SmallCredits from "../SmallCredits";
import Container, { Item, Links } from "./styles";

const FooterBar = () => {
    return (
        <Container>
            <SmallCredits/>
            <Links>
                <Item>
                Precisa de ajuda?
                </Item>
            </Links>
        </Container>
    )
}

export default FooterBar;