import { useEffect, useState } from "react";
import { UseApi } from "../../hooks/UseApi"
import { motion } from "framer-motion";
import { BoxContainer, ContentLoading, RowInline } from "../../styles/main";
import { ActionContainer } from "./styles";
import { Button, Loading } from "@nextui-org/react";
import PlusIcon from "../../Assets/icons/plusIcon.svg"
import EmptyResult from "../../components/EmptyResult";
import { useNavigate } from "react-router-dom";
import ProductList from "../../components/ProductList";
import AffiliationRequestList from "../../components/AffiliationRequestList";
import { useAuth } from "../../Auth/AuthProvider";
import MeetingList from "../../components/MeetingList";
import Analytics from "../../Analytics";

const Product = () => {
    const navigate = useNavigate();
    const api = UseApi();
    const analytics = Analytics();

    const [products, setProducts ] = useState([]);
    const [meetings, setMeetings ] = useState([]);
    const [ affiliatedRequests, setAfiiliatedRequests ] = useState([]) 
    const [ isLoadingProduct, setIsLoadingProduct ] = useState(true);
    const [ isLoadingAffiliated, setIsLoadingAffiliated ] = useState(true);
    const [ isLoadingMeetings, setIsLoadingMeetings ] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            getProducts();
            getAffiliationRequests();
            setAfiiliatedRequests([]);
            getMeetings();
        }, 400);
        analytics.addPage({page: "/products", title:"Lista de mentorias"})
    },[])

    const getProducts = async () => {
        const data = await api.getProductsByUser();
        if (data.length >= 1) {
            setProducts(data);
        } 

        setIsLoadingProduct(false);
    }

    const getMeetings = async () => {
        const data = await api.getMyMeetings();
        if (data.length >= 1) {
            setMeetings(data);
        }

        setIsLoadingMeetings(false);
    }

    const getAffiliationRequests = async () => {
        const data = await api.getAffiliationRequests();
        setAfiiliatedRequests(data);
        setIsLoadingAffiliated(false);
    }

    const updateRequests = () => {
        getAffiliationRequests();
    }

    const renderProducts = () => {
        if (products.length >= 1) {
            return (
                <ProductList products={products}/>
            )
        } else {
            return <EmptyResult text="Nenhuma mentoria cadastrada"/>
        }
    }

    const renderAffiliatedRequests = () => {
        if (affiliatedRequests.length >= 1) {
            return (
                <AffiliationRequestList update={updateRequests} requests={affiliatedRequests}/>
            )
        } else {
            return (
                <EmptyResult text="sem solicitação de afiliação"/>
            )
        }
    }

    const renderMeetings = () => {
        if (meetings.length > 1) {
            return (
                <MeetingList meetings={meetings} />
            )
        } else {
            return (
                <EmptyResult text="sem mentoria agendada!"/>
            )
        }
    }

    return (
        <motion.div 
        initial={{opacity:0, y:"50"}} 
        animate={{opacity:1, y:0}} 
        exit={{opacity:0}}
        >
            <ActionContainer>
                <Button
                    auto
                    shadow
                    color="secondary"
                    iconRight={<img src={PlusIcon} alt="Criar Mentoria"/>}
                    onPress={() => navigate("/dashboard/products/create")}
                >
                    Criar Mentoria
                </Button>
            </ActionContainer>
            <RowInline>
                <BoxContainer>
                    { isLoadingProduct ? <ContentLoading><Loading color="secondary" size="xl" type="points" /></ContentLoading> : renderProducts()}
                </BoxContainer>
            </RowInline>
            <RowInline>
                <BoxContainer>
                    {isLoadingAffiliated ? <ContentLoading><Loading color="secondary" size="xl" type="points" /></ContentLoading> : renderAffiliatedRequests()}
                </BoxContainer>
            </RowInline>
            <RowInline>
                <BoxContainer>
                    { isLoadingMeetings ? <ContentLoading><Loading color="secondary" size="xl" type="points" /></ContentLoading> : renderMeetings()}
                </BoxContainer>
            </RowInline>
        </motion.div>
    )
}

export default Product;