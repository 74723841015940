import { Table, Row, Col, Text, Badge, Button } from "@nextui-org/react";
import TimeIcon from '../../Assets/icons/timeIcon.svg';
import format from "date-fns/format";
import ClockIcon from "../../Assets/icons/timeIcon.svg";
import CheckIcon from "../../Assets/icons/checkIcon.svg";
import CtRow from "./styles";
import ConfirmRequestBtn from "./ConfirmRequestBtn";
import { useEffect, useState } from "react";

export const AffiliationRequestList = (props) => {
    
    const columns = [
        { name: "SOLICITAÇÃO", uid: "solicitacao" },
        { name: "AFILIADO", uid: "afiliado" },
        { name: "PRODUTO", uid: "produto" },
        { name: "STATUS", uid: "status" },
        { name: "ACTIONS", uid: "actions" },
      ];

    const hasChange = () => {
        props.update();
    }

    const renderBadgeStatus = (status) => {
        switch(status) {
            case "PENDING":
                return <Badge variant="flat">aguardando aprovação</Badge>
            case "APPROVED":
                return <Badge variant="flat" color="secondary">ativa</Badge>
        }
    }

    const renderCell = (item, columnKey) => {
        const cellValue = item[columnKey];
        switch (columnKey) {
            case "solicitacao":
            return (
                <Col>
                    <Row>
                    <Text b size={14} css={{ tt: "capitalize" }}>
                        {format(new Date(item.createdAt), "dd/MM/yyyy")}
                    </Text>
                    </Row>
                    <Row>
                    <Text b size={13} css={{ tt: "capitalize", color: "$accents7", display: "flex" }}>
                        <img style={{marginRight:6}} src={ClockIcon} width={16} alt="horario"/>
                        {format(new Date(item.createdAt), "hh:mm")}
                    </Text>
                    </Row>
                </Col>
            );
          case "afiliado":
            return (
                <Col>
                    <Row>
                    <Text b size={14} css={{ tt: "capitalize" }}>
                        {item.requestFrom.name}
                    </Text>
                    </Row>
                    <Row>
                    <Text b size={13} css={{ color: "$accents7" }}>
                        {item.requestFrom.email}
                    </Text>
                    </Row>
                </Col>
            );
          case "produto":
            return (
              <Col>
                <Row>
                  <Text b size={14} css={{ tt: "capitalize" }}>
                    {item.product.productName}
                  </Text>
                </Row>
                <Row>
                  <Text b size={13} css={{ color: "$accents7", display:"flex" }}>
                    {item.product.productCode}
                  </Text>
                </Row>
              </Col>
            );
          case "status":
            return <>{renderBadgeStatus(item.affiliatedStatus)}</>
    
          case "actions":
            return (
              <Row justify="center" align="center">
                <CtRow>
                {(item.affiliatedStatus === "PENDING") && 
                <ConfirmRequestBtn 
                forceUpdate={hasChange}
                affiliatedRequestId={item.id}
                />
                }
                </CtRow>
              </Row>
            );
          default:
            return cellValue;
        }
      };

      return (
        <Table
            striped
            sticked
            bordered={false}
            shadow={false}
            aria-label="Example static striped collection table"
            css={{
                height: "auto",
                minWidth: "100%",
            }}
            className="class-border-less"
            >
          <Table.Header columns={columns}>
            {(column) => (
              <Table.Column
                key={column.uid}
                hideHeader={column.uid === "actions"}
                align={column.uid === "actions" ? "center" : "start"}
              >
                {column.name}
              </Table.Column>
            )}
          </Table.Header>
          <Table.Body items={props.requests}>
            {(item) => (
              <Table.Row>
                {(columnKey) => (
                  <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                )}
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      );


}

export default AffiliationRequestList;