import { Table, Row, Col, Text, Badge, Button, Loading } from "@nextui-org/react";
import TimeIcon from '../../Assets/icons/timeIcon.svg';
import format from "date-fns/format";
import ClockIcon from "../../Assets/icons/timeIcon.svg";
import CheckIcon from "../../Assets/icons/checkIcon.svg";
import CtRow, { ContainerOp } from "./styles";
// import ConfirmRequestBtn from "./ConfirmRequestBtn";
import { OverlayPanel } from 'primereact/overlaypanel';
import { useEffect, useRef, useState } from "react";
import { FiEdit3 } from "react-icons/fi"
import { InputNumber } from 'primereact/inputnumber';
import { UseApi } from "../../hooks/UseApi";
import { useToast } from "@chakra-ui/react";

export const AffiliatesList = (props) => {
    const api = UseApi();
    const toast = useToast();
    
    const overlay = useRef(null);
    const form = useRef(null);
    const [ fee, setFee] = useState(1);
    const [ oldFee, setOldFee ] = useState(0);
    const [ affiliateToUpdate, setAffiliateToUpdate ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    
    const columns = [
        { name: "SOLICITAÇÃO", uid: "solicitacao" },
        { name: "AFILIADO", uid: "afiliado" },
        { name: "COMISSÃO", uid: "comissao" },
        { name: "STATUS", uid: "status" },
        { name: "ACTIONS", uid: "actions" },
      ];

    const hasChange = () => {
        props.update();
    }

    const renderBadgeStatus = (status) => {
        switch(status) {
            case false:
                return <Badge variant="flat">inativa</Badge>
            case true:
                return <Badge variant="flat" color="secondary">ativa</Badge>
        }
    }

    const renderOverlapAffiliatedFee = (id, e) => {
        setAffiliateToUpdate(id);
        overlay.current.toggle(e)
    } 

    const sendUpdate = async () => {
        setOldFee(fee);
        setIsLoading(true);
        const data = await api.updateAffiliatedFee({affiliated_id: affiliateToUpdate, affiliated_fee: fee})
        if (!data.error) {
            setIsLoading(false);
            overlay.current.hide();
            toast({
                description: "Comissão atualizada com sucesso",
                status: 'success',
                duration: 3000,
                isClosable: false,
                position: 'top',
            })
            setFee(1);
            hasChange();
        } else {
            toast({
                description: "Erro ao atualizar comissão",
                status: 'error',
                duration: 2000,
                isClosable: false,
                position: 'top',
            })
        }
    }

    const renderOverlap = () => {
        return (
            <OverlayPanel ref={overlay}>
                    <ContainerOp>
                    <InputNumber inputId="minmax" value={fee} onValueChange={(e) => setFee(e.target.value)} min={1} max={90} />
                    <Button 
                    auto
                    disabled={isLoading}
                    size="lg"
                    color="secondary"
                    onPress={() => sendUpdate()}>
                      {isLoading ? <Loading color="currentColor" size="sm" /> : <>Confirmar</>}
                    </Button>
                    </ContainerOp>
                </OverlayPanel>
        )
    }

    const renderCell = (item, columnKey) => {
        const cellValue = item[columnKey];
        switch (columnKey) {
            case "solicitacao":
            return (
                <Col>
                    <Row>
                    <Text b size={14} css={{ tt: "capitalize" }}>
                        {format(new Date(item.createdAt), "dd/MM/yyyy")}
                    </Text>
                    </Row>
                    <Row>
                    <Text b size={13} css={{ tt: "capitalize", color: "$accents7", display: "flex" }}>
                        <img style={{marginRight:6}} src={ClockIcon} width={16} alt="horario"/>
                        {format(new Date(item.createdAt), "hh:mm")}
                    </Text>
                    </Row>
                </Col>
            );
          case "afiliado":
            return (
                <Col>
                    <Row>
                    <Text b size={14} css={{ tt: "capitalize" }}>
                        {item.user.name}
                    </Text>
                    </Row>
                    <Row>
                    <Text b size={13} css={{ color: "$accents7" }}>
                        {item.user.email}
                    </Text>
                    </Row>
                </Col>
            );
          case "comissao":
            return (
              <Col>
                <Row>
                  <Text b size={14} css={{ tt: "capitalize" }}>
                    {item.affiliatedFee}%
                  </Text>
                </Row>
              </Col>
            );
          case "status":
            return <>{renderBadgeStatus(item.affiliatedStatus)}</>
          case "actions":
            return (
              <Row justify="center" align="center">
                <Button 
                    auto 
                    size="xs" 
                    color="secondary"
                    bordered
                    onPress={(e) => {
                        renderOverlapAffiliatedFee(item.id, e)
                    }}
                    icon={<FiEdit3 size={16}/>}>
                      Editar
                    </Button>
              </Row>
            );
          default:
            return cellValue;
        }
      };

      return (
        <>
        {renderOverlap()}
        <Table
            striped
            sticked
            bordered={false}
            shadow={false}
            aria-label="Example static striped collection table"
            css={{
                height: "auto",
                minWidth: "100%",
            }}
            className="class-border-less"
            >
          <Table.Header columns={columns}>
            {(column) => (
              <Table.Column
                key={column.uid}
                hideHeader={column.uid === "actions"}
                align={column.uid === "actions" ? "center" : "start"}
              >
                {column.name}
              </Table.Column>
            )}
          </Table.Header>
          <Table.Body items={props.affiliates}>
            {(item) => (
              <Table.Row>
                {(columnKey) => (
                  <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                )}
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        </>
      );


}

export default AffiliatesList;