import styled from "styled-components";

export const Row = styled.div`
    display: flex;
    width: 100%;
    @media (max-width:768px) {
        flex-direction: column;
    }
`

export const RowColumn = styled.div`
    display: flex;
    width: 100%;
    margin: 24px 0px;
    /* flex-direction: column; */
    height: 260px;
`
export const GridW3 = styled.div`
    width: 33%;
    display: flex;
`

export const GridW6 = styled.div`
    width: 66%;
    display: flex;
    padding-left:25px;
`

export const WelcomeCt = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /* padding:20px; */
    /* width: 100%; */
    text-align: left;
    h1{
        font-size: 18px;
        font-weight: 600;
    }
    p{
        font-size:14px;
    }
`

export const BoxChart = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    border:1px solid #ccc;
    flex:1;
    flex-direction: column;
    padding:14px;
    border-radius: 8px;
`
export const GridBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    width: 100%;
`
export const GridContent = styled.div`
    display: flex;
    margin-top:0px;
    padding-right: 20px;
    padding-left: 20px;
    min-height: 110px;
    &:last-of-type {
        margin-top:20px
    }
    box-sizing: border-box;
    @media(max-width: 768px) {
        margin-top:30px;
        padding:0px;
        &:last-of-type {
            margin-bottom: 30px;
        }
    }
`

export const GridContentSolo = styled.div`
    display: flex;
    margin-top:0px;
    padding-right: 0px;
    min-height: 240px;
    box-sizing: border-box;
`

export const GridStaticCt = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding:10px;
`

export const GridStaticIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
    border-radius: 8px;
    margin-right: 14px;
`

export const GridStaticContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* align-content: center; */
    /* background-color: #333; */
    margin-top: -10px;
    h2{
        font-size:32px;
        font-weight: 600;
    }
    p{
        font-size:14px;
    }
`

export const GridWalletCt = styled.div`
    display: flex;
    flex-direction: column;
    padding:10px;
    justify-content: center;
    align-items: flex-start;
    h2{
        font-size:16px;
        font-weight: 600;
    }
    h3{
        font-size:36px;
        font-weight: 600;
    }
    p{
        font-size:14px;
    }
    h4{
        font-weight: 400;
        font-size:16px;
    }
    h5{
        font-weight: 600;
    }
`
export const GridWalletLine = styled.div`
    display: flex;
    align-items: center;
`
export const GridCtDivisor = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid #eee;
    margin: 8px 0px;
`

export const GridWalletIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background:#f1f1f1;
    /* padding:10px; */
    height: 40px;
    width: 40px;
    border-radius: 8px;
    /* width: 40px; */
    /* height: 40px; */
`

export const MeetingCt = styled.div`
    display: flex;
    flex-direction: column;
    padding:20px;
    h1{
        font-size:24px;
        font-weight: 600;
    }
    position: relative;
`

export const SlotsLine = styled.div`
    display: flex;
    flex-direction: column;
    margin-top:8px;
    border-top:1px solid #eee;
    padding-top:8px;
    h2{
        font-size:18px;
        font-weight: 400;
    }
`

export const SlotAvart = styled.div`
    display: flex;
    margin-left:10px;
`

export const MeetingCta = styled.div`
    display: flex;
    position: absolute;
    right: 0px;
`

export const CtaBorder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #6B6B6B;
    width: 100%;
    color:#171717;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
    &:hover{
        background-color: #e6e6e6;
    }
    &:active{
        background-color: #fff;
    }
`

export const RowCenter = styled.div`
    display: flex;
    justify-content: center;
    padding:40px 20px;
    flex-direction: column;
    align-items: center;
    p{
        margin-bottom:16px;
    }
`

export const BtnSolid = styled.div`
    display: flex;
    background-color: #582489;
    color:#fff;
    justify-content: center;
    align-items: center;
    /* max-width: 200px; */
    padding:14px 20px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 18px;
`

export default WelcomeCt;