import {
    useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LogoImg from '../../Assets/Images/mentorados-icon.png';
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from "../../Auth/AuthProvider";
import { AlertToChangeAccount, BoxForm, BoxOptions, BoxRadioGroup, BoxRadioItem, BoxRadioLabel, CenterBox, CheckAlert, Container, ForgotPassword, InlineInput, LogoCt, TextLabel, TitleBox } from "./styles";
// import { Button } from 'primereact/button';
// import { InputText } from "primereact/inputtext";
// import { SelectButton } from 'primereact/selectbutton';
import { RadioButton } from "primereact/radiobutton";
import { Button, Loading, Checkbox } from "@nextui-org/react";
import {Input, Spacer} from "@nextui-org/react";
import { UseApi } from "../../hooks/UseApi";
import RecoveryPage from "./Recovery";
import Analytics from "../../Analytics";
// import { Divider } from "primereact/divider";


export default function SignIn() {

    // const formik = useFormik({
    //     initialValues: {
    //         email: "",
    //         password: ""
    //     },
    //     onSubmit: (values, actions) => {
    //         alert(JSON.stringify(values, null, 2))
    //         actions.resetForm();
    //     } 
    // });
    const navigate = useNavigate();
    const toast = useToast();
    const api = UseApi();
    const [searchParams, setSearchParams ] = useSearchParams();
    const analytics = Analytics();

    const { signIn } = useAuth();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailSignUp, setEmailSignUp] = useState('');
    const [passwordSignUp, setPasswordSignUp] = useState('');
    const [passwordConfirmSignUp, setPasswordConfirmSignUp] = useState('');
    const [ username, setUsername ] = useState('');
    const [invalidUsername, setInvalidUsername] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [invalidPasswordConfirm, setInvalidPasswordConfirm] = useState(false);
    const [ signUp, setSignUp ] = useState(false);
    const [signInIsVisible, setSignInIsVisible ] = useState(true);
    const [signUpIsVisible, setSignUpIsVisible ] = useState(false);
    const [ recoveryIsVisible, setRecoveryIsVisible ] = useState(false);
    const [userRole, setUserRole] = useState("MENTOR");
    const [ loadingSignUp, setLoadingSignUp ] = useState(false);
    const [ agreeTerms, setAgreeTerms ] = useState(false);
    const [ agreeTermsInvalid, setAgreeTermsInvalid ] = useState(false);
    
    // const formBackground = useColorModeValue("gray.50","gray.700");
    // const variantInputColor = useColorModeValue("filled", "filled");

    const handleEnterKey = (event) => {
        if (event.key === "Enter") {
            if (signUpIsVisible) handleSignUp();
            if (signInIsVisible) handleSignIn();
        }
    }

    useEffect(() => {
        
        let redirect = searchParams.get('redirect');
        if (redirect && redirect === 'signUp') {
            setSignUp(true);
            setSignInIsVisible(false);
            setSignUpIsVisible(true);
        }
        analytics.addPage({page: "/", title:"Login Dashboard"})
    },[])

    const createAccount = async () => {
        analytics.addEvent("SignUpSubmit")

        try {
            const data = await api.createAccount({
                name: username,
                email: emailSignUp,
                password: passwordSignUp,
                user_role: userRole
            })

            if (data !== null) {
                changeToSignIn();
                toast({
                    description: "Conta criada com sucesso! Agora é só efetuar seu login.",
                    status: 'success',
                    duration: 3000,
                    isClosable: false,
                    position: 'top',
                })
                setUsername("");
                setPasswordSignUp("");
                setEmailSignUp("");
                setUserRole("MENTOR");
                setLoadingSignUp(false);
                analytics.addEvent('SignUpCompleted');
                analytics.addFbDefaultEvent('CompleteRegistration');
                analytics.addEvent('CompleteRegistration');
            }

        } catch(e) {
            toast({
                description: "Erro ao criar conta. Entre em contato com o suporte",
                status: 'error',
                duration: 3000,
                isClosable: false,
                position: 'top',
            })
        }
    }

    const handleSignIn = async () => {
        if (email && password) {
            setLoading(true);
            const isLogged = await signIn( email, password );
            if (isLogged?.error) {
                toast({
                    description: isLogged.message,
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                })
                setLoading(false);
                return;
            }

            if(!isLogged) {
                toast({
                    description: "Email ou senha inválidos!",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                })
                setLoading(false);
            } else {
                analytics.addEvent('SignIn');
                navigate('dashboard')
            }
        } else {
            if (!toast.isActive('signIn')) {
                toast({
                    id: 'signIn',
                    description: "Preencha todos os campos!",
                    status: 'error',
                    duration: 2000,
                    isClosable: false,
                    position: 'top',
                })
            }
            if (email === '') setInvalidEmail(true);
            if (password === '') setInvalidPassword(true);
        }
    }

    useEffect(() => {
        if (agreeTerms) setAgreeTermsInvalid(false);
    },[agreeTerms])

    useEffect(() => {
        setInvalidUsername(false);
    }, [username])

    useEffect(() => {
        setInvalidEmail(false);
    }, [email, emailSignUp])

    useEffect(() => {
        setInvalidPassword(false);
    }, [password])

    useEffect(() => {
        setInvalidPassword(false);
    }, [passwordSignUp])

    useEffect(() => {
        if (passwordConfirmSignUp !== '') {
            if (passwordSignUp !== passwordConfirmSignUp) {
                setInvalidPasswordConfirm(true);
            }
        }
        setInvalidPasswordConfirm(false);
    }, [passwordConfirmSignUp])

    // const handleEmailInputChange = (e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)
    // const handlePasswordInputChange = (e: React.FormEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)
    

    const changeToSignUp = () => {
        setSignInIsVisible(false);
        setTimeout(() => {
            setSignUp(true);
            setSignUpIsVisible(true);
        },500)
    }

    const handleRecovery = (state) => {
        setSignInIsVisible(!state);
        setTimeout(() => {
            setRecoveryIsVisible(state);
        },500)
    }
    
    const changeToSignIn = () => {
        if (loadingSignUp) return;

        setSignUpIsVisible(false);
        setTimeout(() => {
            setSignUp(false);
            setSignInIsVisible(true);
        },500)
    }

    const handleValidateFieldsSignup = () => {
        let validate = true;
        setInvalidEmail(false);
        setInvalidPassword(false);
        setInvalidPasswordConfirm(false);
        setInvalidUsername(false);

        if (emailSignUp === '') {
            setInvalidEmail(true);
            validate = false;
        }
        if (passwordSignUp === '') {
            setInvalidPassword(true);
            validate = false;
        }
        if (passwordConfirmSignUp === '') {
            setInvalidPasswordConfirm(true);
            validate = false;
        }
        if (username === '') {
            setInvalidUsername(true);
            validate = false;
        }

        if (!agreeTerms) {
            setAgreeTermsInvalid(true);
            validate = false;
        }

        return validate;
    }

    const handleSignUp = async () => {
        setLoadingSignUp(true);
        if (handleValidateFieldsSignup()) {
            if (passwordSignUp !== passwordConfirmSignUp) {
                if(!toast.isActive('signUp')) {
                    toast({
                        id: 'signUp',
                        description: "As senhas não são iguais!",
                        status: 'error',
                        duration: 2000,
                        isClosable: false,
                        position: 'top',
                    })
                }
                setLoadingSignUp(false);
            } else {
                createAccount();
            }
        } else {
            if(!toast.isActive('signUp')) {
                toast({
                    id: 'signUp',
                    description: "Preencha todos os campos!",
                    status: 'error',
                    duration: 2000,
                    isClosable: false,
                    position: 'top',
                })
            }
            setLoadingSignUp(false);
        }

    }

    const renderSignIn = () => {
        if (!recoveryIsVisible) {
            return (
                <AnimatePresence>
                    {signInIsVisible && (
                        <motion.div 
                        initial={{ opacity: 0}}
                        animate={{ opacity: 1, x: [60,-20,0]}}
                        transition={{ ease: "easeInOut", duration: 0.4 }}
                        exit={{ opacity: 0, x: [0,-40,80] }}
                        >
                        <LogoCt>
                            <img src={LogoImg} alt="Welcome"/>
                        </LogoCt>
                        <CenterBox>
                        <TitleBox>
                            <h1>
                                Bem vindo!
                            </h1>
                            <p>
                                Entre com seu email e senha para acessar seu painel administrativo.
                            </p>
                        </TitleBox>
                        <BoxForm style={{marginTop: 20}}>
                        <InlineInput>
                            <Input 
                            labelPlaceholder="Seu email"
                            disabled={loading}
                            value={email}
                            status={invalidEmail ? 'error' : 'default'}
                            onKeyDown={event => handleEnterKey(event)}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            />
                        </InlineInput>
                        <Spacer y={1.6} />
                        <InlineInput>
                            <Input.Password 
                            color="default"
                            labelPlaceholder="Sua senha"
                            disabled={loading}
                            value={password}
                            status={invalidPassword ? 'error' : 'default'}
                            onKeyDown={event => handleEnterKey(event)}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            />
                        </InlineInput>
                        <ForgotPassword onClick={() => handleRecovery(true)}>
                            Esqueci minha senha
                        </ForgotPassword>
                        </BoxForm>
                        <Button size="lg" shadow color="secondary" auto onClick={() => handleSignIn()}>
                            {loading ? <Loading color="currentColor" size="sm" /> : <>Entrar</>}
                        </Button>
                        </CenterBox>
                        <AlertToChangeAccount onClick={() => changeToSignUp()}>
                            Ainda não tem conta? <span>Criar Conta</span>
                        </AlertToChangeAccount>
                        </motion.div>
                    )}
                </AnimatePresence>
            )
        } else {
            return <RecoveryPage handleRecovery={handleRecovery}/>
        }
    }

    const renderSignUp = () => {
        return (
            <AnimatePresence>
                {signUpIsVisible && (
                    <motion.div 
                    initial={{ opacity: 0}}
                    animate={{ opacity: 1, x: [60,-20,0]}}
                    transition={{ ease: "easeInOut", duration: 0.4 }}
                    exit={{ opacity: 0, x: [0,-40,80] }}
                    >
                        <LogoCt>
                            <img src={LogoImg} alt="Welcome"/>
                        </LogoCt>
                        <CenterBox>
                            <TitleBox>
                                <h1>
                                    Criar conta
                                </h1>
                                <p>
                                    Ainda não tem uma conta? Crie agora mesmo, é rapidinho!
                                </p>
                            </TitleBox>
                            <Spacer y={1}/>
                            {/* <TextLabel>
                                O que deseja se tornar na plataforma?
                            </TextLabel>
                            <BoxOptions>
                                <BoxRadioGroup>
                                    <BoxRadioItem>
                                        <RadioButton inputId="mentor" name="your-preference" value="MENTOR" onChange={(e) => setUserRole(e.value)} checked={userRole === 'MENTOR'} />
                                        <BoxRadioLabel htmlFor="mentor">
                                            Mentor
                                        </BoxRadioLabel>
                                    </BoxRadioItem>
                                    <BoxRadioItem>
                                        <RadioButton inputId="affiliated" name="your-preference" value="AFFILIATED" onChange={(e) => setUserRole(e.value)} checked={userRole === 'AFFILIATED'} />
                                        <BoxRadioLabel htmlFor="affiliated">
                                            Afiliado
                                        </BoxRadioLabel>
                                    </BoxRadioItem>
                                </BoxRadioGroup>     
                            </BoxOptions>    */}

                            <InlineInput>
                                <Input 
                                    labelPlaceholder="Seu nome completo"
                                    disabled={loadingSignUp}
                                    status={invalidUsername ? 'error' : 'default'}
                                    value={username}
                                    onKeyDown={event => handleEnterKey(event)}
                                    onChange={(e) => setUsername(e.currentTarget.value)}
                                    />
                            </InlineInput>
                            <Spacer y={2} />
                            <InlineInput>
                                <Input 
                                    labelPlaceholder="Seu email"
                                    disabled={loadingSignUp}
                                    status={invalidEmail ? 'error' : 'default'}
                                    value={emailSignUp}
                                    onKeyDown={event => handleEnterKey(event)}
                                    onChange={(e) => setEmailSignUp(e.currentTarget.value)}
                                    />
                            </InlineInput>
                            <Spacer y={2} />
                            <InlineInput>
                                <Input.Password 
                                color="default"
                                labelPlaceholder="Sua senha"
                                disabled={loadingSignUp}
                                value={passwordSignUp}
                                status={invalidPassword ? 'error' : 'default'}
                                onKeyDown={event => handleEnterKey(event)}
                                onChange={(e) => setPasswordSignUp(e.currentTarget.value)}
                                />
                            </InlineInput>
                            <Spacer y={2} />
                            <InlineInput>
                                <Input.Password 
                                color="default"
                                labelPlaceholder="Confirmar senha"
                                disabled={loadingSignUp}
                                value={passwordConfirmSignUp}
                                status={invalidPasswordConfirm ? 'error' : 'default'}
                                onKeyDown={event => handleEnterKey(event)}
                                onChange={(e) => setPasswordConfirmSignUp(e.currentTarget.value)}
                                />
                            </InlineInput>
                            <Spacer y={2}/>
                            <InlineInput>
                            <Checkbox
                            isSelected={agreeTerms} 
                            onChange={setAgreeTerms}>
                                Li e aceito os <a target="_blank" href="https://mentorados.com/termos-de-uso">termos de uso</a> 
                            </Checkbox>
                            {agreeTermsInvalid && <CheckAlert>
                                Precisa concordar com os termos.    
                            </CheckAlert>}
                            </InlineInput>
                            <Spacer y={1.4} />
                            <Button size="lg" shadow color="secondary" auto onPress={() => handleSignUp()}>
                                {loadingSignUp ? <Loading color="currentColor" size="sm" /> : <>Criar conta</>}
                            </Button>
                        </CenterBox>
                        <AlertToChangeAccount onClick={() => changeToSignIn()}>
                            Já tenho conta! <span>Entrar</span>
                        </AlertToChangeAccount>
                    </motion.div>
                )}  
            </AnimatePresence>
        )
    }

    return (
        <>
            <Container>
                {signUp ? renderSignUp() : renderSignIn()}
            </Container>
        </>      
    )
}