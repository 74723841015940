import styled from "styled-components";

export const Content = styled.div`
    /* overflow: auto; */
    /* background-color: #000; */
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-direction: column;
    height: calc(100vh - 78px);
    max-width: 980px;
    margin:auto;
    box-sizing: border-box;
    /* flex-shrink: 0; */
    padding: 20px 20px;
    /* padding-bottom: 0px; */
    /* margin-bottom: -220px; */
`

export const ProgressCt = styled.div`
    display: flex;
    position: absolute;
    top:74px;
    width: 100%;
`

export default Content;