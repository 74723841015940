import styled from 'styled-components'

const colorTextMuted = "#8a90a2";
const colorTextMain = "#343a40";
const colorTheme = "#4b1584";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    
`

export const Row = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background:#fff;
`

export const LogoCt = styled.div`
    display: flex;
    margin-bottom:40px;
    justify-content: center;
    align-items: center;
    img{
        width: 40px;
    }
`

export const InlineInput = styled.div`
    display: flex;
    flex-direction: column;
    /* margin-bottom:40px; */
    /* &:last-of-type {
        margin-bottom: 10px;
    } */
`

export const LabelInput = styled.label`
    color:${colorTextMain} !important;
    font-weight: 600;
    margin-bottom:6px;
`

export const CenterBox = styled.div`
    display: flex;
    width: 450px;
    flex-direction: column;
    padding:40px;
    box-shadow: 5px 15px 25px #e5e5e5a0;
    border-radius: 12px;
    background:#fff;
    @media(max-width: 768px) {
        width: 90%;
        margin: auto;
        /* margin:20px; */
        box-sizing: border-box;
    }
`
export const TitleBox = styled.div`
    margin-bottom: 20px;
    h1{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 4px;
        color: ${colorTextMain};
    }
    p{
        font-size: 18px;
        color: ${colorTextMuted};
    }
`

export const BoxForm = styled.div`
    display: flex;
    margin-bottom:60px;
    flex-direction: column;
`

export const BoxButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const BoxRadioGroup = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
`

export const BoxRadioLabel = styled.label`
    color: ${colorTextMain};
    margin-left:10px;
    cursor: pointer;
`

export const BoxRadioItem = styled.div`
    display: flex;
`

export const AlertToChangeAccount = styled.div`
    display: flex;
    color:${colorTextMuted};
    margin-top:30px;
    justify-content: center;
    span{
        color:${colorTheme} !important;
        margin-left: 5px;
        font-weight: 800;
    }
    cursor: pointer;
`

export const TextLabel = styled.div`
    display: flex;
    color:${colorTextMain};
    font-weight: 600;
    margin-bottom:14px;
    justify-content: center;
`

export const BoxOptions = styled.div`
    display: flex;
    border:1px solid #eee;
    align-items: center;
    justify-content: center;
    padding:10px 0px;
    margin-bottom: 60px;
    border-radius: 8px;
`
export const ForgotPassword = styled.div`
    display: flex;
    margin-top: 16px;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
    color:#888;
    &:hover{
        color:#000;
    }
`

export const TimerRecovery = styled.div`
    strong{
        color:#4b1584;
    }
`

export const InputBasic = styled.div`
    margin: 10px 0px;
`

export const CheckAlert = styled.div`
    display: flex;
    color:red;
    margin-top:4px;
`

export default Container;