import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";
import { useEffect, useState } from "react";
import { UseApi } from "../../hooks/UseApi";
import { useToast } from "@chakra-ui/react";
import { DayType, HourType } from "../ProductCreate";
import { motion } from "framer-motion";
import { TabView, TabPanel } from 'primereact/tabview';
import RowInline, { BoxContainer, 
    FormLine, 
    FormLineColumnDirection, 
    FormLineWithText, 
    RadioCt 
} from "../../styles/main";
import AlertFilledIcon from "../../Assets/icons/alertFilledIcon.svg";
import 'primeicons/primeicons.css';
import ActionCt, { ActionButtons, 
    BoxHoursAlert, 
    BoxHoursExemple, 
    BoxText, 
    InputHoursCt, 
    ModalContent,
    Title, 
    ContainerCalendar,
    BoxSessions, 
    LineMeetingGroupAction
} from "./styles";
import AlertInfo from "../../components/AlertInfo";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { Spacer, Modal, Loading, Button } from "@nextui-org/react";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from 'primereact/dropdown';
import hoursToWork from "../ProductCreate/hours";
import AvailableHours from "./AvailableHours";
import CheckIcon from "../../Assets/icons/checkIcon.svg";
import { FiUsers, FiSettings } from "react-icons/fi";
import { InputSwitch } from "primereact/inputswitch";
import AffiliatedList from "./AffiliatedList";
import { Calendar } from "primereact/calendar";
import { addLocale } from 'primereact/api';
import AvailableMeetings from "./AvailableMeetings"
import { FiTrash2, FiCheck } from "react-icons/fi";
import AvailableEvents from "../ProductCreate/AvailableEvent";
import add from 'date-fns/add'
import { Divider } from 'primereact/divider';
import { format } from "date-fns";
import AvailableEventsGroup from "./AvailableEventsGroup";
import { Editor } from "primereact/editor";
import Analytics from "../../Analytics";

const ProductEditPage = () => {
    const { id } = useParams();
    const { toggleMainLoader, user } = useAuth();
    const api = UseApi();
    const toast = useToast();
    const navigate = useNavigate();
    const analytics = Analytics();

    const [ productName, setProductName ] = useState<string>("");
    const [ productType, setProductType ] = useState("SOLO");
    const [ productPrice, setProductPrice ] = useState(10);
    const [ productDuration, setProductDuration ] = useState("30");
    const [ productSlot, setProductSlot ] = useState<number>(1);
    const [ productDescription, setProductDescription ] = useState("");
    const [ allowAffiliated, setAllowAfiliated ] = useState("nao");
    const [ affiliatedFee, setAffiliatedFee ] = useState<number>(0);
    const [ affiliatedDescription, setAffiliatedDescription ] = useState<string>("");
    const [ isUpdating, setIsUpdating ] = useState<boolean>(false);
    const [ activeIndex, setActiveIndex ] = useState(0);
    const [ productNameInvalid, setProductNameInvalid ] = useState(false);
    const [ productCode, setProductCode ] = useState(""); 
    const [ calendar, setCalendar ] = useState<any>({});
    const [ isActive, setIsActive ] = useState(true);
    const [ eventLimit, setEventLimit ] = useState(false);
    const [ events, setEvents ] = useState<any>([]);
    const [ inviteGroup, setInviteGroup ] = useState(false);
    const [ linkGroup, setLinkGroup ] = useState("");

    const [ productReady, setProductReady ] = useState(false);

    const [day, setDay] = useState<DayType>({name: "", code: ""});
    const week = [
        { name: 'Segunda', code: 'monday' },
        { name: 'Terça', code: 'tuesday' },
        { name: 'Quarta', code: 'wednesday' },
        { name: 'Quinta', code: 'thursday' },
        { name: 'Sexta', code: 'friday' },
        { name: 'Sábado', code: 'saturday' },
        { name: 'Domingo', code: 'sunday' }
    ];

    addLocale('br', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'feb', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar',
    });
    
    const [ startHour, setStartHour ] = useState<HourType>({name: "", code: ""});
    const [ endHour, setEndHour ] = useState<HourType>({name: "", code: ""});
    const [ availableHours, setAvailableHours ] = useState([])
    const [ availableMeetings, setAvailableMeetings ] = useState([])
    const [ availableEvents, setAvailableEvents ] = useState([]);
    const [ date, setDate] = useState(null);
    const [ productGroupReady, setProductGroupReady ] = useState(false);
    const [ parseEventsReady, setParseEventsReady ] = useState(false);


    useEffect(() => {
        toggleMainLoader(true);
        getProductToEdit();
        analytics.addEvent("InitProductEdit");
        return () => {
            toggleMainLoader(false);
        }
    },[])


    const getProductToEdit = async () => {
        const data = await api.getProductToEdit({product_id: id})
        if (data?.product.id) {
            const { product, calendar } = data;
            setProductName(product.productName);
            setProductDescription(product.productDescription);
            setProductPrice(product.productPrice);
            setProductDuration(''+ product.meetingDuration);
            setProductSlot(product.productSlots);
            setAllowAfiliated((product.allowAffiliated ? 'sim' : 'nao'));
            setAffiliatedFee(product.affiliatedFee);
            setAffiliatedDescription(product.affiliatedDescription);
            setProductCode(product.productCode);
            setIsActive(product.isActive);
            if (product?.linkGroup) {
                setInviteGroup(true);
                setLinkGroup(product.linkGroup);
            }
            if (product.productType === "SOLO") {
                setCalendar(calendar);
            } 
            if (data?.events?.length >= 1) {
                setEvents(data.events);
            }
            setProductType(product.productType);
            setProductReady(true);
            toggleMainLoader(false);
        } 
    }
    
    useEffect(() => {
        if (calendar?.workingHours) {
            checkAvaiableHours();
        }
    },[calendar])

    useEffect(() => {
        if (productPrice < 10) setProductPrice(10);
    }, [productPrice])

    useEffect(() => {
        if (events.length >= 1) {
            parseEvents();
        }
    },[events])

    useEffect(() => {
        if (parseEventsReady) {
            addMeetingEvent(events[0]?.id);

        }
    },[parseEventsReady])

    const translateDay = (day) => {
        switch(day) {
            case 'monday': 
                return 'Segunda';
            case 'tuesday':
                return 'Terça';
            case 'wednesday':
                return 'Quarta';
            case 'thursday':
                return 'Quinta';
            case 'friday':
                return 'Sexta';
            case 'saturday':
                return 'Sábado';
            case 'sunday':
                return 'Domingo';
        }
    }

    

    const checkAvaiableHours = () => {
        for (let i = 0; i < Object.keys(calendar.workingHours).length ; i++ ) {
            let currentDay = Object.keys(calendar.workingHours)[i];
            let currentHours = calendar.workingHours[currentDay]
            if (currentHours.length >= 1) {
                for (let h = 0; h < currentHours.length; h++) {
                    let hourSplit = currentHours[h].split(" - ");
                    let generateKey = currentDay + hourSplit[0] + hourSplit[1]
                    const hour = {
                        key: generateKey,
                        weekday: {name: translateDay(currentDay), code: currentDay},
                        start: {name: hourSplit[0]},
                        end: {name: hourSplit[1]}
                    }  
                    if (availableHours.find(hour => hour.key === generateKey)) return;
                    setAvailableHours(current => [...current, hour])
                }
            }
        }
    }

    const addAvailableHours = () => {
        if (startHour.name 
            && endHour.name 
            && (startHour.name !== endHour.name)
            && startHour.code < endHour.code
            ) {
            const generateKey = day.name + startHour.name + endHour.name  
            const hour = {
                key: generateKey,
                weekday: day,
                start: startHour,
                end: endHour
            }

            if (availableHours.find(hour => hour.key === generateKey)) return;
            setAvailableHours(current => [...current, hour]);
            cleanInputsHour();
        }
    }

    const cleanInputsHour = () => {
        const empty = {name: "", code: ""}
        setDay(empty)
        setStartHour(empty)
        setEndHour(empty);
    }

    const prepareSchedule = async () => {
        const schedule = {
            "monday": [],
            "tuesday": [],
            "wednesday": [],
            "thursday": [],
            "friday": [],
            "saturday": [],
            "sunday": []
        }
        for (let i = 0; availableHours.length > i; i++) {
            schedule[availableHours[i].weekday.code].push(
                availableHours[i].start.name + ' - ' + availableHours[i].end.name
            );
        }
        return schedule;
    }

    const removeAvailbleHours = key => {
        setAvailableHours((current) => current.filter((hour) => hour.key !== key))
    }


    const checkProductReady = () => {
        let ready = false;
        let details = false;

        if (
            productName.length > 1 &&
            productDescription.length > 1 && 
            productDuration &&
            productPrice >= 10 &&
            productSlot
        ) {
            ready = true;
            details = true;
        } else {
            details = false;
        }

        if (availableHours.length >= 1 || events.length >= 1) {
            ready = true;
        } else {
            ready = false;
            if (details) setActiveIndex(1);
        }

        if (allowAffiliated === "sim" && affiliatedFee > 0 ) {
            ready = true;
        }

        // if (ready) setProductReady(true);
        return ready && details;
    }

    const parseEvents = () => {
        for (let e = 0; e < events.length; e++) {
            for (let m = 0; m <events[e].meetings.length; m++) {
                let currentMeeting = events[e].meetings[m];
                let startDate = new Date(currentMeeting.start_date);
                let key = startDate.getTime();
                let endDate = new Date(currentMeeting.end_date);

                const meeting = {
                    key,
                    // id: currentMeeting.id,
                    start_date: startDate,
                    end_date: endDate
                }

                if (availableMeetings.find(meeting => meeting.key === key)) return;
                setAvailableMeetings(current => [...current, meeting]);
            }
        }
        setParseEventsReady(true);
        // addMeetingEvent(events?.id)
    }

    const addGroupHour = () => {
        if (date !== null) {
            let key = date.getTime();

            const end = add(date, { minutes: parseInt(productDuration)})

            const meeting = {
                key: key,
                start_date: date,
                end_date: end
            }

            if (availableMeetings.find(meeting => meeting.key === key)) return;
            analytics.addEvent("AddEventGroupHour");
            setAvailableMeetings(current => [...current, meeting]);
        }
    }

    const addMeetingEvent = (id?) => {
        const keyEvent = new Date().getTime();
        let event = {
            key: keyEvent,
            name: id ? id : keyEvent,
            meetings: availableMeetings,
        }
        if (availableEvents.find(event => event.key === keyEvent)) return;
        setAvailableEvents(current => [...current, event]);
        setAvailableMeetings([]);
        setEventLimit(true);
        setProductGroupReady(true);
        analytics.addEvent("GroupEventCompleted");
    }

    const calendarGroupAction = () => {
        if(availableMeetings.length >= 1) {
            return (
                <LineMeetingGroupAction>
                    <Button 
                    size="sm"
                    ghost
                    auto
                    iconRight={<FiTrash2 />}
                    onPress={() => setAvailableMeetings([])}
                    >
                        Limpar
                    </Button>
                    <Button 
                    size="sm"
                    auto
                    color="secondary"
                    iconRight={<FiCheck />}
                    onPress={() => addMeetingEvent()}
                    >
                        Finalizar Evento
                    </Button>
                </LineMeetingGroupAction>
            )
        }
    }

    const updateProduct = async () => {
        if (checkProductReady()) {
            setIsUpdating(true);
            const calendar = await prepareSchedule();
            // TODO REFACTOR IMAGE

             try {
                let product;
                if (productType === "SOLO") {
                    
                    product = await api.updateProductSolo({
                        product_id: id,
                        product_code: productCode,
                        product_name: productName,
                        product_description: productDescription,
                        product_price: productPrice,
                        product_slots: productSlot,
                        product_duration: parseInt(productDuration),
                        calendar,
                        affiliated: (allowAffiliated === "sim") ? true : false,
                        affiliated_fee: affiliatedFee,
                        affiliated_description: affiliatedDescription,
                        is_active: isActive,
                        link_group: linkGroup,
                    });
                } else {
                    product = await api.updateProductGroup({
                        product_id: id,
                        product_code: productCode,
                        product_name: productName,
                        product_description: productDescription,
                        product_price: productPrice,
                        product_slots: productSlot,
                        affiliated: (allowAffiliated === "sim") ? true : false,
                        affiliated_fee: affiliatedFee,
                        affiliated_description: affiliatedDescription,
                        is_active: isActive,
                        link_group: linkGroup,
                    });
                }
    
                if (!product.error) {
                    analytics.addEvent("ProductUpdated");
                    navigate("/dashboard/products")
                    toast({
                        id: "productDone",
                        description: "Mentoria atualizada com sucesso",
                        status: 'success',
                        duration: 4000,
                        isClosable: false,
                        position: 'top',
                    })
                }
             } catch(e) {
                analytics.addEvent("ProductUpdateError");
                toast({
                    id: "productError",
                    description: "Erro #PC302 Por favor, entre em contato com o suporte!",
                    status: 'error',
                    duration: 5000,
                    isClosable: false,
                    position: 'top',
                })
                setIsUpdating(false);
             }
        } else {
            if (!toast.isActive('productReady')) {
                analytics.addEvent("ProductErrorInfoMissing");
                toast({
                    id: "productReady",
                    description: "Por favor, preencha todos os campos disponíveis.",
                    status: 'warning',
                    duration: 4000,
                    isClosable: false,
                    position: 'top',
                })
            }
        }
    }

    const renderAffiliatedContent = () => {
        return (
            <>
            <BoxText>
                <h2>
                    Configurações para afiliação.
                </h2>
                <p>
                    Uma vez que você disponibilizar sua mentoria para afiliação, ela ficará disponível para todos usuários solicitarem autorização.
                </p>
                <h3>Taxa e comissão:</h3>
                <p>Primeiro sera descontado a sua taxa de operação que hoje é de <strong>{user.fee}%</strong>. Só então será aplicado a comissão do afiliado.</p>
            </BoxText>
            <BoxHoursAlert>
                <img src={AlertFilledIcon} alt="Aviso importante"/>
                <p>Modelo de atribuição é por último click. Isso significa que se mais de um afiliado disputarem seu link a comissão vai para o último que trouxer novamente o cliente.</p>
            </BoxHoursAlert>
            <Spacer y={1.2}/>
            Disponibilizar para afiliação:
            <FormLine>
            <RadioCt>
                <RadioButton inputId="nao" name="product-type" value="nao" onChange={(e) => setAllowAfiliated(e.value)} checked={allowAffiliated === 'nao'} />
                <label htmlFor="nao">Não</label>
            </RadioCt>
            <RadioCt>
                <RadioButton inputId="sim" name="product-type" value="sim" onChange={(e) => setAllowAfiliated(e.value)} checked={allowAffiliated === 'sim'} />
                <label htmlFor="sim">Sim</label>
            </RadioCt>
            </FormLine>
            <FormLineColumnDirection>
            <Spacer y={1}/>
            <label htmlFor="grupo">Valor da comissão em porcentagem.</label>
            <InputNumber inputId="minmax" value={affiliatedFee} onValueChange={(e) => setAffiliatedFee(e.value)} min={0} max={90} />
            <small id="username-help">
                    * O limite permitido é 90%.
                </small>
            </FormLineColumnDirection>
            <FormLineColumnDirection>
                <label htmlFor="username">Descrição para os afiliados:</label>
                {/* <InputTextarea autoResize value={affiliatedDescription} onChange={(e) => setAffiliatedDescription(e.target.value)} rows={5} cols={30} /> */}
                <div className="card">
                    <Editor maxLength={260} value={affiliatedDescription} onTextChange={(e) => setAffiliatedDescription(e.htmlValue)} headerTemplate={renderHeaderEditor()} style={{ height: '260px' }} />
                </div>
            </FormLineColumnDirection>
            </>
        )
    }

    const renderCalendarContent = () => {
        if (productType === "SOLO") {
            return (
                <>
                <BoxText>
                    <h2>
                        Defina seus horários disponíveis para mentorias.
                    </h2>
                    <p>
                        Você precisa criar uma faixa de horário para cada dia da semana em que deseja fornecer suas mentorias, o mesmo dia pode ter mais de uma faixa de horário. Use diferentes faixas de horário para separar um horário de almoço por exemplo.
                    </p>
                    <h3>Um exemplo de como podem ser seus horários:</h3>
                    <BoxHoursExemple>
                        <ul>
                            <li>Sexta das 07:00 às 11:00</li>
                            <li>Sexta das 14:00 às 16:00</li>
                            <li>Sábado das 09:00 às 11:00</li>
                        </ul>
                    </BoxHoursExemple>
                </BoxText>
                <BoxHoursAlert>
                    <img src={AlertFilledIcon} alt="Aviso importante"/>
                    <p>A disponibilidade das reuniões será calculada automáticamente baseado na sua agenda de horários e o tempo de duração definido anteriormente.</p>
                </BoxHoursAlert>
                <FormLine>
                    <InputHoursCt>
                        <Dropdown value={day} onChange={(e) => setDay(e.value)} options={week} optionLabel="name" 
                        placeholder="Selecione um dia" className="w-full md:w-14rem" />
                    </InputHoursCt>
                    <InputHoursCt>
                        <Dropdown value={startHour} onChange={(e) => setStartHour(e.value)} options={hoursToWork} optionLabel="name" 
                        placeholder="Início" className="w-full md:w-14rem" />
                    </InputHoursCt>
                    <InputHoursCt>
                        <Dropdown value={endHour} onChange={(e) => setEndHour(e.value)} options={hoursToWork} optionLabel="name" 
                        placeholder="Fim" className="w-full md:w-14rem" />
                    </InputHoursCt>
                    <InputHoursCt>
                    <Button 
                    size="lg" 
                    shadow iconRight={<img src={CheckIcon} alt="Adicionar horário"/>}
                    onPress={() => addAvailableHours()}
                    >
                        Adicionar horário
                    </Button>
                    </InputHoursCt>
                </FormLine>
                <AvailableHours removeAvailbleHours={removeAvailbleHours} hours={availableHours}/>
                </>
            )
        } else {
            return (
                <>
                    <BoxText>
                        <h2>
                            Defina as sessões da sua mentoria em grupo.
                        </h2>
                        <p>
                            Você precisa criar um evento com todas as sessões pré definidas para uma mentoria em grupo.
                        </p>
                        <h3>Um exemplo de como podem ser seu evento:</h3>
                        <BoxHoursExemple>
                            <ul>
                                <li>Sexta 16/06/2023 das 07:00 às 11:00</li>
                                <li>Sábado  17/06/2023 das 14:00 às 16:00</li>
                            </ul>
                        </BoxHoursExemple>
                    </BoxText>
                    <BoxHoursAlert>
                        <img src={AlertFilledIcon} alt="Aviso importante"/>
                        <p>Após concluir esses eventos, você pode voltar e adicinar novamente para recomeçar essa mentoria em grupo.</p>
                    </BoxHoursAlert>
                    <ContainerCalendar>
                        <Calendar 
                        value={date} 
                        locale="br"
                        showTime hourFormat="24"
                        stepMinute={15}
                        onChange={(e) => setDate(e.value)} inline /> 
                        <BoxSessions>
                        {!eventLimit && <BoxText>
                        <h2>
                            Horários:
                        </h2>
                        </BoxText>}
                        {/* <Calendar value={time} onChange={(e) => setTime(e.value)} timeOnly /> */}
                        <Spacer y={0.4}/>
                        {!eventLimit && <>
                            <Button 
                            size="sm"
                            // ghost 
                            color="primary"
                            shadow iconRight={<img src={CheckIcon} alt="Adicionar horário"/>}
                            onPress={() => addGroupHour()}
                            >
                                Adicionar horário
                            </Button>
                            <AvailableMeetings meetings={availableMeetings}/>
                            {calendarGroupAction()}
                        </>}
                        </BoxSessions>
                    </ContainerCalendar>
                    <Divider/>
                    {renderEvents()}
                </>
            )
        }
    }

    const renderEvents = () => {
        return <>
                <BoxText>
                    <h2>
                        Eventos:
                    </h2>
                </BoxText>
                <AvailableEventsGroup events={availableEvents}/>
        </>
    }

    const renderStatus = () => {
        return (
            <FormLineWithText>
                <InputSwitch checked={isActive} onChange={(e) => setIsActive(e.value)} />
                <p>Mentoria: <strong>{isActive ? 'Ativa' : 'Inativa'}</strong></p>
            </FormLineWithText>
        )
    }

    const renderHeaderEditor = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        );
    };
    
    return (
        <>
        {productReady && <motion.div 
        initial={{opacity:0, y:"50"}} 
        animate={{opacity:1, y:0}} 
        exit={{opacity:0}}
        >
            <Modal
                blur
                aria-labelledby="modal-title"
                open={isUpdating}
                preventClose
            >
                <ModalContent>
                <Loading color="secondary" size="xl" type="default" />
                <p>Atualizando sua mentoria...<br></br>É rapidinho! </p>
                </ModalContent>
            </Modal>
            <ActionCt>
                <Title>
                    <h1>Visualizando sua mentoria</h1>
                    <p>
                        Aqui você pode editar algumas das configurações da sua mentoria.
                    </p>
                </Title>
                <ActionButtons>
                <Button 
                size="lg" 
                color="secondary"
                shadow iconRight={<img src={CheckIcon} alt="Adicionar horário"/>}
                onPress={() => updateProduct()}
                >
                    Atualizar Mentoria
                </Button>
                </ActionButtons>
            </ActionCt>
            <AlertInfo type="info" content="Algumas configurações não podem ser editadas depois de criadas, se for necessário alteralas, crie uma nova mentoria."/>
            <RowInline>
            <BoxContainer>
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Geral" rightIcon="pi pi-pencil">
                        {renderStatus()}
                        <FormLineColumnDirection>
                            <label htmlFor="username">Nome da mentoria:</label>
                            <InputText 
                            className={productNameInvalid ? 'p-invalid' : ''}
                            id="username" 
                            aria-describedby="username-help"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            />
                        </FormLineColumnDirection>
                        <FormLine>
                            <p>
                                Tipo de mentoria: <strong> {productType}</strong>
                            </p>
                        </FormLine>
                        <FormLine>
                            <p>
                                Código do produto:  <strong> {productCode}</strong>
                            </p>
                        </FormLine>
                        {(productType === "SOLO") && <FormLine>
                            Duração da mentoria:
                            <RadioCt>
                                <RadioButton inputId="30" name="duration" value="30" onChange={(e) => setProductDuration(e.value)} checked={productDuration === '30'} />
                                <label htmlFor="30">30m</label>
                            </RadioCt>

                            <RadioCt>
                                <RadioButton inputId="60" name="duration" value="60" onChange={(e) => setProductDuration(e.value)} checked={productDuration === '60'} />
                                <label htmlFor="60">1h</label>
                            </RadioCt>

                            <RadioCt>
                                <RadioButton inputId="90" name="duration" value="90" onChange={(e) => setProductDuration(e.value)} checked={productDuration === '90'} />
                                <label htmlFor="90">1:30h</label>
                            </RadioCt>

                            <RadioCt>
                                <RadioButton inputId="120" name="duration" value="120" onChange={(e) => setProductDuration(e.value)} checked={productDuration === '120'} />
                                <label htmlFor="120">2h</label>
                            </RadioCt>

                            <RadioCt>
                                <RadioButton inputId="150" name="duration" value="150" onChange={(e) => setProductDuration(e.value)} checked={productDuration === '150'} />
                                <label htmlFor="150">2:30h</label>
                            </RadioCt>

                            <RadioCt>
                                <RadioButton inputId="180" name="duration" value="180" onChange={(e) => setProductDuration(e.value)} checked={productDuration === '180'} />
                                <label htmlFor="180">3h</label>
                            </RadioCt>
                        </FormLine>}
                        {(productType === "GROUP") && <FormLine>
                            Duração da mentoria: {productDuration} minutos
                        </FormLine>}
                        <FormLine>
                        Convidar para grupo após confirmação de compra:
                        <RadioCt>
                            <RadioButton inputId="invite-group-false" name="invite-group" value={false} onChange={(e) => setInviteGroup(e.value)} checked={!inviteGroup} />
                            <label htmlFor="invite-group-false">Não</label>
                        </RadioCt>

                        <RadioCt>
                            <RadioButton inputId="invite-group-true" name="invite-group" value={true} onChange={(e) => setInviteGroup(e.value)} checked={inviteGroup} />
                            <label htmlFor="invite-group-true">Sim</label>
                        </RadioCt>
                        </FormLine>
                        {inviteGroup && <> 
                            <FormLineColumnDirection>
                                <label htmlFor="link-group">Link do grupo:</label>
                                <InputText 
                                id="link-group" 
                                aria-describedby="link-group"
                                value={linkGroup}
                                onChange={(e) => setLinkGroup(e.target.value)}
                                />
                            </FormLineColumnDirection>
                        </>}
                        <FormLineColumnDirection>
                        <AlertInfo type="alert" content="O número de sessões é a quantidade de sessões inclusas nessa mentoria pelo preço total determinado abaixo. Por exemplo, se essa mentoria tiver 4 sessões e o preço da mentoria for de R$1.000, isso significa que cada sessão está custando R$250, mas o cliente precisa agendar e pagar as 4 sessões de uma vez."/>
                        <Spacer y={1}/>
                        <label htmlFor="grupo">{(productType === "SOLO") ? <>Número de sessões:</> : <>Vagas disponíveis</>}</label>
                        <InputNumber inputId="minmax" value={productSlot} onValueChange={(e) => setProductSlot(e.value)} min={0} max={100} />
                        <small id="username-help">
                                * O limite permitido é 100.
                            </small>
                        </FormLineColumnDirection>
                        <Spacer y={1}/>
                        <FormLineColumnDirection>
                            <label htmlFor="username">Descrição da mentoria:</label>
                            {/* <InputTextarea autoResize value={productDescription} onChange={(e) => setProductDescription(e.target.value)} rows={5} cols={30} /> */}
                            <div className="card">
                            <Editor maxLength={440} value={productDescription} onTextChange={(e) => setProductDescription(e.htmlValue)} headerTemplate={renderHeaderEditor()} style={{ height: '260px' }} />
                            </div>
                        </FormLineColumnDirection>
                        <FormLineColumnDirection>
                             <label htmlFor="currency-us" className="font-bold block mb-2">Valor da mentoria:</label>
                            <InputNumber inputId="currency-us" value={productPrice} onValueChange={(e) => setProductPrice(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
                            <small id="username-help">
                                * valor mínimo permitido é R$10,00
                            </small>
                        </FormLineColumnDirection>
                    </TabPanel>
                    <TabPanel header="Agenda" rightIcon="pi pi-fw pi-calendar">
                        {renderCalendarContent()}
                    </TabPanel>
                    <TabPanel header="Afiliado" rightIcon={<FiSettings size={16}/>}>
                        {renderAffiliatedContent()}
                    </TabPanel>
                    <TabPanel header="Conectados" rightIcon={<FiUsers size={16}/>}>
                        <AffiliatedList productId={id}/>
                    </TabPanel>
                </TabView>
            </BoxContainer>
            </RowInline>
        </motion.div>}
        </>
    )
}

export default ProductEditPage;