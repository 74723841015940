import styled from "styled-components";

export const ContainerCenter = styled.div`
    margin-top:14px;
    display: flex;
    width: 100%;
    border-top:1px solid #eee;
    /* height: 200px; */
    align-items:center;
    justify-content: center;
    flex-direction: column;
`

export const ContainerLogo = styled.div`
    display: flex;
    margin-top:24px;
    margin-bottom:20px;
`

export const LineInfo = styled.div`
    display: flex;
    margin-bottom:24px;
    h2 {
        font-size:24px;
    }
`

export const ContentInfo = styled.div`
    display: flex;
    background:#f5f5f5;
    flex-direction: column;
    padding:20px;
    margin-bottom:26px;
`

export const Item = styled.div`
    display: flex;    
    justify-content: flex-start;
    align-items: center;
    margin-bottom:20px;
    &:last-of-type{
        margin-bottom:0px;
    }
`

export const ItemIcon = styled.div`
    display: flex;
    justify-content: center;
    width: 50px;
    align-items: center;
    margin-right: 20px;
`

export const ItemText = styled.div`
    display: flex;
    flex-direction: column;
    h2{
        font-size:18px;
        font-weight: 600;
    }
`

export const ModalAlertText = styled.div`
    display: flex;
    flex-direction: column;
    /* background-color: #444; */
    width: 100%;
    a{
        color:blue;
    }
    margin-top:10px;
    margin-bottom:14px;
`

export const SyncContainer = styled.div`
    display: flex;
    padding:20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const SyncItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export default ContainerCenter;