import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    position: relative;
    min-height: 130px;
    @media (max-width:768px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top:20px;
    }
`

export const ContentImage = styled.div`
    display: flex;
    /* position: absolute; */
    /* left: -50px; */
    /* top:-35px; */
    /* margin-left: -40px; */
    width: 20%;
    @media (max-width:768px) {
        width: 100%;
        justify-content: center;
    }
`

export const Content = styled.div`
    display: flex;
    width: 80%;
    /* background-color: #444; */
    justify-content: flex-start;
    align-items: center;
    padding-left:10px;
`

export const Block = styled.div`
    display: flex;
    /* margin-left:24px; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    h2{
        font-size: 24px;
        font-weight: 800;
        color:#582489;
    }
    p{
        font-size: 14px;
    }
`
export const Text = styled.div`
    h2{
        font-size: 24px;
        font-weight: 800;
        color:#582489;
    }
    p{
        font-size: 14px;
    }
`

export const Cta = styled.div`
    display: flex;
    flex:1;
    background-color: #582489;
    font-size: 16px;
    color:#fff;
    padding:14px 20px;
    border-radius: 8px;
    font-weight: 600;
    margin-top:20px;
    justify-content: center;
    align-items: center;
    p{
        font-size: 16px;
        margin-right: 10px;
    }
`

export default Container;