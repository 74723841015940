import { motion } from 'framer-motion'
import { useEffect, useState } from 'react';
import { UseApi } from '../../hooks/UseApi';
import { useAuth } from '../../Auth/AuthProvider';
import WelcomeCt, { BoxChart, BtnSolid, CtaBorder, GridBox, GridContent, GridContentSolo, GridCtDivisor, GridStaticContent, GridStaticCt, GridStaticIcon, GridW3, GridW6, GridWalletCt, GridWalletIcon, GridWalletLine, MeetingCt, MeetingCta, Row, RowCenter, RowColumn, SlotAvart, SlotsLine } from './styles';
import RowInline, { BoxContainer, ContentLoading } from '../../styles/main';
import { Loading } from '@nextui-org/react';
import ProductList from '../../components/ProductList';
import EmptyResult from '../../components/EmptyResult';
import { Chart } from 'primereact/chart';
import { format, subDays } from 'date-fns';
import { FiCheckCircle, FiCalendar, FiClock } from "react-icons/fi";
// import { background } from '@chakra-ui/react';
import { Avatar, Button } from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';
import UserUrlPage from '../../components/UserUrl';
import NeedSales from '../../components/NeedSales';


const Home = (props) => {
    const api = UseApi();
    const navigate = useNavigate();

    const { user, toggleMainLoader } = useAuth();

    const [ products, setProducts ] = useState([]);

    const [loadingProducts, setLoadingProducts] = useState(true);

    const [ chartData, setChartData ] = useState({});
    const [ chartOptions, setChartOptions ] = useState({});
    const [ salesLabels, setSalesLabels ] = useState([]);
    const [ salesValues, setSalesValues ] = useState([]);
    const [ approvedValues, setApprovedValues ] = useState([]);
    const [ salesReportReady, setSalesReportReady ] = useState(false);
    const [ myWallet, setMyWallet ] = useState<any>({});
    const [ totalOrders, setTotalOrders ] = useState(0);
    const [ totalMeetings , setTotalMeetings ] = useState(0);
    const [ nextMeeting, setNextMeeting ] = useState<any>({});
    const [ nextMeetingReady, setNextMeetingReady ] = useState(false);
    const [ qtdSlots, setQtdSlots ] = useState(0);
    const [ walletReady, setWalletReady ] = useState(false);
    
    


    const getSalesByDay = async () => {
        const data = await api.getSalesByDay();
        
        let days = [];
        let sales = [];
        let approved = [];
        setTotalOrders(data.orders.total);
        setTotalMeetings(data.meetings.total)
        // console.log(data.nextMeeting.slots);
        setNextMeeting(data.nextMeeting);
        ///////
        if (Object.keys(data.nextMeeting).length > 1) {
            setQtdSlots(data.nextMeeting.slots.length);
        }
    
        for (let i = 0; i < 30; i++) {
            days.push(format(subDays(new Date(), i), 'dd/MM'))
            if (data.sales[i] === undefined) {
                sales.push(0);
            } else {
                sales.push(data.sales[i].sales);
            }
            if (data.approved[i] === undefined) {
                approved.push(0)
            } else {
                approved.push(data.approved[i].sales);
            }
        }
        setSalesLabels(days.reverse());
        setSalesValues(sales.reverse());
        setApprovedValues(approved.reverse());  
        setSalesReportReady(true);
    }   

    const getMyWallet = async () => {
        const data = await api.getMyWallet();
        setMyWallet(data);
        setWalletReady(true);
        setTimeout(() => {
            toggleMainLoader(false);
        },600)
    }

    useEffect(() => {
        toggleMainLoader(true);
        getSalesByDay();
        getMyWallet();
    },[])

    useEffect(() => {
        if (Object.keys(nextMeeting).length >= 1) {
            setNextMeetingReady(true);
        }
    },[nextMeeting])

    useEffect(() => {
        if (salesReportReady) {
            const documentStyle = getComputedStyle(document.documentElement);

            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
            

            const data = {
                labels: salesLabels,
                datasets: [
                    {
                        label: 'Pagamento confirmado',
                        // data: salesValues,
                        data: approvedValues,
                        fill: true,
                        // borderDash: [5, 5],
                        borderColor: "#4b1584",
                        tension: 0.4,
                        backgroundColor: '#4b158446'
                    },
                    {
                        label: 'vendas',
                        data: salesValues,
                        fill: true,
                        borderDash: [5, 5],
                        tension: 0.4,
                        borderColor: "#8ad6ff31",
                        backgroundColor:"#8ad6ff31"
                    },
                ]
            };
            const options = {
                maintainAspectRatio: false,
                aspectRatio: 1.4,
                layout: {
                    padding: 0,
                    lines: false,
                },
                plugins: {
                    legend: {
                        display: false,
                        labels: {
                            color: textColor,
                            display: true
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary,
                            display:true,
                        },
                        grid: {
                            color: surfaceBorder,
                            display: false,
                        }
                    },
                    y: {
                        beginAtZero:true,
                        ticks: {
                            color: "#ccc",
                            display: true,
                        },
                        grid: {
                            color: '#eee',
                            display: true,
                        }
                    }
                }
            };
            setChartData(data);
            setChartOptions(options);
        }
    }, [salesReportReady]);

    useEffect(() => {
        getProducts();
    }, [])


    const getProducts = async () => {
        const data = await api.getProductsByUser();
        if (data.length >= 1) {
            setProducts(data);
        }
        setLoadingProducts(false);
    }

    const renderProducts = () => {
        if (products.length >= 1) {
            return (
                <ProductList products={products}/>
            )
        } else {
            // return <EmptyResult text="Nenhuma mentoria cadastrada"/>
            return (
                <RowCenter>
                    <p>Você ainda não criou nenhuma mentoria</p>
                    <BtnSolid onClick={() => navigate("/dashboard/products/create")}>
                        Criar nova mentoria
                    </BtnSolid>
                </RowCenter>
            )
        }
    }

    const renderSlotsMeeting = () => {
        return (
            nextMeeting.slots.map((slot, index) => {
                return (
                    <Avatar size="sm" key={index} pointer text={slot.name} stacked />
                )
            })
        )
    }

    const renderNextMeeting =() => {
        if (nextMeetingReady && Object.keys(nextMeeting).length >= 1) {
            return (
                <MeetingCt>
                    <MeetingCta>
                    <Button size="xs" color="secondary" auto ghost onPress={() => window.open(`${nextMeeting.zoom_link}`, '_blank')}>
                        Entrar  
                    </Button>
                    </MeetingCta>
                Próxima mentoria:
                <h1>
                    {nextMeeting.product_name}
                </h1>
                <p>horário: {format(new Date(nextMeeting.start_date), 'HH:mm')} às {format(new Date(nextMeeting.end_date), 'HH:mm')}</p>
                <p>Dia: {format(new Date(nextMeeting.end_date), 'dd/MM/yyyy')}</p>
                
                <SlotsLine>
                    <h2>
                        Participantes:
                    </h2>
                    <SlotAvart>
                        <Avatar.Group count={qtdSlots}>
                            {nextMeetingReady && renderSlotsMeeting()}
                        </Avatar.Group>
                    </SlotAvart>
                </SlotsLine>

                </MeetingCt>
            )
        } 

        if (Object.keys(nextMeeting < 1)) {
            return (
                <RowCenter>
                    <EmptyResult text="Nenhuma mentoria agendada"/>
                </RowCenter>
            )
        }
    }

    const renderWithDraw = () => {
        if (myWallet?.wallet?.amount > 0) {
            return (
                <CtaBorder onClick={() => navigate("/dashboard/orders")}> 
                    Solicitar saque
                </CtaBorder>
            )
        } else {
            return <>Valor total acumulado.</>
        }
    }

    const renderTopBarProducts = () => {
        if (products?.length >= 1) {
            return <>Minhas mentorias</>
        }
    }
    
    return (
        <motion.div 
        initial={{opacity:0, y:"50"}} 
        animate={{opacity:1, y:0}} 
        exit={{opacity:0}}
        >
            <Row>
                <WelcomeCt>
                   <h1>
                   Olá {user.name}
                   </h1>
                   <p>
                    bem vindo ao mentorados!
                   </p>
                </WelcomeCt>
            </Row>
            {totalOrders == 0 && <Row style={{marginTop:20}}>
                <BoxContainer>
                    <NeedSales/>    
                </BoxContainer>    
            </Row>}
            <Row>
                <RowColumn>
                    {/* <GridW3>
                    <BoxContainer>
                        <UserUrlPage user={user}/>
                    </BoxContainer>
                    </GridW3>
                    <GridW6>
                    <BoxContainer>
                        Desempenho em vendas dos últimos 30 dias.
                    <Chart type="line" data={chartData} options={chartOptions} />
                    </BoxContainer>
                    </GridW6> */}
                    <BoxContainer>
                        Desempenho em vendas dos últimos 30 dias.
                    <Chart type="line" data={chartData} options={chartOptions} />
                    </BoxContainer>
                </RowColumn>
            </Row>
            <Row>
                <GridBox>
                <GridContentSolo>
                <BoxContainer>
                        {renderNextMeeting()}
                </BoxContainer>
                </GridContentSolo>
                </GridBox>
                <GridBox>
                    <GridContent>
                        <BoxContainer>
                            <GridStaticCt>
                                <GridStaticIcon style={{backgroundColor:"#53c28134"}}>
                                    <FiCheckCircle color='#3cd278' size={40}/>
                                </GridStaticIcon>
                                <GridStaticContent>
                                    <h2>{totalOrders}</h2>
                                    <p>Total de pedidos</p>
                                </GridStaticContent>
                            </GridStaticCt>
                        </BoxContainer>
                    </GridContent>
                    <GridContent>
                    <BoxContainer>
                    <GridStaticCt>
                            <GridStaticIcon style={{backgroundColor:"#e3f4f6"}}>
                                <FiCalendar color='#17a2b8' size={40}/>
                            </GridStaticIcon>
                            <GridStaticContent>
                                <h2>{totalMeetings}</h2>
                                <p>Total de Mentorias</p>
                            </GridStaticContent>
                        </GridStaticCt>
                    </BoxContainer>
                    </GridContent>
                </GridBox>
                <GridBox>
                <GridContentSolo>
                <BoxContainer>
                        <GridWalletCt>
                            <GridWalletLine>
                                <h2>
                                    Saldo disponível
                                </h2>
                            </GridWalletLine>
                            <h3>
                                {walletReady && <>R${myWallet.wallet.amount}</>}
                            </h3>
                            {renderWithDraw()}
                            <GridCtDivisor/>
                            <GridWalletLine>
                                <GridWalletIcon>
                                    <FiClock size={22} color='#888'/>
                                </GridWalletIcon>
                                <GridWalletCt>
                                <h4>
                                    Total a receber
                                </h4>
                                <h5>
                                    {walletReady && <>R${myWallet.wallet.amountPending}</>}
                                </h5>
                                </GridWalletCt>
                            </GridWalletLine>
                        </GridWalletCt>
                </BoxContainer>
                </GridContentSolo>
                </GridBox>
            </Row>
            <RowInline>
                <BoxContainer>
                    {renderTopBarProducts()}
                    {loadingProducts ? <ContentLoading><Loading color="secondary" size="xl" type="points" /></ContentLoading> : renderProducts()}
                </BoxContainer>
            </RowInline>
        </motion.div>
    )
}

export default Home;