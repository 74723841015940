import { useEffect, useState } from "react";
import { UseApi } from "../../hooks/UseApi";
import { useAuth } from "../../Auth/AuthProvider";
import { motion } from 'framer-motion'
import { Loading } from "@nextui-org/react";
import { PageTitle } from "../../styles/main";
import ContainerCenter, { CardCt, InfoCt, InfoValue } from "./styles";
import { Card, Col, Text, Button, Row, Spacer } from "@nextui-org/react";
import AffiliateRequest from "./AffiliateRequest";
import IndividualIcon from "../../Assets/icons/affiliated11Icon.svg";
import GroupIcon from "../../Assets/icons/affiliatedGroupIcon.svg";

const Affiliates = () => {
    const api = UseApi();
    const { toggleMainLoader } = useAuth();

    const [ products, setProducts ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(() => {
        toggleMainLoader(true);
        getProductsToAffiliated();
        return () => {
            toggleMainLoader(false);
        }
    },[])

    const getProductsToAffiliated = async () => {
        const data = await api.getProductsToAffiliated();
        setProducts(data);
        toggleMainLoader(false);
        setIsLoading(false);
    }

    const renderType = (type) => {
        if (type ==="SOLO") {
            return (
                <>
                Individual 
                <img style={{marginLeft:10}} src={IndividualIcon} alt="individual"/>
                </>
            )
        } else {
            return (
                <>
                Em grupo
                <img style={{marginLeft:10}} src={GroupIcon} alt="individual"/>
                </>
            )
        }
    }

    const renderProducts = () => {
        if (products.length < 1) {
            return <>Nenhum produto disponível.</>
        } else {
            return (
                <>
                    {products.map((product) => (
                        <CardCt key={product.id}>
                            <Card css={{ mw: "330px" }}>
                                <Card.Header>
                                    <Col>
                                        <Text b size={18}>{product?.user?.name}</Text>
                                        <Text h2>{product.productName}</Text>
                                    </Col>
                                </Card.Header>
                                <Card.Body css={{ py: "$10" }}>
                                    <Text>
                                    {product.productDescription}
                                    </Text>
                                    <Text>
                                    {product.affiliatedDescription}
                                    </Text>
                                    <Spacer y={1.2}/>
                                    {/* <Card.Divider /> */}
                                    <InfoCt>
                                        <InfoValue>
                                            Valor de venda: 
                                        </InfoValue>
                                        <InfoValue>
                                            R${product.productPrice.toLocaleString('pt-br', {minimumFractionDigits: 2})}  
                                        </InfoValue> 
                                    </InfoCt>
                                    <InfoCt>
                                        <InfoValue>
                                            Comissão:
                                        </InfoValue>
                                        <InfoValue>
                                            {product.affiliatedFee}%   
                                        </InfoValue> 
                                    </InfoCt>
                                    <InfoCt>
                                        <InfoValue>
                                            Tipo de mentoria 
                                        </InfoValue>
                                        <InfoValue>
                                            {renderType(product.productType)}
                                        </InfoValue> 
                                    </InfoCt>
                                    <InfoCt>
                                        <InfoValue>
                                            Duração 
                                        </InfoValue>
                                        <InfoValue>
                                            {product.meetingDuration} min
                                        </InfoValue> 
                                    </InfoCt>
                                </Card.Body>
                                {/* <Card.Divider /> */}
                                <Card.Footer>
                                    <Row justify="flex-end">
                                        <AffiliateRequest productId={product.id}/>
                                    {/* <Button disabled={true} color="secondary" size="sm">Solicitar afiliação</Button> */}
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </CardCt>
                    ))}
                </>
            )
        }
    }

    const renderWait = () => {
        return (
            <>Disponilizaremos a loja de afiliados em breve.</>
        )
    }

    return (
        <motion.div 
        initial={{opacity:0, y:"50"}} 
        animate={{opacity:1, y:0}} 
        exit={{opacity:0}}
        >
            <PageTitle>
                <h1>Mentorias disponíveis para afiliação.</h1>
                <p>O primeiro passo para uma grande parceria de sucesso.</p>
            </PageTitle>
            <ContainerCenter>
                {/* {isLoading ? 
                <Loading color="secondary" type="points">Selecionando os melhores produtos...</Loading> : 
                renderProducts()} */}
                {renderWait()}
            </ContainerCenter>
        </motion.div>
    )
}

export default Affiliates;