import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Button, Row, Spacer, Loading } from "@nextui-org/react";
import WelcomeCt from "../Home/styles";
import RowInline, { BoxContainer, FormLine, LineButtonRight, RadioCt } from "../../styles/main";
import { UseApi } from "../../hooks/UseApi";
import { Input } from "@nextui-org/react";
import { ActionContainer } from "./styles";
import { BoxHoursAlert, BoxHoursExemple, BoxText } from "../ProductCreate/styles";
import AlertFilledIcon from "../../Assets/icons/alertFilledIcon.svg";
import { useToast } from "@chakra-ui/react";
import { useAuth } from "../../Auth/AuthProvider";
import { TabView, TabPanel } from 'primereact/tabview';
import QRCode from "react-qr-code";
import VerificationPage from "../../components/VerificationId";
import Analytics from "../../Analytics";
import { RadioButton } from "primereact/radiobutton";
import AccountStatus from "./AccountStatus";

const ConfigurationPage = () => {
    const api = UseApi();
    const toast = useToast();
    const { user } = useAuth();
    const analytics = Analytics();

    const [ username, setUsername ] = useState('');
    // const [ initialCode, setInitialCode ] = useState('');
    // const [ initialUrl, setInitialUrl ] = useState('');
    const [ usernameStatus, setUsernameStatus ] = useState('default');
    const [ updated, setUpdated ] = useState(false);
    const [ url, setUrl ] = useState('');
    const [ invalidUrl, setInvalidUrl ] = useState(false);
    const [ metaPixel, setMetaPixel ] = useState('');
    const [ googlePixel, setGooglePixel ] = useState('');
    const [ tiktokPixel, setTiktokPixel ] = useState('');

    const [ hasUserBankInfo, setHasUserBankInfo ] = useState(false);
    const [ isLoadingBank, setIsLoadingBank ] = useState(false);
    const [ documentType, setDocumentType ] = useState('PJ');
    const [ documentName, setDocumentName ] = useState('');
    const [ documentId, setDocumentId ] = useState('');
    const [ pix, setPix ] = useState('');
    const [ documentNameInvalid, setDocumentNameInvalid ] = useState(false);
    const [ documentIdInvalid, setDocumentIdInvalid ] = useState(false);
    const [ pixInvalid, setPixInvalid ] = useState(false);

    const [activeIndex, setActiveIndex] = useState(0);


    useEffect(() => {
        if (user.username !== null) setUsername(user.username);
        if (user.url !== null) setUrl(user.url);
        if (user.metaPixel !== null) setMetaPixel(user.metaPixel);
        if (user.tiktokPixel !== null) setTiktokPixel(user.tiktokPixel);
        if (user.googlePixel !== null) setGooglePixel(user.googlePixel);
        analytics.addPage({page: "/dashboard/configs",title: "Configurações"})
        checkUserDetails();
    },[])

    const checkUserDetails = async () => {
        try {
            const data = await api.getUserDetails();
            if (data?.id) {
                setDocumentId(data.documentId)
                setDocumentType(data.documentType);
                setDocumentName(data.documentName);
                setPix(data.pix);
                setHasUserBankInfo(true);
            }

        } catch(e) {
            console.log('get user details error');
        }
    }

    useEffect(() => {
        // console.log(url);
        if (url.length >1 ) {
            validationUrl(url);
        } else {
            setInvalidUrl(false);
        }
    },[url])

    useEffect(() => {
        if (documentId.length > 1) {
            setDocumentIdInvalid(false);
        }
    },[documentId])
    
    useEffect(() => {
        if (documentName.length > 1) {
            setDocumentNameInvalid(false);
        }
    },[documentName])

    useEffect(() => {
        if (pix.length > 1) {
            setPixInvalid(false);
        }
    },[pix])

    const validationUrl = (url) => {
//         const pattern =
//   /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
        const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g

        // console.log(pattern.test(url));
        if (pattern.test(url)) {
            setInvalidUrl(false);
        } else {
            setInvalidUrl(true);
        }
    }


    const updateConfings = async () => {
        if (invalidUrl) {
            toast({
                id: 'configs',
                description: "Url inválida!",
                status: 'error',
                duration: 2000,
                isClosable: false,
                position: 'top',
            })

            return; 
        }
        
        if (username.length > 4) {
            const data = await api.updateConfigs({
                username,
                url,
                meta_pixel: metaPixel,
                google_pixel: googlePixel,
                tiktok_pixel: tiktokPixel
            })
            if (!data.error) {
                toast({
                    id: 'configs',
                    description: "Configurações atualizadas com sucesso!",
                    status: 'success',
                    duration: 2000,
                    isClosable: false,
                    position: 'top',
                })
                user.username = username;
                user.url = url;
                user.metaPixel = metaPixel;
                user.googlePixel = googlePixel;
                user.tiktokPixel = tiktokPixel;
                analytics.addEvent('UsernameUpdated')
                setUpdated(true);
            } else {
                if (!toast.isActive('already-exist')) {
                    toast({
                        id: 'already-exist',
                        description: "nome de usuário já está em utilização",
                        status: 'error',
                        duration: 2000,
                        isClosable: false,
                        position: 'top',
                    })
                }
            }
        } else {
            setUsernameStatus('error');
            if (!toast.isActive('username')) {
                toast({
                    id: 'username',
                    description: "username com minímo de caracteres inválido.",
                    status: 'error',
                    duration: 2000,
                    isClosable: false,
                    position: 'top',
                })
            }
        }

    }

    const updateUserBankAccount = async () => {
        let errors = false;
        if (documentName.length < 1) {
            setDocumentNameInvalid(true);
            errors = true;
        }

        if (documentId.length < 11) {
            setDocumentIdInvalid(true);
            errors = true;
        }

        if (pix.length < 1) {
            setPixInvalid(true);
            errors = true;
        }

        if (errors) {
            if (!toast.isActive('bankAccount')) {
                toast({
                    id: 'bankAccount',
                    description: "Por favor preencha todos os dados.",
                    status: 'error',
                    duration: 2000,
                    isClosable: false,
                    position: 'top',
                })
            }
        }

        if (!errors) {
            setIsLoadingBank(true);
            try {
            
                const data = await api.updateUserBankAccount({
                    document_type: documentType,
                    document_id: documentId,
                    document_name: documentName,
                    pix
                })
                if (data?.documentId) {
                    if (!toast.isActive('saveSuccess')) {
                        toast({
                            id: 'saveSuccess',
                            description: "Dados salvos com sucesso.",
                            status: 'success',
                            duration: 2000,
                            isClosable: false,
                            position: 'top',
                        })
                    }
                    analytics.addEvent("AddBankInfo");
                }
            } catch(e) {
                if (!toast.isActive('saveError')) {
                    toast({
                        id: 'saveError',
                        description: "Por favor entre em contato com o suporte #CS981",
                        status: 'error',
                        duration: 2000,
                        isClosable: false,
                        position: 'top',
                    })
                }
            } finally {
                setIsLoadingBank(false);
            }
        }
    }

    const maskUserName = (value) => {
        return value.replace(/[^a-z0-9-/]/g, '')
    }

    const renderTabs = () => {
        return (
            <div className="card">
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Geral">
                <RowInline>
                        {/* box account status */}
                    <AccountStatus user={user}/>
                </RowInline>
                <RowInline>
                        <BoxText>
                            <h2>Nome de usuário:</h2>
                            <p>Seu nome de usuário é importante para conseguir gerar os links das suas mentorias.</p>
                        </BoxText>
                        <BoxHoursExemple>
                            <ul>
                                <li>Utilize apenas letras minúsculas.</li>
                                <li>Ou números.</li>
                                <li>Sinal hífen (-) é permitido.</li>
                                <li>Minímo de 4 letras.</li>
                            </ul>
                        </BoxHoursExemple>
                        <Spacer y={1.4}/>

                        <Input
                        aria-labelledby="username"
                        underlined 
                        labelLeft="@username" 
                        value={username}
                        onChange={(e) => setUsername(maskUserName(e.target.value))}
                        placeholder="fulano-de-tal"
                        />
                    </RowInline>
                    <RowInline>
                        <BoxText>
                            <h2>Url landing page:</h2>
                            <p>Adicione a url da sua landing page principal.</p>
                        </BoxText>
                        <Spacer y={-0.2}/>
                        <Input
                        aria-labelledby="https://seusite.com.br"
                        underlined 
                        labelLeft="url" 
                        value={url}
                        status={invalidUrl ? 'error': 'default'}
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder="https://www.seusite.com.br"
                        />
                    </RowInline>
                    {/* <RowInline>
                        <BoxText>
                            <h2>Pixel de rastreamento.</h2>
                            <p>Seu nome de usuário é importante para conseguir gerar os links das suas mentorias.</p>
                        </BoxText>
                        <BoxHoursAlert>
                         <img src={AlertFilledIcon} alt="Aviso importante"/>
                         <p>Seu pixel só sera chamado quando utilizar dos seus próprios links, caso um afiliado gere tráfego, apenas o pixel dele será ativado.</p>
                        </BoxHoursAlert>
                        <Input 
                        aria-labelledby="meta"
                        underlined 
                        labelLeft="Pixel Meta" 
                        placeholder="" 
                        value={metaPixel}
                        onChange={(e) => setMetaPixel(e.target.value)}
                        />
                        <Input 
                        aria-labelledby="tiktok"
                        underlined 
                        labelLeft="Pixel Tiktok" 
                        placeholder="" 
                        value={tiktokPixel}
                        onChange={(e) => setTiktokPixel(e.target.value)}
                        />
                        <Input 
                        underlined 
                        aria-labelledby="analytics"
                        labelLeft="Pixel Analytics" 
                        placeholder="" 
                        value={googlePixel}
                        onChange={(e) => setGooglePixel(e.target.value)}
                        />
                    </RowInline> */}
                    <LineButtonRight>
                    <Button shadow color="secondary" onPress={() => updateConfings()}>Salvar Alterações</Button>
                    </LineButtonRight>
                </TabPanel>
                <TabPanel header="Dados bancários">
                        <BoxText>
                            <h2>Seus dados bancários:</h2>
                            <p>Configure a conta onde vai receber seus saques.</p>
                        </BoxText>
                        <Spacer y={-0.4}/>
                        { hasUserBankInfo ? <>Conta: {documentType === 'PF' ? 'Pessoa Física' : 'Pessoa Jurídica'}</> : <>
                        <FormLine>
                            <RadioCt>
                                <RadioButton inputId="pj" name="document-type" value="PJ" onChange={(e) => setDocumentType(e.value)} checked={documentType === 'PJ'} />
                                <label htmlFor="pj">Pessoa Jurídica</label>
                            </RadioCt>
                            <RadioCt>
                                <RadioButton inputId="pf" name="document-type" value="PF" onChange={(e) => setDocumentType(e.value)} checked={documentType === 'PF'} />
                                <label htmlFor="pf">Pessoa física</label>
                            </RadioCt>
                        </FormLine>
                        </>}
                        
                        <RowInline>
                            <Input
                            aria-labelledby="documentName"
                            underlined 
                            disabled={hasUserBankInfo}
                            labelLeft={documentType === "PJ" ? "Razão social" : "Nome completo"} 
                            value={documentName}
                            status={documentNameInvalid ? 'error' : 'default'}
                            onChange={(e) => setDocumentName(e.target.value)}
                            placeholder=""
                            />
                        </RowInline>
                        <RowInline>
                            <Input
                            aria-labelledby="documentId"
                            underlined 
                            disabled={hasUserBankInfo}
                            labelLeft={documentType === "PJ" ? "CNPJ" : "CPF"} 
                            value={documentId}
                            status={documentIdInvalid ? 'error' : 'default'}
                            onChange={(e) => setDocumentId(e.target.value)}
                            placeholder=""
                            />
                        </RowInline>
                        <RowInline>
                        <Input
                            aria-labelledby="chavePix"
                            underlined 
                            labelLeft="Chave Pix"
                            value={pix}
                            status={pixInvalid ? 'error' : 'default'}
                            onChange={(e) => setPix(e.target.value)}
                            placeholder=""
                            />
                        </RowInline>
                    <p className="m-0">
                        Evite estornos e falhas no saque: a chave pix deve pertencer ao CNPJ ou CPF informado.
                    </p>
                    <LineButtonRight>
                    <Button shadow color="secondary" onPress={() => updateUserBankAccount()}>
                        {isLoadingBank ? <><Loading color="currentColor" size="sm" /></> : <>Salvar Alterações</>}
                    </Button>
                    </LineButtonRight>
                </TabPanel>
                <TabPanel header="Identidade">
                    <VerificationPage user={user}/>
                </TabPanel>
            </TabView>
        </div>
        )
    }

    return (
        <motion.div 
        initial={{opacity:0, y:"50"}} 
        animate={{opacity:1, y:0}} 
        exit={{opacity:0}}
        >
            <Row>
                <ActionContainer>
                <WelcomeCt>
                    <h1>
                        Configurações
                    </h1>
                    <p>
                        O saldo da venda só é adicionado a sua carteira após o pagamento ser confirmado.
                    </p>
                </WelcomeCt>
                
                </ActionContainer>
            </Row>
            <RowInline>
                <BoxContainer>
                    {renderTabs()}
                </BoxContainer>
            </RowInline>
        </motion.div>
    )
}

export default ConfigurationPage;