import * as ReactDOM from 'react-dom/client';
import { ChakraProvider, extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { AuthProvider } from './Auth/AuthProvider';
import AppRoutes from './Routes';
import "primereact/resources/themes/lara-light-indigo/theme.css"; 
import "primereact/resources/primereact.min.css";
import './styles/main.css'
// import reportWebVitals from './reportWebVitals';
import Analytics from './Analytics';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://b08b7049ca8841a7a697e79f29366eea@o4505518985838592.ingest.sentry.io/4505519078899712",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https//:app.mentorados.com", "https://api.mentorados.com"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const analytics = Analytics();
analytics.init();


const theme = extendTheme({ config })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ChakraProvider theme={theme}>
      <AuthProvider>
        <AppRoutes/>
      </AuthProvider>
  </ChakraProvider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorker.register()

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)