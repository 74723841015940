import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { EventContainer, EventItem, EventMeetings, EventMeetingsInProgress, EventTitle, LineMeeting } from "./styles";
import { LuCalendarCheck } from "react-icons/lu";

const AvailableEventsGroup = (props) => {

    const renderMeetings = (meetings) => {
        return (
            meetings.map(meeting => {
                return (
                    <LineMeeting key={meeting.key}>
                        <LuCalendarCheck size={22} color="#888"/>
                        <p>
                        {format(new Date(meeting.start_date), 'dd \'de\' LLLL \'de\' yyyy \'das\' HH:mm ' , { locale: ptBR } )}
                        às {format(new Date(meeting.end_date),'HH:mm')} 
                        </p>
                    </LineMeeting>
                )
            })
        )
    }

    const renderEvent = props.events.map(event => {
        return (
            <div key={event.key}>
                <EventItem>
                    <EventTitle>
                    Id do evento: {event.name}
                    </EventTitle>
                    <EventMeetingsInProgress>
                    {renderMeetings(event.meetings)}
                    </EventMeetingsInProgress>
                </EventItem>
            </div>
        )
    })

    const content = () => {
        if (props.events.length >= 1) {
            return <EventContainer>
                {renderEvent}
            </EventContainer>
        } else {
            return <>nenhum evento gerado.</>
        }
    }
    return (
        <>
        {content()}
        </>
    )
}

export default AvailableEventsGroup;