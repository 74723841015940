import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const RequireAuth = ({ children }: {children: JSX.Element}) => {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to="/" replace/>
    }

    return children
}

export default RequireAuth