import { useEffect, useState } from "react";
import { UseApi } from "../../hooks/UseApi";
import AffiliatesList from "../../components/AffiliatesList";

type Props = {
    productId: string;
}

const AffiliatedList = (props: Props) => {
    const api = UseApi();
    const [ affiliates, setAffiliates ] = useState([]);

    useEffect(() => {
        getAffiliates();
    },[])

    const updateAffiliates = () => {
        getAffiliates();
    }

    const getAffiliates = async () => {
        const data = await api.getAffiliatesByProduct({product_id: props.productId});
        if (data[0]?.id) {
            setAffiliates(data);
        }
    }

    return (
        <>{affiliates ? <AffiliatesList update={updateAffiliates}  affiliates={affiliates}/> : <>Nenhum afiliado conectado.</>}</>
    )
}

export default AffiliatedList;