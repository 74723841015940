import { useEffect, useState } from "react";
import { UseApi } from "../../hooks/UseApi";
import WithdrawalsList from "../../components/WithdrawalsList";
import RowInline, { BoxContainer } from "../../styles/main";

const WithdrawalsPage = () => {
    const api = UseApi();
    const [ ready, setReady ] = useState(false);
    const [withdrawals, setWithdrawals ] = useState([]);

    useEffect(() => {
        getWithdrawals();
    },[])

    const getWithdrawals = async () => {
        const data = await api.getWithdrawalsByAdmin();
        setWithdrawals(data);
    }

    return (
        <RowInline>
            <BoxContainer>
            <WithdrawalsList withdrawals={withdrawals}/>
            </BoxContainer>
        </RowInline>
    )
}

export default WithdrawalsPage;