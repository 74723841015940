import styled from "styled-components";

export const ModalAlertCt = styled.div`
    display: flex;
`

export const ModalLine = styled.div`
    display: flex;
    font-weight: 600;
`

export default ModalAlertCt;