import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Loading, Row, Modal, Button, Text } from "@nextui-org/react";
import WelcomeCt from "../Home/styles";
import RowInline, { BoxContainer, ContentLoading } from "../../styles/main";
import { UseApi } from "../../hooks/UseApi";
import OrdersList from "../../components/OrdersList";
import { DataLoading, WalletCt, WalletIcon, WalletInfo, WalletItem, WalletLine, WithdrawCt } from "./styles";
import { FiClock, FiRepeat, FiShoppingBag, FiShoppingCart } from "react-icons/fi"
import WithdrawalsList from "../../components/WithdrawalsList";
import { useToast } from "@chakra-ui/react";
import Analytics from "../../Analytics";

const SalesPage = () => {
    const api = UseApi();
    const toast = useToast();
    const analytics = Analytics();
    const [ myWallet, setMyWallet ] = useState<any>({});
    const [ orders, setOrders ] = useState<any>([])
    const [ ready, setReady ] = useState(false);
    const [ modalIsvisible, setModalIsVisible] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ withdrawals, setWithdrawals ] = useState([]);
    const [ walletReady, setWalletReady ] = useState(false);
    const [ isUserCheckLoading, setIsUserCheckLoading ] = useState(false);

    useEffect(() => {
        getMyWallet();
        getOrders();
        getWithdrawals();
        return () => {
            setReady(false);
        }
    },[])

    const closeModal = () => setModalIsVisible(false);

    const getOrders = async () => {
        const data = await api.getSales();
        // console.log(typeof data);
        setOrders(data);
        setReady(true);
    }

    const getMyWallet = async () => {
        const data = await api.getMyWallet();
        if (data.withdraw.pending === null) {
            data.withdraw.pending = "0.00"  
        }
        setMyWallet(data);
        setTimeout(() => {
            setWalletReady(true);
        }, 600)
    }

    const requestWithDraw = async () => {
        setIsLoading(true);
        const data = await api.createRequestWithdraw({wallet_id: myWallet.wallet.id, amount: myWallet.wallet.amount})
        
        // console.log(data.withdraw);
        if (data?.withdraw?.id) {
            setTimeout(() => {
                setModalIsVisible(false);
                setIsLoading(false);
            },600)
            getMyWallet();
            getWithdrawals();
        } else {
            setModalIsVisible(false);
            setIsLoading(false);
            if (!toast.isActive('withdrawError')) {
                toast({
                    id: 'withdrawError',
                    description: "Entre em contato com o suporte",
                    status: 'error',
                    duration: 2000,
                    isClosable: false,
                    position: 'top',
                })
            }
        }

    }

    const getWithdrawals = async () => {
        const data = await api.getMyWithdraws();
        setWithdrawals(data);
        // if (data.length >= 1) {
            
        // }
    }

    const renderContent = () => {
        return (
            <OrdersList orders={orders}/>
        )
    }

    const renderLoading = () => {
        return (
            <ContentLoading>
                <Loading size="md" color="secondary">Atualizando dados...</Loading>
            </ContentLoading>
        )
    }

    const renderWithdrawals = () => {
        return (
            <WithdrawalsList withdrawals={withdrawals}/>
        )
    }

    const renderLoadingData = () => {
        return (
            <DataLoading>
                <Loading type="points" size="sm" color="default"/>
            </DataLoading>
        )
    }

    const checkUserInfoToWithDraw = async () => {
        setIsUserCheckLoading(true);
        let error = false;

        const dataBank = await api.getUserDetails();

        if (!dataBank?.id) {
            if (!toast.isActive('bankAccount')) {
                toast({
                    id: 'bankAccount',
                    description: "Sem dados bancários cadastrados",
                    status: 'error',
                    duration: 2000,
                    isClosable: false,
                    position: 'top',
                })
            }
            analytics.addEvent("WithdrawBankInfoError");
            error = true;
            return;
        }

        const dataIdentity = await api.getIdentityVerify();
        if (dataIdentity?.identityStatus !== "APPROVED") {
            if (!toast.isActive('identityAccount')) {
                toast({
                    id: 'identityAccount',
                    description: "Sua conta ainda não foi verificada",
                    status: 'error',
                    duration: 2000,
                    isClosable: false,
                    position: 'top',
                })
            }
            analytics.addEvent("WithdrawIdentityError");
            error = true;
        }

        setIsUserCheckLoading(false);
        if (!error) {
            setModalIsVisible(true);
        }

    }

    const renderWallet = () => {
        return (
            <WalletCt>
                    <WalletItem>
                        <WalletLine>
                            <WalletIcon style={{backgroundColor:"#e3f4f6"}}>
                                <FiShoppingCart color='#17a2b8' size={20}/>
                            </WalletIcon>
                            <h2>Valor em vendas:</h2>
                        </WalletLine>
                        <WalletLine>
                            <WalletInfo>
                                {walletReady ? <h1>R${myWallet.wallet.amountSale}</h1> : renderLoadingData() }
                            </WalletInfo>
                        </WalletLine>
                        <p>* Valor total gerado em vendas.</p>
                    </WalletItem>
                    <WalletItem>
                        <WalletLine>
                            <WalletIcon style={{backgroundColor:"#53c28134"}}>
                                <FiShoppingBag color='#3cd278' size={20}/>
                            </WalletIcon>
                            <h2>Saldo disponível:</h2>
                        </WalletLine>
                        <WalletLine>
                            <WalletInfo>
                                {walletReady ? <h1>R${myWallet.wallet.amount}</h1> : renderLoadingData()}
                            </WalletInfo>
                        </WalletLine>
                        <p>* Valor disponível para saque</p>
                    </WalletItem>
                    <WalletItem>
                        <WalletLine>
                            <WalletIcon style={{backgroundColor:"#dea82b33"}}>
                                <FiClock color='#dea82b' size={20}/>
                            </WalletIcon>
                            <h2>Saldo aguardando liberação:</h2>
                        </WalletLine>
                        <WalletLine>
                            <WalletInfo>
                                {walletReady ? <h1>R${myWallet.wallet.amountPending}</h1> : renderLoadingData()}
                            </WalletInfo>
                        </WalletLine>
                        <p>* Pagamento confirmado.</p>
                    </WalletItem>
                    <WalletItem>
                        <WalletLine>
                            <WalletIcon style={{backgroundColor:"#e3f4f6"}}>
                                <FiRepeat color='#17a2b8' size={20}/>
                            </WalletIcon>
                            <h2>Saque em andamento:</h2>
                        </WalletLine>
                        <WalletLine>
                            <WalletInfo>
                                
                                {walletReady ? <h1>R${myWallet.withdraw.pending}</h1> : renderLoadingData()}
                                
                            </WalletInfo>
                        </WalletLine>
                        <p>* Saldo sendo transferido.</p>
                    </WalletItem>
                </WalletCt>
        )
    }
    
    const renderWithdraw = () => {
        if (myWallet?.wallet?.amount > 0) {
            return (
                <WithdrawCt>
                    <Button onPress={() => checkUserInfoToWithDraw()} shadow color="secondary">
                        {isUserCheckLoading ? <Loading color="currentColor" size="sm"/> : <>Solicitar Saque</>}
                    </Button>
                </WithdrawCt>
            )
        }
    }

    return (
        <>
        <Modal
        closeButton
        aria-labelledby="modal-title"
        open={modalIsvisible}
        onClose={() => setModalIsVisible(false)}
        blur
        >
            <Modal.Header>
            <Text b size={18}>
                Realização de saque
            </Text>
            </Modal.Header>
            <Modal.Body>
            <Row justify="space-between">
                <Text size={14}>Confirmar saque?</Text>
            </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button auto ghost disabled={isLoading} color="error" onPress={closeModal}>
                Cancelar
            </Button>
            <Button auto disabled={isLoading} onPress={() => requestWithDraw()}>
                {isLoading ?  <Loading color="currentColor" size="sm" /> : <>Confirmar</>}
            </Button>
            </Modal.Footer>
        </Modal>
        <motion.div 
        initial={{opacity:0, y:"50"}} 
        animate={{opacity:1, y:0}} 
        exit={{opacity:0}}
        >
            <Row>
                <WelcomeCt>
                    <h1>
                        Relatório de vendas
                    </h1>
                    <p>
                        O saldo da venda só é adicionado a sua carteira após o pagamento ser confirmado.
                    </p>
                </WelcomeCt>
                {renderWithdraw()}
            </Row>
            <RowInline>
                <BoxContainer>
                    <RowInline>
                        {renderWallet()}
                    </RowInline>
                    <RowInline>
                    {ready ? renderWithdrawals() : renderLoading()}
                    </RowInline>
                    {ready ? renderContent() : renderLoading()}
                </BoxContainer>
            </RowInline>
        </motion.div>
        </>
    )
}

export default SalesPage;