import styled from "styled-components";

export const MenuContainer = styled.div`
    display:flex;
    flex-direction: column;
    /* background-color: #444; */
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    a{
        width: 100%;
    }
`

export const MenuItem = styled.div`
    display: flex;
    cursor: pointer;
    font-size:18px;
    /* margin-bottom:18px; */
    border-bottom: 1px solid #f3f3f3;
    width: 100%;
    padding:10px;
    &:hover {
        background-color: #e1e1e1;
    }
`

export const MenuItemInvalid = styled.div`
    display: flex;
    font-size:18px;
    border-bottom: 1px solid #f3f3f3;
    width: 100%;
    padding:10px;
    color:#c8c8c8;
`

export const MenuIcon = styled.div`
    display: flex;
    margin-right: 5px;
    img{
        width: 18px;
    }
`


export default MenuContainer;