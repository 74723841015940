import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    padding:10px 20px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export const Links = styled.div`
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: flex-end;
    height: 50px;
`

export const Item = styled.div`
    display: flex;
    font-size: 14px;
    color:#888;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    &:hover{
        color:#000;
        border-color: #000;
    }
    padding:10px;
    /* border:1px solid #dadada; */
    border-radius: 8px;
`

export default Container;