import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { UseApi } from "../../hooks/UseApi";
// import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import RowInline, { BoxContainer, FormLine, FormLineColumnDirection, RadioCt } from "../../styles/main";
import AlertFilledIcon from "../../Assets/icons/alertFilledIcon.svg";
import 'primeicons/primeicons.css';
import ActionCt, { ActionButtons, BoxHoursAlert, BoxHoursExemple, BoxSessions, BoxText, ContainerCalendar, InputDaysWeekCt, InputHoursCt, LineMeetingGroupAction, ModalContent, Title } from "./styles";
import AlertInfo from "../../components/AlertInfo";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { Spacer, Modal, Loading, Button } from "@nextui-org/react";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from 'primereact/dropdown';
import hoursToWork from "./hours";
import AvailableHours from "./AvailableHours";
import CheckIcon from "../../Assets/icons/checkIcon.svg";
import { useAuth } from "../../Auth/AuthProvider";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Divider } from 'primereact/divider';
import AvailableMeetings from "./AvailableMeetings";
import add from 'date-fns/add';
import { FiTrash2, FiCheck } from "react-icons/fi";
import AvailableEvents from "./AvailableEvent";
import Uploader from "../../components/Uploader";
import { format } from "date-fns";
import { Editor } from "primereact/editor";
import Analytics from "../../Analytics";


export type DayType = {
    name: string;
    code: string;
}

export type HourType = {
    name: string;
    code: any;
}

const ProductCreatePage = () => {
    const api = UseApi();
    const { user } = useAuth();
    const [ activeIndex, setActiveIndex ] = useState(0);
    const toast = useToast();
    const navigate = useNavigate();
    const analytics = Analytics();

    const [ productName, setProductName ] = useState<string>("");
    const [ productType, setProductType ] = useState("SOLO");
    const [ productPrice, setProductPrice ] = useState(10);
    const [ productDuration, setProductDuration ] = useState("30");
    const [ productSlot, setProductSlot ] = useState<number>(1);
    const [ productDescription, setProductDescription ] = useState("");
    const [ productImage, setProductImage ] = useState("");
    const [ allowAffiliated, setAllowAfiliated ] = useState("nao");
    const [ affiliatedFee, setAffiliatedFee ] = useState<number>(0);
    const [ affiliatedDescription, setAffiliatedDescription ] = useState<string>("");
    const [ isSaving, setIsSaving ] = useState<boolean>(false);
    const [ date, setDate] = useState(null);
    const [ time, setTime ] = useState(null);
    const [ productGroupReady, setProductGroupReady ] = useState(false);
    const [ eventLimit, setEventLimit ] = useState(false);
    const [ inviteGroup, setInviteGroup ] = useState(false);
    const [ linkGroup, setLinkGroup ] = useState("");
    const [ salesType, setSalesType ] = useState("DIRECT");
    // const [ proudctReady, setProductReady ] = useState<boolean>(false);

    const [productNameInvalid, setProductNameInvalid ] = useState(false);

    
    const [day, setDay] = useState<DayType>({name: "", code: ""});
    const week = [
        { name: 'Segunda', code: 'monday' },
        { name: 'Terça', code: 'tuesday' },
        { name: 'Quarta', code: 'wednesday' },
        { name: 'Quinta', code: 'thursday' },
        { name: 'Sexta', code: 'friday' },
        { name: 'Sábado', code: 'saturday' },
        { name: 'Domingo', code: 'sunday' }
    ];

    addLocale('br', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'feb', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar',
    });
    
    const [ startHour, setStartHour ] = useState<HourType>({name: "", code: ""});
    const [ endHour, setEndHour ] = useState<HourType>({name: "", code: ""});
    const [ availableHours, setAvailableHours ] = useState([])
    const [ availableMeetings, setAvailableMeetings ] = useState([])
    const [ availableEvents, setAvailableEvents ] = useState([])

    useEffect(() => {
        if (day.name.length > 1) {
            setStartHour({name: '09:00', code: 9})
            setEndHour({name: "18:00", code: 18})
        }
    },[day])


    useEffect(() => {
        if (productPrice < 10) setProductPrice(10);
    }, [productPrice])

    useEffect(() => {
        let currentDate = new Date();
        currentDate.setHours(8);
        currentDate.setMinutes(0); 
        setDate(currentDate);
        analytics.addPage({page: "/product/create", title:"Criação de mentoria"})
        analytics.addEvent("ProductCreateInit"); 
    },[])

    useEffect(() => {
        if (productName.length > 1) {
            setProductNameInvalid(false);
        }
    },[productNameInvalid])

    const cleanInputsHour = () => {
        const empty = {name: "", code: ""}
        setDay(empty)
        setStartHour(empty)
        setEndHour(empty);
    }

    const removeAvailbleHours = key => {
        setAvailableHours((current) => current.filter((hour) => hour.key !== key))
    }

    const checkProductReady = () => {
        let ready = false;
        let details = false;

        if (
            productName.length > 1 &&
            productDescription.length > 1 && 
            productDuration &&
            productPrice >= 10 &&
            productSlot &&
            productType
        ) {
            ready = true;
            details = true;
        } else {
            details = false;
        }

        if (availableHours.length >= 1 || (productType === "GROUP" && productGroupReady)) {
            ready = true;
        } else {
            ready = false;
            if (details) setActiveIndex(1);
        }

        if (allowAffiliated === "sim" && affiliatedFee > 0 ) {
            ready = true;
        }

        // if (ready) setProductReady(true);
        return ready && details;
    }

    const renderHeaderEditor = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        );
    };

    const prepareSchedule = async () => {
        const schedule = {
            "monday": [],
            "tuesday": [],
            "wednesday": [],
            "thursday": [],
            "friday": [],
            "saturday": [],
            "sunday": []
        }
        for (let i = 0; availableHours.length > i; i++) {
            // console.log(availableHours[i].weekday.code);
            schedule[availableHours[i].weekday.code].push(
                availableHours[i].start.name + ' - ' + availableHours[i].end.name
            );
        }
        return schedule;
    }

    const saveProduct = async () => {
        if (checkProductReady()) {
            if (productType === "SOLO") {
                setIsSaving(true);
                const calendar = await prepareSchedule();
                try {
                    const product = await api.createProduct({
                        product_type: productType,
                        product_name: productName,
                        product_description: productDescription,
                        product_price: productPrice,
                        product_image: productImage,
                        product_slots: productSlot,
                        product_duration: parseInt(productDuration),
                        calendar,
                        affiliated: (allowAffiliated === "sim") ? true : false,
                        affiliated_fee: affiliatedFee,
                        affiliated_description: affiliatedDescription,
                        link_group: linkGroup,
                        sales_type: salesType,
                    });

                    if (!product.error) {
                        navigate("/dashboard/products")
                        toast({
                            id: "productDone",
                            description: "Parabéns! Mentoria criada com sucesso",
                            status: 'success',
                            duration: 4000,
                            isClosable: false,
                            position: 'top',
                        })
                        analytics.addEvent("ProductCreated", { type: "solo", name: productName} ) 
                    }
                } catch(e) {
                    analytics.addEvent("ProductCreateError") 
                    toast({
                        id: "productError",
                        description: "Erro #PC302 Por favor, entre em contato com o suporte!",
                        status: 'error',
                        duration: 5000,
                        isClosable: false,
                        position: 'top',
                    })
                    setIsSaving(false);
                }
            } else if (productType === "GROUP") {
                setIsSaving(true);
                try {
                    const product = await api.createProductGroup({
                        product_type: productType,
                        product_name: productName,
                        product_description: productDescription,
                        product_price: productPrice,
                        product_image: productImage || "https://portalpopline.com.br/wp-content/uploads/2022/12/avatar-capa-3.jpg",
                        product_slots: productSlot,
                        product_duration: parseInt(productDuration),
                        events: availableEvents,
                        affiliated: (allowAffiliated === "sim") ? true : false,
                        affiliated_fee: affiliatedFee,
                        affiliated_description: affiliatedDescription,
                        link_group: linkGroup,
                        sales_type: salesType,
                    });
        
                    if (!product.error) {
                        analytics.addEvent("ProductCreated", { type: "group", name: productName} ) 
                        navigate("/dashboard/products")
                        toast({
                            id: "productDone",
                            description: "Parabéns! Mentoria criada com sucesso",
                            status: 'success',
                            duration: 4000,
                            isClosable: false,
                            position: 'top',
                        })
                    }
                } catch(e) {
                    toast({
                        id: "productError",
                        description: "Erro #PC302 Por favor, entre em contato com o suporte!",
                        status: 'error',
                        duration: 5000,
                        isClosable: false,
                        position: 'top',
                    })
                    setIsSaving(false);
                }
            }
        } else {
            if (!toast.isActive('productReady')) {
                toast({
                    id: "productReady",
                    description: "Por favor, preencha todos os campos disponíveis.",
                    status: 'warning',
                    duration: 4000,
                    isClosable: false,
                    position: 'top',
                })
            }
        }
    }

    const addAvailableHours = () => {
        if (startHour.name 
            && endHour.name 
            && (startHour.name !== endHour.name)
            && startHour.code < endHour.code
            ) {
            const generateKey = day.name + startHour.name + endHour.name  
            const hour = {
                key: generateKey,
                weekday: day,
                start: startHour,
                end: endHour
            }

            if (availableHours.find(hour => hour.key === generateKey)) return;
            setAvailableHours(current => [...current, hour]);
            cleanInputsHour();
            analytics.addEvent("CalendarAddHours");
        }
    }

    const renderAffiliatedContent = () => {
        return (
            <>
            <BoxText>
                <h2>
                    Configurações para afiliação.
                </h2>
                <p>
                    Uma vez que você disponibilizar sua mentoria para afiliação, ela ficará disponível para todos usuários solicitarem autorização.
                </p>
                <h3>Taxa e comissão:</h3>
                <p>Primeiro sera descontado a sua taxa de operação que hoje é de <strong>{user.fee}%</strong>. Só então será aplicado a comissão do afiliado.</p>
            </BoxText>
            <BoxHoursAlert>
                <img src={AlertFilledIcon} alt="Aviso importante"/>
                <p>Modelo de atribuição é por último click. Isso significa que se mais de um afiliado disputarem seu link a comissão vai para o último que trouxer novamente o cliente.</p>
            </BoxHoursAlert>
            <Spacer y={1.2}/>
            Disponibilizar para afiliação:
            <FormLine>
            <RadioCt>
                <RadioButton inputId="nao" name="product-type" value="nao" onChange={(e) => setAllowAfiliated(e.value)} checked={allowAffiliated === 'nao'} />
                <label htmlFor="nao">Não</label>
            </RadioCt>
            <RadioCt>
                <RadioButton inputId="sim" name="product-type" value="sim" onChange={(e) => setAllowAfiliated(e.value)} checked={allowAffiliated === 'sim'} />
                <label htmlFor="sim">Sim</label>
            </RadioCt>
            </FormLine>
            <FormLineColumnDirection>
            <Spacer y={1}/>
            <label htmlFor="grupo">Valor da comissão em porcentagem.</label>
            <InputNumber inputId="minmax" value={affiliatedFee} onValueChange={(e) => setAffiliatedFee(e.value)} min={0} max={90} />
            <small id="username-help">
                    * O limite permitido é 90%.
                </small>
            </FormLineColumnDirection>
            <FormLineColumnDirection>
                <label htmlFor="username">Descrição para os afiliados:</label>
                {/* <InputTextarea autoResize value={affiliatedDescription} onChange={(e) => setAffiliatedDescription(e.target.value)} rows={5} cols={30} /> */}
                <div className="card">
                <Editor maxLength={260} value={affiliatedDescription} onTextChange={(e) => setAffiliatedDescription(e.htmlValue)} headerTemplate={renderHeaderEditor()} style={{ height: '260px' }} />
                </div>
            </FormLineColumnDirection>
            </>
        )
    }

    const calendarSolo = () => {
        return (
            <>
            <BoxText>
                <h2>
                    Defina seus horários disponíveis para mentorias.
                </h2>
                <p>
                    Você precisa criar uma faixa de horário para cada dia da semana em que deseja fornecer suas mentorias, o mesmo dia pode ter mais de uma faixa de horário. Use diferentes faixas de horário para separar um horário de almoço por exemplo.
                </p>
                <h3>Um exemplo de como podem ser seus horários:</h3>
                <BoxHoursExemple>
                    <ul>
                        <li>Sexta das 07:00 às 11:00</li>
                        <li>Sexta das 14:00 às 16:00</li>
                        <li>Sábado das 09:00 às 11:00</li>
                    </ul>
                </BoxHoursExemple>
            </BoxText>
            <BoxHoursAlert>
                <img src={AlertFilledIcon} alt="Aviso importante"/>
                <p>A disponibilidade das reuniões será calculada automáticamente baseado na sua agenda de horários e o tempo de duração definido anteriormente.</p>
            </BoxHoursAlert>
            <FormLine>
                <InputDaysWeekCt>
                    <Dropdown value={day} onChange={(e) => setDay(e.value)} options={week} optionLabel="name" 
                    placeholder="Selecione um dia" className="w-full md:w-14rem" />
                </InputDaysWeekCt>
                <InputHoursCt>
                    <Dropdown value={startHour} onChange={(e) => setStartHour(e.value)} options={hoursToWork} optionLabel="name" 
                    placeholder="Início" className="w-full md:w-14rem" />
                </InputHoursCt>
                <InputHoursCt>
                    <Dropdown value={endHour} onChange={(e) => setEndHour(e.value)} options={hoursToWork} optionLabel="name" 
                    placeholder="Fim" className="w-full md:w-14rem" />
                </InputHoursCt>
                <InputHoursCt>
                <Button 
                size="lg" 
                shadow iconRight={<img src={CheckIcon} alt="Adicionar horário"/>}
                onPress={() => addAvailableHours()}
                >
                    Adicionar horário
                </Button>
                </InputHoursCt>
            </FormLine>
            <AvailableHours removeAvailbleHours={removeAvailbleHours} hours={availableHours}/>
            </>
        )
    }

    const addGroupHour = () => {
        let ready = true;
        // console.log(date.getTime());
        const currentDate = new Date();

        var diffMs = (date - currentDate.getTime()); // milliseconds between now & Christmas
        // var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor(date.getTime() - currentDate.getTime()) / 3600000; // hours
        // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        if (date.getTime() < currentDate.getTime()) {
            // console.log("horário menor! inválido");
            toast({
                description: "Horário inválido, Não é possível agendar em um horário que já passou!",
                status: 'error',
                duration: 3000,
                isClosable: false,
                position: 'top',
            })

            ready = false;
            // return null;
        }

        if ((diffHrs < 5 && ready)) {
            toast({
                description: "Horário inválido! Agende com pelo menos 6 horas de antecedência.",
                status: 'warning',
                duration: 3000,
                isClosable: false,
                position: 'top',
            })
            ready = false;
        }

        if (date !== null && ready) {

            // console.log(date);
            // fix hours 
            // console.log("time ->", format(new Date(time), 'HH:mm'));


            let key = date.getTime();

            const end = add(date, { minutes: parseInt(productDuration)})
            // console.log(key);

            const meeting = {
                key: key,
                start_date: date,
                end_date: end
            }
            // console.log("meeting in create ->", meeting);
            if (availableMeetings.find(meeting => meeting.key === key)) return;
            setAvailableMeetings(current => [...current, meeting]);
        }
    }

    const addMeetingEvent = () => {
        const keyEvent = new Date().getTime();
        let event = {
            key: keyEvent,
            name: keyEvent,
            meetings: availableMeetings,
        }
        if (availableEvents.find(event => event.key === keyEvent)) return;
        setAvailableEvents(current => [...current, event]);
        setAvailableMeetings([]);
        setEventLimit(true);
        setProductGroupReady(true);
    }

    const calendarGroupAction = () => {
        if(availableMeetings.length >= 1) {
            return (
                <LineMeetingGroupAction>
                    <Button 
                    size="sm"
                    ghost
                    auto
                    iconRight={<FiTrash2 />}
                    onPress={() => setAvailableMeetings([])}
                    >
                        Limpar
                    </Button>
                    <Button 
                    size="sm"
                    auto
                    color="secondary"
                    iconRight={<FiCheck />}
                    onPress={() => addMeetingEvent()}
                    >
                        Finalizar Evento
                    </Button>
                </LineMeetingGroupAction>
            )
        }
    }

    const calendarGroup = () => {
        return (
            <>
                <BoxText>
                    <h2>
                        Defina as sessões da sua mentoria em grupo.
                    </h2>
                    <p>
                        Você precisa criar um evento com todas as sessões pré definidas para uma mentoria em grupo.
                    </p>
                    <h3>Um exemplo de como podem ser seu evento:</h3>
                    <BoxHoursExemple>
                        <ul>
                            <li>Sexta 16/06/2023 das 07:00 às 11:00</li>
                            <li>Sábado  17/06/2023 das 14:00 às 16:00</li>
                        </ul>
                    </BoxHoursExemple>
                </BoxText>
                <BoxHoursAlert>
                    <img src={AlertFilledIcon} alt="Aviso importante"/>
                    <p>Após concluir esses eventos, você pode voltar e adicinar novamente para recomeçar essa mentoria em grupo.</p>
                </BoxHoursAlert>
                <ContainerCalendar>
                    <Calendar 
                    value={date} 
                    locale="br"
                    showTime hourFormat="24"
                    stepMinute={15}
                    onChange={(e) => setDate(e.value)} 
                    inline
                    className="p-invalid" 
                    /> 
                    <BoxSessions>
                    {!eventLimit && <BoxText>
                    <h2>
                        Horários:
                    </h2>
                    </BoxText>}
                    {/* <Calendar value={time} onChange={(e) => setTime(e.value)} timeOnly /> */}
                    <Spacer y={0.4}/>
                    {!eventLimit && <>
                        <Button 
                        size="sm"
                        // ghost 
                        color="primary"
                        shadow iconRight={<img src={CheckIcon} alt="Adicionar horário"/>}
                        onPress={() => addGroupHour()}
                        >
                            Adicionar horário
                        </Button>
                        <AvailableMeetings meetings={availableMeetings}/>
                        {calendarGroupAction()}
                    </>}
                    {renderEvents()}
                    </BoxSessions>
                </ContainerCalendar>
                {/* <Divider/> */}
                {/* {renderEvents()} */}
            </>
        )
    }

    const renderEvents = () => {
        return <>
            {eventLimit && 
                <>
                    <BoxText>
                        <h2>
                            Evento gerado:
                        </h2>
                    </BoxText>
                    <AvailableEvents events={availableEvents}/>
                </>} 
        </>
    }

    const renderCalendarContent = () => {
        if (productType === "SOLO") {
            return <>{calendarSolo()}</>
        } else {
            return <>{calendarGroup()}</>
        }
    }

    const renderSlotType = () => {
        if (productType === "SOLO") {
            return <>
                <AlertInfo type="alert" content="O número de sessões é a quantidade de sessões inclusas nessa mentoria pelo preço total determinado abaixo. Por exemplo, se essa mentoria tiver 4 sessões e o preço da mentoria for de R$1.000, isso significa que cada sessão está custando R$250, mas o cliente precisa agendar e pagar as 4 sessões de uma vez."/>
                <Spacer y={1}/>
                <label htmlFor="grupo">Número de sessões:</label>
                <InputNumber inputId="minmax" value={productSlot} onValueChange={(e) => setProductSlot(e.value)} min={0} max={100} />
                <small id="username-help">
                    * O limite permitido é 100.
                </small>
            </>
        } else {
            return <>
                <Spacer y={1}/>
                <label htmlFor="grupo">Vagas disponíveis:</label>
                <InputNumber inputId="minmax" value={productSlot} onValueChange={(e) => setProductSlot(e.value)} min={0} max={100} />
                <small id="username-help">
                    * O limite permitido é de 100 vagas.
                </small>
            </>
        }
    }

    const uploadHandl = (filePath) => {
        setProductImage(filePath);
    }

    const handleChangeProductDuration = (e) => {
        setProductDuration(e.value)
        setAvailableHours([]);
    }

    return (
        <motion.div 
        initial={{opacity:0, y:"50"}} 
        animate={{opacity:1, y:0}} 
        exit={{opacity:0}}
        >
            <Modal
                blur
                aria-labelledby="modal-title"
                open={isSaving}
                preventClose
            >
                <ModalContent>
                <Loading color="secondary" size="xl" type="default" />
                <p>Criando sua mentoria...<br></br>É rapidinho! </p>
                </ModalContent>
            </Modal>
            <ActionCt>
                <Title>
                    <h1>Criação de mentoria</h1>
                    <p>
                        Crie aqui todas as regras da sua mentoria.
                    </p>
                </Title>
                <ActionButtons>
                <Button 
                size="lg" 
                color="secondary"
                shadow iconRight={<img src={CheckIcon} alt="Adicionar horário"/>}
                onPress={() => saveProduct()}
                >
                    Salvar Mentoria
                </Button>
                </ActionButtons>
            </ActionCt>
            <AlertInfo type="info" content="Você pode criar dois tipos de mentoria, em grupo e sozinho. Dependo da sua escolha as regras abaixo serão alteradas."/>
            <RowInline>
            <BoxContainer>
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Geral" rightIcon="pi pi-pencil">
                        <FormLineColumnDirection>
                            <label htmlFor="username">Nome da mentoria:</label>
                            <InputText 
                            className={productNameInvalid ? 'p-invalid' : ''}
                            id="username" 
                            aria-describedby="username-help"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            />
                        </FormLineColumnDirection>
                        {/* <FormLine> */}
                        <Uploader
                            disabled={!!productImage}
                            uploadHandler={uploadHandl}
                            bucket={"mentoria"}
                        />
                        <Spacer y={0}/>
                        <FormLine>
                        Tipo de venda:
                        <RadioCt>
                            <RadioButton inputId="direct" name="product-type" value="DIRECT" onChange={(e) => setSalesType(e.value)} checked={salesType === 'DIRECT'} />
                            <label htmlFor="direct">Compra direta</label>
                        </RadioCt>

                        <RadioCt>
                            <RadioButton inputId="voucher" name="product-type" value="VOUCHER" onChange={(e) => setSalesType(e.value)} checked={salesType === 'VOUCHER'} />
                            <label htmlFor="voucher">Compra de crédito</label>
                        </RadioCt>
                        </FormLine>
                        <Spacer y={0}/>
                        <FormLine>
                        Tipo de mentoria:
                        <RadioCt>
                            <RadioButton inputId="solo" name="product-type" value="SOLO" onChange={(e) => setProductType(e.value)} checked={productType === 'SOLO'} />
                            <label htmlFor="solo">Solo (1:1)</label>
                        </RadioCt>

                        <RadioCt>
                            <RadioButton inputId="grupo" name="product-type" value="GROUP" onChange={(e) => setProductType(e.value)} checked={productType === 'GROUP'} />
                            <label htmlFor="grupo">Grupo</label>
                        </RadioCt>
                        </FormLine>
                        <Spacer y={0}/>
                        <FormLine>
                        Duração da mentoria:
                            <RadioCt>
                                <RadioButton inputId="30" name="duration" value="30" onChange={handleChangeProductDuration} checked={productDuration === '30'} />
                                <label htmlFor="30">30m</label>
                            </RadioCt>

                            <RadioCt>
                                <RadioButton inputId="60" name="duration" value="60" onChange={handleChangeProductDuration} checked={productDuration === '60'} />
                                <label htmlFor="60">1h</label>
                            </RadioCt>

                            <RadioCt>
                                <RadioButton inputId="90" name="duration" value="90" onChange={handleChangeProductDuration} checked={productDuration === '90'} />
                                <label htmlFor="90">1:30h</label>
                            </RadioCt>

                            <RadioCt>
                                <RadioButton inputId="120" name="duration" value="120" onChange={handleChangeProductDuration} checked={productDuration === '120'} />
                                <label htmlFor="120">2h</label>
                            </RadioCt>

                            <RadioCt>
                                <RadioButton inputId="150" name="duration" value="150" onChange={handleChangeProductDuration} checked={productDuration === '150'} />
                                <label htmlFor="150">2:30h</label>
                            </RadioCt>

                            <RadioCt>
                                <RadioButton inputId="180" name="duration" value="180" onChange={handleChangeProductDuration} checked={productDuration === '180'} />
                                <label htmlFor="180">3h</label>
                            </RadioCt>
                        </FormLine>
                        <Spacer y={0}/>
                        <FormLine>
                        Convidar para grupo após confirmação de compra:
                        <RadioCt>
                            <RadioButton inputId="invite-group-false" name="invite-group" value={false} onChange={(e) => setInviteGroup(e.value)} checked={!inviteGroup} />
                            <label htmlFor="invite-group-false">Não</label>
                        </RadioCt>

                        <RadioCt>
                            <RadioButton inputId="invite-group-true" name="invite-group" value={true} onChange={(e) => setInviteGroup(e.value)} checked={inviteGroup} />
                            <label htmlFor="invite-group-true">Sim</label>
                        </RadioCt>
                        </FormLine>
                        {inviteGroup && <> 
                            <FormLineColumnDirection>
                                <label htmlFor="link-group">Link do grupo:</label>
                                <InputText 
                                id="link-group" 
                                aria-describedby="link-group"
                                value={linkGroup}
                                onChange={(e) => setLinkGroup(e.target.value)}
                                />
                            </FormLineColumnDirection>
                        </>}
                        <FormLineColumnDirection>
                            {renderSlotType()}
                        </FormLineColumnDirection>
                        <Spacer y={1}/>
                        <FormLineColumnDirection>
                            <label htmlFor="username">Descrição da mentoria:</label>
                            {/* <InputTextarea autoResize value={productDescription} onChange={(e) => setProductDescription(e.target.value)} rows={5} cols={30} /> */}
                            <div className="card">
                            <Editor maxLength={440} value={productDescription} onTextChange={(e) => setProductDescription(e.htmlValue)} headerTemplate={renderHeaderEditor()} style={{ height: '260px' }} />
                            </div>
                        </FormLineColumnDirection>
                        <FormLineColumnDirection>
                             <label htmlFor="currency-us" className="font-bold block mb-2">Valor total da mentoria:</label>
                            <InputNumber inputId="currency-us" value={productPrice} onValueChange={(e) => setProductPrice(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
                            <small id="username-help">
                                * valor mínimo permitido é R$10,00
                            </small>
                        </FormLineColumnDirection>
                    </TabPanel>
                    <TabPanel header="Agenda" rightIcon="pi pi-fw pi-calendar">
                        {renderCalendarContent()}
                    </TabPanel>
                    <TabPanel header="Afiliado" rightIcon="pi pi-fw pi-share-alt">
                        {renderAffiliatedContent()}
                    </TabPanel>
                </TabView>
            </BoxContainer>
            </RowInline>
        </motion.div>
    )
}

export default ProductCreatePage;