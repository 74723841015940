import Analytics from "../../Analytics";
import Container from "./styles";
import {BsWhatsapp } from "react-icons/bs";

export default function Support(){
    const analytics = Analytics();

    const needSupport = () => {
        analytics.addEvent("DashboardClickSupport");
        window.location.replace('https://api.whatsapp.com/send?phone=5511914767900&text=Sou%20um%20mentor%20e%20preciso%20de%20ajuda%20com%20a%20plataforma.');
    }

    return (
        <Container onClick={() => needSupport()}>
            <BsWhatsapp size={28}/>
        </Container>
    )
}