import { createContext, useContext, useEffect, useState } from "react";
import { UseApi } from "../hooks/UseApi";
import { User } from "../types/User";
// import { AuthContext } from "../Context/AuthContext";
// import { Progress } from "@chakra-ui/react";
import { Progress } from "@nextui-org/react"

export type IAuthContext = {
    user: User | null;
    signIn: (email: string, password: string) => Promise<boolean> | any;
    signOut: () => void; 
    mainLoader: boolean;
    toggleMainLoader: (value: boolean) => void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext)

const AuthProvider = ({ children }: { children: JSX.Element}) => {
    const [user, setUser] = useState<User | null>(null);

    // mainLoader para controlar o loading principal do dashboard
    const [ mainLoader, setMainLoader ] = useState<boolean>(false);

    const api = UseApi();

    // Ready é usado para printa ou não o componente na tela, antes de verificar sua autenticação
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const validateToken = async () => {
            const storageData = localStorage.getItem('mentorados');
            if (storageData) {
                const data = await api.validateToken(storageData);
                if (data !== null && data.user) {
                    setUser(data.user);
                    setReady(true);
                } else {
                    // Alterar esse Ready 
                    setReady(true);
                }
            } else {
                setReady(true);
            }
        }
        setTimeout(() => {
            validateToken();
        }, 900)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toggleMainLoader = (value:boolean) => {
        setMainLoader(value)
    }
    
    
    const signIn = async (email: string, password: string) => {
        try {
            const data = await api.signIn(email, password);
            if (data?.error && data.msg === "suspended account") {
                return {error: true, message: "Conta suspensa, entre em contato com o suporte!"}
            }
            if (data?.user && data?.token) {
                setUser(data.user);
                setToken(data.token);
                return true
            } 
        } catch {
            return false
        }

    }

    const signOut = async () => {
        // await api.signOut();
        localStorage.removeItem('mentorados');
        setUser(null);
    }

    const setToken = (token: string) => {
        localStorage.setItem('mentorados', token);
    }
    

    return (
        <AuthContext.Provider value={{ 
            user, 
            signIn,
            signOut, 
            mainLoader, 
            toggleMainLoader
            }}>
            {ready && children ? children :
                <>
                    {/* <Spinner color='red.500' /> */}
                    {/* <Progress colorScheme="#red" size='xs' isIndeterminate /> */}
                    <Progress
                    size="xs"
                    indeterminated
                    value={10}
                    color="secondary"
                    status="primary"
                    />
                </>
            }
            
        </AuthContext.Provider>
    );
}

function useAuth(): IAuthContext {
    const context = useContext(AuthContext);

    return context;
}

export { AuthProvider, useAuth};