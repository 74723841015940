import { Navbar } from '@nextui-org/react';
import { ContentTopBar, CustomerContainer, IdentityVerifyAlert, MenuContainer, MenuIcon, MenuItem, MobileBtn, SideBarContent } from './style';
import LogoImg from '../../Assets/Images/mentorados-icon.png';
import HomeIcon from '../../Assets/icons/homeIcon.svg';
import UserMeetIcon from '../../Assets/icons/userMeetIcon.svg';
import BagIcon from '../../Assets/icons/bagIcon.svg';
import NetworkIcon from '../../Assets/icons/networkIcon.svg';
import ChartIcon from '../../Assets/icons/chartIcon.svg';
import AvatarMenu from '../AvatarMenu';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthProvider';
import { useEffect, useState } from 'react';
import { UseApi } from '../../hooks/UseApi';
import { Sidebar } from 'primereact/sidebar';
import MobileMenu from '../MobileMenu';
import { FiMenu } from 'react-icons/fi';
import SmallCredits from '../SmallCredits';


const TopBar = () => {
    const sizeIcon = 24;
    // const user = useAuth
    const { user } = useAuth();
    const api = UseApi();
    
    const [ needVerify, setNeedVerify ] = useState(false);
    const [ sidebarVisible, setSidebarVisible ] = useState(false);

    useEffect(() => {
        setReady(true);
        checkIdentity();
    },[])

    const checkIdentity = async () => {
        try {
            const data = await api.getIdentityVerify();
            if (data?.identityStatus !== "APPROVED") {
                setNeedVerify(true);
            }
        } catch(e) {
            console.log(e);
        }
    }

    const hideSidebar = () => {
        setSidebarVisible(false);
    }

    const [ ready, setReady ] = useState(false);

    const renderOptions = () => {
        if (user.userRole === "ADMIN") {
            return (
                <MenuContainer>
                    <Link to="/dashboard">
                        <MenuItem>
                            <MenuIcon>
                                <img src={ChartIcon} alt="Home" width={sizeIcon}/>
                            </MenuIcon>
                            Home
                        </MenuItem>
                    </Link>
                    <Link to="/dashboard/withdrawals">
                        <MenuItem>
                            <MenuIcon>
                                <img src={BagIcon} alt="Home" width={sizeIcon}/>
                            </MenuIcon>
                            Solicitações de saques
                        </MenuItem>
                    </Link>
                </MenuContainer>
            )
        } else if (user.userRole === "CUSTOMER") {
            return (
                <MenuContainer>
                    <Link to="/dashboard">
                        <MenuItem>
                            <MenuIcon>
                                <img src={ChartIcon} alt="Home" width={sizeIcon}/>
                            </MenuIcon>
                            Home
                        </MenuItem>
                    </Link>
                    <Link to="/dashboard/customer/meetings">
                        <MenuItem>
                            <MenuIcon>
                                <img src={UserMeetIcon} alt="Home" width={sizeIcon}/>
                            </MenuIcon>
                            Mentorias
                        </MenuItem>
                    </Link>
                </MenuContainer>
            )
        } else {
            return (
                <MenuContainer>
                    <Link to="/dashboard">
                        <MenuItem>
                            <MenuIcon>
                                <img src={ChartIcon} alt="Home" width={sizeIcon}/>
                            </MenuIcon>
                            Home
                        </MenuItem>
                    </Link>
                    <Link to="/dashboard/products">
                        <MenuItem>
                            <MenuIcon>
                                <img src={UserMeetIcon} alt="Home" width={sizeIcon}/>
                            </MenuIcon>
                            Mentorias
                        </MenuItem>
                    </Link>
                    <Link to="/dashboard/orders">
                        <MenuItem>
                            <MenuIcon>
                                <img src={BagIcon} alt="Home" width={sizeIcon}/>
                            </MenuIcon>
                            Vendas
                        </MenuItem>
                    </Link>
                    <Link to="/dashboard/affiliates">
                        <MenuItem>
                            <MenuIcon>
                                <img src={NetworkIcon} alt="Home" width={sizeIcon}/>
                            </MenuIcon>
                            Afiliado
                        </MenuItem>
                    </Link>
                    <Link to="/dashboard/configs">
                        <MenuItem>
                            <MenuIcon>
                                <img src={HomeIcon} alt="Home" width={sizeIcon}/>
                            </MenuIcon>
                            Configurações
                        </MenuItem>
                    </Link>
                </MenuContainer>
            )
        }
    }

    return (
        <>
        {needVerify && <>
            <IdentityVerifyAlert>
                Para começar a vender, você precisa verificar sua identidade. 
                <Link to="/dashboard/configs">
                    Clique aqui para verificar.
                </Link>
                
            </IdentityVerifyAlert>
        </>}
        <Navbar variant="sticky" style={{maxWidth:"100%", backgroundColor:"#fff", zIndex:999}}>
            <ContentTopBar>
                <MobileBtn onClick={() => setSidebarVisible(true)}> 
                    <FiMenu color='#bebebe' size={28}/>
                </MobileBtn>
                <Link to="/dashboard">
                <img src={LogoImg} alt="Mentorados" width={40}/>
                </Link>
                {ready && renderOptions()}
                <CustomerContainer>
                    <AvatarMenu/>
                </CustomerContainer>
            </ContentTopBar>
        </Navbar>
        <Sidebar visible={sidebarVisible} onHide={() => setSidebarVisible(false)}>
            <SideBarContent>
                <MobileMenu hideSidebar={hideSidebar} user={user}/>
                <SmallCredits/>
            </SideBarContent>
        </Sidebar>
        </>
    )
}

export default TopBar;