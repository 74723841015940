import { User } from "../../types/User";

type Props = {
    user: User
}

const AccountStatus = ({user}: Props) => {
    
    return (
        <>AccountStatus : {user.userStatus}</>
    )
}

export default AccountStatus;