import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    position: fixed;
    bottom: 20px;
    right: 15px;
    background-color: #25D366;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 55px;
    color:#fff;
    border-radius: 100%;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.24) 0px 10px 30px -5px;
    z-index: 999;
`

export default Container;