import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

type CreateAccountType = {
    name: string;
    email: string;
    password: string;
    user_role: string;
}

type CreateProductType = {
    product_type: string;
    product_price: number;
    product_name: string;
    product_description: string;
    product_image: string;
    product_slots: number;
    product_duration: number;
    calendar: any;
    affiliated?: boolean;
    affiliated_fee?: number;
    affiliated_description?: string;
    link_group?: string;
    sales_type?: string;
}

type CreateProductGroupType = {
    product_type: string;
    product_price: number;
    product_name: string;
    product_description: string;
    product_image: string;
    product_slots: number;
    product_duration: number;
    events: any;
    affiliated?: boolean;
    affiliated_fee?: number;
    affiliated_description?: string;
    link_group?: string;
    sales_type?: string;
}

type UpdateProductType = {
    product_id: string;
    product_code: string;
    product_price: number;
    product_name: string;
    product_description: string;
    product_image?: string;
    product_slots: number;
    product_duration: number;
    calendar: any;
    affiliated?: boolean;
    affiliated_fee?: number;
    affiliated_description?: string;
    is_active: boolean;
    link_group?: string;
}

type UpdateProductGroupType = {
    product_id: string;
    product_code: string;
    product_price: number;
    product_name: string;
    product_description: string;
    product_image?: string;
    product_slots: number;
    affiliated?: boolean;
    affiliated_fee?: number;
    affiliated_description?: string;
    is_active: boolean;
    link_group?: string;
}

type CreateRequestAffiliatedType = {
    product_id: string;
}

type ConfirmRequestAffiliatedType = {
    affiliated_request_id: string;
}

type RequestWithdrawType = {
    wallet_id: string;
    amount: number;
}

type GetRecoveryCodeType = {
    email: string;
}

type UseRecoveryCodeType = {
    email: string;
    code: string;
}

type UpdatePasswordType = {
    recovery_id: string;
    email: string;
    password: string;
}

type UpdateUserConfigsType = {
    username: string;
    url: string;
    meta_pixel?: string;
    google_pixel?: string;
    tiktok_pixel?: string;
}

export const UseApi = () => ({
    validateToken: async (token: string) => {
        try {
            const response = await api.post('/validateToken', { token });
            return response.data;
        } catch {
            return null;
        }
    },
    signIn: async (email: string, password: string) => {    
        let response = await api.post('/auth', { email, password });
        return response.data;
        
    },
    signOut: async () => {
        const response = await api.post('/logout');
        return response.data;
    },
    createAccount: async ({name, email, password, user_role}: CreateAccountType) => {    
        let response = await api.post('/user/create', 
        { name, email, password, user_role },);
        return response.data;
    },
    createProduct: async ({
        product_type,
        product_price,
        product_name,
        product_description,
        product_image,
        product_slots,
        product_duration,
        calendar,
        affiliated,
        affiliated_fee,
        affiliated_description,
        link_group,
        sales_type
    }: CreateProductType) => {    
        let response = await api.post('/product/create', { 
            product_type,
            product_price,
            product_name,
            product_description,
            product_image,
            product_slots,
            product_duration,
            calendar,
            affiliated,
            affiliated_fee,
            affiliated_description,
            link_group, 
            sales_type
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    createProductGroup: async ({
        product_type,
        product_price,
        product_name,
        product_description,
        product_image,
        product_slots,
        product_duration,
        events,
        affiliated,
        affiliated_fee,
        affiliated_description,
        link_group,
        sales_type
    }: CreateProductGroupType) => {    
        let response = await api.post('/product/group/create', { 
            product_type,
            product_price,
            product_name,
            product_description,
            product_image,
            product_slots,
            product_duration,
            events,
            affiliated,
            affiliated_fee,
            affiliated_description ,
            link_group,
            sales_type
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    updateProductSolo: async ({
        product_id,
        product_code,
        product_price,
        product_name,
        product_description,
        product_image,
        product_slots,
        product_duration,
        calendar,
        affiliated,
        affiliated_fee,
        affiliated_description,
        is_active,
        link_group,
    }: UpdateProductType) => {    
        let response = await api.post('/product/update', { 
            product_id,
            product_code,
            product_price,
            product_name,
            product_description,
            product_image,
            product_slots,
            product_duration,
            calendar,
            affiliated,
            affiliated_fee,
            affiliated_description,
            is_active,
            link_group
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    updateProductGroup: async ({
        product_id,
        product_code,
        product_price,
        product_name,
        product_description,
        product_image,
        product_slots,
        affiliated,
        affiliated_fee,
        affiliated_description,
        is_active,
        link_group,
    }: UpdateProductGroupType) => {    
        let response = await api.post('/product/group/update', { 
            product_id,
            product_code,
            product_price,
            product_name,
            product_description,
            product_image,
            product_slots,
            affiliated,
            affiliated_fee,
            affiliated_description,
            is_active,
            link_group 
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getProductsByUser: async () => {
        const response = await api.get('/product/list',{
            headers: {
              'Authorization': `token ${localStorage.getItem('mentorados')}`
            }
        })

        return response.data;
    },
    getProductsToAffiliated: async () => {
        const response = await api.get('/product/list/affiliated',{
            headers: {
              'Authorization': `token ${localStorage.getItem('mentorados')}`
            }
        })

        return response.data;
    },
    createRequestAffiliated: async ({
        product_id,
    }: CreateRequestAffiliatedType) => {    
        let response = await api.post('/affiliated/request', { 
            product_id
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    createRequestWithdraw: async ({
        wallet_id,
        amount
    }: RequestWithdrawType) => {    
        let response = await api.post(`${process.env.REACT_APP_API_BANK_URL}/wallet/withdraw`, { 
            wallet_id,
            amount
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getAffiliationRequests: async () => {    
        let response = await api.get('/affiliated/request', {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    confirmRequestAffiliation: async ({
        affiliated_request_id,
    }: ConfirmRequestAffiliatedType) => {    
        let response = await api.post('/affiliated/request/confirm', { 
            affiliated_request_id
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getSalesByDay: async () => {    
        let response = await api.get('/order/list/sales-by-day', {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getSales: async () => {    
        let response = await api.get('/order/list/sales', {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getMyWallet: async () => {    
        let response = await api.get('/wallet/me', {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getMyMeetings: async () => {    
        let response = await api.get('/meeting/list/me', {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    updateUserCode: async (username: string) => {    
        let response = await api.post('user/update/configs', { 
            username
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    updateConfigs: async ({username, url, meta_pixel, google_pixel, tiktok_pixel}: UpdateUserConfigsType) => {    
        let response = await api.post('user/update/configs', { 
            username,
            url, 
            meta_pixel,
            google_pixel,
            tiktok_pixel,
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getProductToEdit: async ({
        product_id,
    }: CreateRequestAffiliatedType) => {    
        let response = await api.post('/product/edit', { 
            product_id
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getAffiliatesByProduct: async({
        product_id
    }) => {
        let response = await api.post('/product/affiliates/list', { 
            product_id
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    updateAffiliatedFee: async ({
        affiliated_id,
        affiliated_fee
    }) => {    
        let response = await api.post('/affiliated/fee/update', { 
            affiliated_id,
            affiliated_fee 
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getMyWithdraws: async () => {    
        let response = await api.get('/withdraw/me', {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getWithdrawalsByAdmin: async () => {    
        let response = await api.get('/withdraw/list', {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getRecoveryCode: async ({
        email
    }: GetRecoveryCodeType) => {    
        let response = await api.post('/recovery/code/get', { 
            email,
        });
        return response.data;
    },
    useRecoveryCode: async ({
        email,
        code
    }: UseRecoveryCodeType) => {    
        let response = await api.post('/recovery/code/use', { 
            email,
            code 
        });
        return response.data;
    },
    updatePassword: async ({
        recovery_id,
        email,
        password
    }: UpdatePasswordType) => {    
        let response = await api.post('/recovery/password/update', { 
            recovery_id,
            email,
            password 
        });
        return response.data;
    },
    getIdentityVerify: async () => {    
        let response = await api.get('/user/verification', {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    initIdentityVerify: async (cpf) => {    
        let response = await api.post('/user/verification-init', {
            cpf
        },
        {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    updateUserBankAccount: async ({ 
        document_type,
        document_id, 
        document_name,
        pix
    }) => {    
        let response = await api.post('user/details/save', { 
            document_type,
            document_id,
            document_name,
            pix
        }, {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getUserDetails: async () => {    
        let response = await api.get('/user/details', {
            headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`},
        });
        return response.data;
    },
    getLeads: async (page: number) => {
        let config = {
            headers: { 'Authorization': `token ${localStorage.getItem('authToken')}`},
            params: {
                page: page
            }
        }
        const response = await api.get('/lead/list', config)

        return response.data;
    },
    getLeadById: async (value) => {
        const response = await api.get(`/lead/${value}`, { 
            headers: {
                'Authorization': `token ${localStorage.getItem('authToken')}`
            }
        })

        return response;
    },
    // getLeadsFromSearch: async (value) => {
    //     const response = await api.get('/leads/search', { 
    //         params: {
    //             value
    //         }, 
    //         headers: {
    //             'Authorization': `token ${localStorage.getItem('authToken')}`
    //         }
    //     })

    //     return response;
    // },
    // getLeadHistory: async (id: string, page: number) => {
    //     const response = await api.get(`/history/${id}/${page}`, { 
    //         headers: {
    //             'Authorization': `token ${localStorage.getItem('authToken')}`
    //         }
    //     })

    //     return response;
    // },
    // getLeadEvent: async (id: string, page: number) => {
    //     const response = await api.get(`/event/${id}/${page}`, { 
    //         headers: {
    //             'Authorization': `token ${localStorage.getItem('authToken')}`
    //         }
    //     })

    //     return response;
    // },
})