import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';


const measurementId = process.env.REACT_APP_ANALYTICS_ID;
const fbPixel = process.env.REACT_APP_FB_PIXEL;
const tiktokPixel = process.env.REACT_APP_TIKTOK_PIXEL;

type AnalyticsPageType = {
    page: string;
    title: string;
}

const options = {
    debug: false, // enable logs
};



export const Analytics = () => ({
    
    init: () => {
        ReactGA.initialize(measurementId);
        ReactPixel.init(fbPixel);
        TiktokPixel.init(tiktokPixel);
    },
    addPage: ({page, title}: AnalyticsPageType) => {
        ReactGA.send({ hitType: "pageview", page, title });
        ReactPixel.pageView();
    },
    addEvent: (event: string, params?: {}) => {
        ReactGA.event(event, params)
        ReactPixel.trackCustom(event, params);
        TiktokPixel.track(event, params);
    },
    addFbDefaultEvent:(event) => {
        ReactPixel.track(event);
    }
})

export default Analytics;