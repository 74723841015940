import {
    useToast,
} from "@chakra-ui/react";
import { UseApi } from "../../hooks/UseApi";
import { useEffect, useState } from "react";
import LogoImg from '../../Assets/Images/mentorados-icon.png';
import { AnimatePresence, motion } from "framer-motion";
import {Button, Input, Loading, Spacer} from "@nextui-org/react";
import { BoxForm, CenterBox, InlineInput, LogoCt, TitleBox } from "./styles";
import CountdownTimer from "./CountdownTimer";

export default function RecoveryPage(props) {
    const toast = useToast();
    const api = UseApi();   

    const [ stepGetCode, setStepGetCode ] = useState(true);
    const [ stepUseCode, setStepUseCode ] = useState(false);
    const [ stepPassword, setStepPassword ] = useState(false);
    const [ email, setEmail ] = useState("");
    const [ invalidEmail, setInvalidEmail ] = useState(false); 
    const [ code, setCode ] = useState("");
    const [ invalidCode, setInvalidCode ] = useState(false);
    const [ newPassword, setNewPassword ] = useState("");
    const [ newInvalidPassword, setInvalidNewPassword ] = useState(false);
    const [ newPasswordConfirm, setNewPasswordConfirm ] = useState("");
    const [ invalidNewPasswordConfirm, setInvalidNewPasswordConfirm ] = useState(false);
    const [ recoveryId, setRecoveryId ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        setStepGetCode(true);
        setStepUseCode(false);
        setStepPassword(false);
        setNewPassword("");
        setNewPasswordConfirm("");
        setEmail("");
        setCode("");
        setRecoveryId("");
    },[])

    useEffect(() => {
        if (email.length > 1) setInvalidEmail(false);
    },[email])

    useEffect(() => {
        if (code.length > 1) setInvalidCode(false);
    },[code])

    useEffect(() => {
        if (newPassword.length > 1) setInvalidNewPassword(false);
    },[newPassword])

    useEffect(() => {
        if (newPasswordConfirm.length > 1) setInvalidNewPasswordConfirm(false);
    },[newPasswordConfirm])

    const submitRequestCode = async () => {
        setIsLoading(true);
        if (email.length < 1) {
            setInvalidEmail(true);
            toast({
                description: "Email inválido!",
                status: 'error',
                duration: 3000,
                isClosable: false,
                position: 'top',
            })
        } else {
            try {
                const data = await api.getRecoveryCode({ email });
                if (data.error){ 
                    toast({
                        description: "Email inválido!",
                        status: 'error',
                        duration: 3000,
                        isClosable: false,
                        position: 'top',
                    })  
                } else {
                    setStepGetCode(false);
                    setTimeout(() => {
                        setStepUseCode(true);
                    },500)
                }
            } catch(e) {
                toast({
                    description: "Email inválido!",
                    status: 'error',
                    duration: 3000,
                    isClosable: false,
                    position: 'top',
                }) 
            }
            
        }
        setIsLoading(false);
    }

    const submitUseCode = async () => {
        setIsLoading(true);
        if (code.length < 6) {
            setInvalidCode(true);
            toast({
                description: "Código incompleto.",
                status: 'error',
                duration: 3000,
                isClosable: false,
                position: 'top',
            })
        } else {
            try {
                const data = await api.useRecoveryCode({
                    email: email,
                    code: code,
                })
                if (data?.id) {
                    setRecoveryId(data.id);
                    setStepUseCode(false);
                    setTimeout(() => {
                        setStepPassword(true)
                    },500)
                } else {
                    toast({
                        description: "Código incorreto.",
                        status: 'error',
                        duration: 2000,
                        isClosable: false,
                        position: 'top',
                    })    
                }
            } catch(e) {
                toast({
                    description: "Código inválido.",
                    status: 'error',
                    duration: 3000,
                    isClosable: false,
                    position: 'top',
                })
            }
        }
        setIsLoading(false);
    }

    const submitNewPassword = async () => {
        setIsLoading(true);
        if (newPassword !== newPasswordConfirm) {
            setInvalidNewPassword(true);
            setInvalidNewPasswordConfirm(true);
            if(!toast.isActive('newPassword')) {
                toast({
                    id: 'newPassword',
                    description: "As senhas não são iguais!",
                    status: 'error',
                    duration: 2000,
                    isClosable: false,
                    position: 'top',
                })
            }
        } else {
            try {
                const data = await api.updatePassword({
                    recovery_id: recoveryId,
                    email: email,
                    password: newPassword
                })
                if (data?.id) {
                    toast({
                        id: 'newPassword',
                        description: "Senha atualizada com sucesso",
                        status: 'success',
                        duration: 2000,
                        isClosable: false,
                        position: 'top',
                    })
                    props.handleRecovery(false);
                }
                
            } catch(e) {
                toast({
                    description: "Erro ao atualizar a senha, entre em contato com o suporte.",
                    status: 'error',
                    duration: 3000,
                    isClosable: false,
                    position: 'top',
                })
            }
        }
        setIsLoading(false);
    }
    

    const renderGetRecoveryCode = () => {
        return (
            <AnimatePresence>
                    {stepGetCode && (
                        <motion.div 
                        initial={{ opacity: 0}}
                        animate={{ opacity: 1, x: [60,-20,0]}}
                        transition={{ ease: "easeInOut", duration: 0.4 }}
                        exit={{ opacity: 0, x: [0,-40,80] }}
                        >
                        <LogoCt>
                            <img src={LogoImg} alt="Welcome"/>
                        </LogoCt>
                        <CenterBox>
                        <TitleBox>
                            <h1>
                                Esqueceu sua senha?
                            </h1>
                            <p>
                                Entre com seu email para receber um código de autorização e com ele conseguir atualizar sua senha.
                            </p>
                        </TitleBox>
                        <BoxForm style={{marginTop: 20}}>
                        <InlineInput>
                            <Input 
                            labelPlaceholder="Seu email"
                            disabled={isLoading}
                            value={email}
                            status={invalidEmail ? 'error' : 'default'}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            />
                        </InlineInput>
                        <Spacer y={-1.6} />
                        </BoxForm>
                        <Button size="lg" shadow color="secondary" auto onPress={() => submitRequestCode()}>
                            {isLoading ? <Loading color="currentColor" size="sm" /> : <>Enviar</>}
                        </Button>
                        </CenterBox>
                        </motion.div>
                    )}
                </AnimatePresence>
        )
    }

    const renderUseRecoveryCode = () => {
        return (
            <AnimatePresence>
                    {stepUseCode && (
                        <motion.div 
                        initial={{ opacity: 0}}
                        animate={{ opacity: 1, x: [60,-20,0]}}
                        transition={{ ease: "easeInOut", duration: 0.4 }}
                        exit={{ opacity: 0, x: [0,-40,80] }}
                        >
                        <LogoCt>
                            <img src={LogoImg} alt="Welcome"/>
                        </LogoCt>
                        <CenterBox>
                        <TitleBox>
                            <h1>
                                Enviamos seu código por email.
                            </h1>
                            <p>
                                Insira o código que acabou de receber por email.
                            </p>
                            <Spacer y={0.4} />
                            <CountdownTimer/>
                        </TitleBox>
                        <BoxForm style={{marginTop: 20}}>
                        <InlineInput>
                            <Input 
                            labelPlaceholder="Seu código"
                            disabled={isLoading}
                            value={code}
                            status={invalidCode ? 'error' : 'default'}
                            onChange={(e) => setCode(e.currentTarget.value)}
                            />
                        </InlineInput>
                        <Spacer y={-1.6} />
                        </BoxForm>
                        <Button size="lg" shadow color="secondary" auto onPress={() => submitUseCode()}>
                            {isLoading ? <Loading color="currentColor" size="sm" /> : <>Enviar</>}
                        </Button>
                        </CenterBox>
                        </motion.div>
                    )}
                </AnimatePresence>
        )
    }

    const renderNewPassword = () => {
        return (
            <AnimatePresence>
                    {stepPassword && (
                        <motion.div 
                        initial={{ opacity: 0}}
                        animate={{ opacity: 1, x: [60,-20,0]}}
                        transition={{ ease: "easeInOut", duration: 0.4 }}
                        exit={{ opacity: 0, x: [0,-40,80] }}
                        >
                        <LogoCt>
                            <img src={LogoImg} alt="Welcome"/>
                        </LogoCt>
                        <CenterBox>
                        <TitleBox>
                            <h1>
                                Nova senha.
                            </h1>
                            <p>
                                Insira sua nova senha.
                            </p>
                        </TitleBox>
                        <BoxForm style={{marginTop: 20}}>
                        <InlineInput>
                                <Input.Password 
                                color="default"
                                labelPlaceholder="Sua senha"
                                disabled={isLoading}
                                value={newPassword}
                                status={newInvalidPassword ? 'error' : 'default'}
                                onChange={(e) => setNewPassword(e.currentTarget.value)}
                                />
                            </InlineInput>
                            <Spacer y={2} />
                            <InlineInput>
                                <Input.Password 
                                color="default"
                                labelPlaceholder="Confirmar senha"
                                disabled={isLoading}
                                value={newPasswordConfirm}
                                status={invalidNewPasswordConfirm ? 'error' : 'default'}
                                onChange={(e) => setNewPasswordConfirm(e.currentTarget.value)}
                                />
                            </InlineInput>
                        <Spacer y={-1.6} />
                        </BoxForm>
                        <Button size="lg" shadow color="secondary" auto onPress={() => submitNewPassword()}>
                            {isLoading ? <Loading color="currentColor" size="sm" /> : <>Salvar</>}
                        </Button>
                        </CenterBox>
                        </motion.div>
                    )}
                </AnimatePresence>
        )
    }

    return (
        <>
            {renderGetRecoveryCode()}
            {renderUseRecoveryCode()}
            {renderNewPassword()}
        </>
    )
}