import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { FormLine } from "../../styles/main";
import { Button } from "@nextui-org/react";

type Props = {
  bucket: string;
  disabled: boolean;
  uploadHandler: (filePath: string) => void;
}

export default function Uploader(props: Props) {
  const [fileName, setFileName] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [removeIcon, setRemoveIcon] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("Selecione uma imagem");
  const toast = useRef(null);
  const fileUploadRef = useRef(null);

  const getSignedUrl = async (file: File) => {
    console.log("getSignedUrl", file);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/product/upload/image?type=`+file.type.split('/')[1], {
      headers: { 'Authorization': `token ${localStorage.getItem('mentorados')}`}
    });
      // `/api/upload?name=${file.name}&type=${file.type}&bucket=${props.bucket}`
      console.log("response", response);
    const data : { key: string, url: string } = await response.json();
    console.log("data", data);

    return {
      signedUrl: data.url,
      filePath: data.key,
    }
  }

  const onTemplateSelect = (e) => {
    let file = e.files[0];
    setFileName(file.name);
    setUploading(true);
    setButtonLabel("Enviando imagem...");
};

  const customUploadHandler = async (event: any) => {
    setDisableButton(true);
    setRemoveIcon(true);
    const file = event.files[0];
    const { signedUrl, filePath } = await getSignedUrl(file);
    const response = await fetch(signedUrl, {
      method: "PUT",
      body: file,
    });

    if (response.ok) {
      props.uploadHandler(filePath);
      toast.current.show({ severity: 'info', summary: 'Success', detail: 'Arquivo salvo' });
    } else {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Erro ao salvar arquivo' });
      setButtonLabel("Selecione uma imagem");
      setRemoveIcon(false);
    }
    setUploading(false);
    setDisableButton(false);
    console.log("fileUploadRef", fileUploadRef.current.props);
    setButtonLabel("Enviado com sucesso");
  }

  const clearFileUpload = () => {
    setFileName("");
    setRemoveIcon(false);
    setButtonLabel("Selecione uma imagem");
    fileUploadRef.current.clear();
    props.uploadHandler("");
  }

  return (
    <FormLine>
      <Toast ref={toast}></Toast>

      <FileUpload
        disabled={props.disabled || disableButton}
        mode="basic"
        ref={fileUploadRef}
        auto
        name="demo[]"
        accept="image/*"
        maxFileSize={1000000}
        onUpload={() => console.log("upload")}
        onSelect={onTemplateSelect}
        chooseOptions={{
          label: buttonLabel,
          icon: `pi pi-fw pi-${removeIcon ? uploading ?  'clock' : 'check' : 'plus'}`,
        }}
        customUpload
        uploadHandler={customUploadHandler}
      />
      <span style={{alignSelf: 'center', marginLeft: 15, marginRight: 15}}>{fileName}</span>
      {fileName && <Button style={{alignSelf: 'center'}} color="warning" size="xs" onPress={clearFileUpload}>Limpar</Button>}
    </FormLine>
  )
}