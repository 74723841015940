import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`

export const Content = styled.div`
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    p{
        font-size: 12px;
        color:#888;
    }
`

export default Container;