import styled from "styled-components";

export const ContainerCenter = styled.div`
    display: flex;
    margin-top:40px;
    /* justify-content: center; */
    align-items: center;
    flex-wrap: wrap;
`
export const CardCt = styled.div`
    display: flex;
    margin-right: 10px;
    min-width: 310px;
`

export const InfoCt = styled.div`
    display: flex;
    /* margin-top:14px; */
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding:10px 0px;
    &:last-of-type {
        border-bottom: 0px;
    }
`

export const InfoValue = styled.div`
    display: flex;
    /* font-weight: 600; */
    font-size: 18px;
    color:#555;
`

export default ContainerCenter;