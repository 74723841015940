import { Outlet } from 'react-router-dom';
// import ColorModeSwitcher from '../../components/ColorModeSwitch';
import TopBar from '../../components/TopBar';
import { Content, ProgressCt } from './styles';
import { Progress } from '@nextui-org/react';
import { useAuth } from '../../Auth/AuthProvider';
import Support from '../../components/Support';
import FooterBar from '../../components/FooterBar';


export default function Dashboard() {
    const { mainLoader } = useAuth();
    return (
        <>
            <TopBar/>
            <ProgressCt>
                {mainLoader && <Progress
                indeterminated
                value={50}
                color="secondary"
                size="xs"
                />}
            </ProgressCt>
            <Content>
                <Outlet />
                <FooterBar/>
            </Content>
            <Support/>
        </>
    )
}