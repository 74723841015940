const hoursToWork = [
    {
        name: '00:00', code: 0.0
    },
    {
        name: '01:00', code: 1.0
    },
    {
        name: '01:30', code: 1.3
    },
    {
        name: '02:00', code: 2
    },
    {
        name: '02:30', code: 2.3
    },
    {
        name: '03:00', code: 3
    },
    {
        name: '03:30', code: 3.3
    },
    {
        name: '04:00', code: 4
    },
    {
        name: '04:30', code: 4.3
    },
    {
        name: '05:00', code: 5
    },
    {
        name: '05:30', code: 5.3
    },
    {
        name: '06:00', code: 6
    },
    {
        name: '06:30', code: 6.3
    },
    {
        name: '07:00', code: 7
    },
    {
        name: '07:30', code: 7.3
    },
    {
        name: '08:00', code: 8
    },
    {
        name: '08:30', code: 8.3
    },
    {
        name: '09:00', code: 9
    },
    {
        name: '09:30', code: 9.3
    },
    {
        name: '10:00', code: 10
    },
    {
        name: '10:30', code: 10.3
    },
    {
        name: '11:00', code: 11
    },
    {
        name: '11:30', code: 11.3
    },
    {
        name: '12:00', code: 12
    },
    {
        name: '12:30', code: 12.3
    },
    {
        name: '13:00', code: 13
    },
    {
        name: '13:30', code: 13.3
    },
    {
        name: '14:00', code: 14
    },
    {
        name: '14:30', code: 14.3
    },
    {
        name: '15:00', code: 15
    },
    {
        name: '15:30', code: 15.3
    },
    {
        name: '16:00', code: 16
    },
    {
        name: '16:30', code: 16.3
    },
    {
        name: '17:00', code: 17
    },
    {
        name: '17:30', code: 17.3
    },
    {
        name: '18:00', code: 18
    },
    {
        name: '18:30', code: 18.3
    },
    {
        name: '19:00', code: 19
    },
    {
        name: '19:30', code: 19.3
    },
    {
        name: '20:00', code: 20
    },
    {
        name: '20:30', code: 20.3
    },
    {
        name: '21:00', code: 21
    },
    {
        name: '21:30', code: 21.30
    },
    {
        name: '22:00', code: 22
    },
    {
        name: '22:30', code: 22.3
    },
    {
        name: '23:00', code: 23
    },
]

export default hoursToWork;