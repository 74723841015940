import { Table, Row, Col, Text, Badge, Modal, Button, Tooltip } from "@nextui-org/react";
import TimeIcon from '../../Assets/icons/timeIcon.svg';
import { useAuth } from "../../Auth/AuthProvider";
import { useEffect, useState } from "react";
import { ModalAlertCt, ModalLine } from "./styles"
import { FiX, FiEdit3, FiExternalLink  } from "react-icons/fi"
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export const MeetingList = (props) => {
      
    
    const columns = [
        { name: "DATA", uid: "data" },
        { name: "MENTORIA", uid: "mentoria" },
        { name: "DETALHES", uid: "details" },
        { name: "ACTIONS", uid: "actions" },
      ];

    const renderCell = (item, columnKey) => {
        const cellValue = item[columnKey];
        switch (columnKey) {
          case "data":
            return (
                <Col>
                    <Row>
                    <Text b size={14} css={{ tt: "capitalize" }}>
                        {format(item.start_date, 'E, dd \'de\' LLLL \'de\' yyyy \':\' HH:mm', { locale: ptBR } )}
                    </Text>
                    </Row>
                    <Row>
                    <Text b size={13} css={{ tt: "capitalize", color: "$accents7" }}>
                        Termina: {format(item.end_date, 'E, dd \'de\' LLLL \'de\' yyyy \':\' HH:mm', { locale: ptBR } )}
                    </Text>
                    </Row>
                </Col>
            );
          case "mentoria":
            return (
              <Col>
                <Row>
                  <Text b size={14} css={{ tt: "capitalize" }}>
                    {item.product_name}
                  </Text>
                </Row>
              </Col>
            );
          case "details":
            return (
              <Col>
                <Row>
                  <Text b size={14} css={{ tt: "capitalize" }}>
                    {item.meeting_duration} minutos
                  </Text>
                </Row>
                <Row>
                  <Text b size={13} css={{ tt: "capitalize", color: "$accents7", display:"flex" }}>
                    Tipo: {item.product_type}
                  </Text>
                </Row>
              </Col>
            );
          case "actions":
            return (
              <Row justify="center" align="center">
                <Col css={{ d: "flex" }}>
                  <Tooltip content="Editar mentoria">
                    <Button 
                    auto 
                    size="xs" 
                    color="secondary"
                    bordered
                    // onPress={() => console.log("navigate")}
                    icon={<FiExternalLink size={16}/>}>
                      Entrar
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            );
          default:
            return cellValue;
        }
      };

      return (
        <>
          <Table
              striped
              sticked
              bordered={false}
              shadow={false}
              aria-label="Example static striped collection table"
              css={{
                  height: "auto",
                  minWidth: "100%",
              }}
              className="class-border-less"
              >
            <Table.Header columns={columns}>
              {(column) => (
                <Table.Column
                  key={column.uid}
                  hideHeader={column.uid === "actions"}
                  align={column.uid === "actions" ? "center" : "start"}
                >
                  {column.name}
                </Table.Column>
              )}
            </Table.Header>
            <Table.Body items={props.meetings}>
              {(item) => {
                return (
                  <Table.Row>
                    {(columnKey) => (
                      <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                    )}
                  </Table.Row>
                )
              }}
            </Table.Body>
          </Table>
        </>
      );


}

export default MeetingList;