import styled from "styled-components";

export const CtRow = styled.div`
    display: flex;
`

export const ContainerOp = styled.div`
    display: flex;
    width: 100%;
    /* background-color: #444; */
    button{
        margin-left:10px;
    }
`

export default CtRow;