import { User } from "../../types/User";
import { Link, useNavigate } from "react-router-dom";
import { MenuContainer, MenuItem, MenuIcon, MenuItemInvalid } from "./styles";
import LogoImg from '../../Assets/Images/mentorados-icon.png';
import HomeIcon from '../../Assets/icons/homeIcon.svg';
import UserMeetIcon from '../../Assets/icons/userMeetIcon.svg';
import BagIcon from '../../Assets/icons/bagIcon.svg';
import NetworkIcon from '../../Assets/icons/networkIcon.svg';
import ChartIcon from '../../Assets/icons/chartIcon.svg';

type Props = {
    user: User;
    hideSidebar: Function;
}

const MobileMenu = ({user, hideSidebar}: Props) => {
    const sizeIcon = 24;
    const navigate = useNavigate();

    const goTo = (link) => {
        navigate(link);
        hideSidebar();
    }
    
    const renderOptions = () => {
        if (user.userRole === "ADMIN") {
            return (
                <MenuContainer>
                    <Link to="/dashboard">
                        <MenuItem>
                            <MenuIcon>
                                <img src={ChartIcon} alt="Home" width={sizeIcon}/>
                            </MenuIcon>
                            Home
                        </MenuItem>
                    </Link>
                    <Link to="/dashboard/withdrawals">
                        <MenuItem>
                            <MenuIcon>
                                <img src={BagIcon} alt="Home" width={sizeIcon}/>
                            </MenuIcon>
                            Solicitações de saques
                        </MenuItem>
                    </Link>
                </MenuContainer>
            )
        } else {
            return (
                <MenuContainer>
                    <MenuItem onClick={() => goTo("/dashboard")}>
                        <MenuIcon>
                            <img src={ChartIcon} alt="Home" width={sizeIcon}/>
                        </MenuIcon>
                        Home
                    </MenuItem>
                    <MenuItem onClick={() => goTo("/dashboard/products")}>
                        <MenuIcon>
                            <img src={UserMeetIcon} alt="Home" width={sizeIcon}/>
                        </MenuIcon>
                        Mentorias
                    </MenuItem>
                    <MenuItem onClick={() => goTo("/dashboard/orders")}>
                        <MenuIcon>
                            <img src={BagIcon} alt="Home" width={sizeIcon}/>
                        </MenuIcon>
                        Vendas
                    </MenuItem>
                    <MenuItemInvalid>
                        <MenuIcon>
                            <img src={NetworkIcon} alt="Home" width={sizeIcon}/>
                        </MenuIcon>
                        Afiliados (Em breve)
                    </MenuItemInvalid>
                    <MenuItem onClick={() => goTo("/dashboard/configs")}>
                        <MenuIcon>
                            <img src={HomeIcon} alt="Home" width={sizeIcon}/>
                        </MenuIcon>
                        Configurações
                    </MenuItem>
                </MenuContainer>
            )
        }
    }
    
    return (
        <>
            {renderOptions()}
        </>
    )
}

export default MobileMenu;