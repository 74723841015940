import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { UseApi } from "../../hooks/UseApi";
import { Button, Loading } from "@nextui-org/react";

type Props = {
    affiliatedRequestId: string;
    forceUpdate?: any;
}

const ConfirmRequestBtn = (props: Props) => {
    const toast = useToast();
    const api = UseApi();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ confirmed, setConfirmed ] = useState(false);


    const confirmRequest = async () => {
        setIsLoading(true);
        try {
            const data = await api.confirmRequestAffiliation({affiliated_request_id: props.affiliatedRequestId})
            if (!data.error) {
                toast({
                    description: "Parabéns! Afiliação confirmada.",
                    status: 'success',
                    duration: 3000,
                    isClosable: false,
                    position: 'top',
                })
                setConfirmed(true);
                props.forceUpdate();
            } else {
                toast({
                    description: "Erro (#CR872) Por favor, entre em contato com o suporte",
                    status: 'error',
                    duration: 3000,
                    isClosable: false,
                    position: 'top',
                })
            }
        } catch(error) {
            toast({
                description: "Erro (#CR873) Por favor, entre em contato com o suporte",
                status: 'error',
                duration: 3000,
                isClosable: false,
                position: 'top',
            })
        }
        setTimeout(() => {
            setIsLoading(false);
        },700)
    }

    const renderLoading = () => {
        if (isLoading) {
            return (
                <Loading size="xs"/>
            )
        } else {
            return (
                <>Aprovar</>
            )
        }
    }

    return (
        <>
            {!confirmed && <Button size="xs" shadow onPress={() => confirmRequest()}>
                {renderLoading()}
            </Button>}
        </>
    )
}

export default ConfirmRequestBtn