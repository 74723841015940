import styled from "styled-components";

export const ContainerTopBar = styled.div`
    max-width:100%;
    background-color: #fff;
`

export const IdentityVerifyAlert = styled.div`
    display: flex;
    background-color: #4b1584;
    justify-content: center;
    color:#fff;
    padding:6px;
    font-size:14px;
    a{
        margin-left:14px;
    }
    @media(max-width:768px) {
        font-size: 12px;
        flex-direction: column;
        align-items: center;
    }
`

export const ContentTopBar = styled.div`
    display: flex;
    width: 100%;
    max-width: 980px;
    justify-content: space-between;
    /* justify-content: center; */
    align-items: center;
    margin:auto;
    padding:0px 20px;
`

export const Logo = styled.div`
    display: flex;
    width: 20px;
    box-sizing: border-box;
    img{
        width: 20px;
    }
`

export const MobileBtn = styled.div`
    display: none;
    @media(max-width:768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;   
        height: 40px;
        margin-right: -80px;
    }
`


export const MenuContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width: 768px) {
        display: none;
    }
`

export const CustomerContainer = styled.div`
    display: flex;
`

export const MenuItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0px 20px;
    cursor: pointer;
    font-size:14px;
`

export const MenuIcon = styled.div`
    display: flex;
    margin-right: 5px;
    img{
        width: 18px;
    }
`

export const SideBarContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`

export default ContainerTopBar;