import Container, { Block, Content, ContentImage, Cta, Text } from "./styles"
import Image from "../../Assets/Images/especialista-vendas.png";
import { BsWhatsapp } from "react-icons/bs";
import Analytics from "../../Analytics";

const NeedSales = () => {

    const analytics = Analytics();

    const needExpert = () => {
        analytics.addEvent("DashboardClickExpert");
        window.location.replace('https://wa.me/5514996275796?text=Ol%C3%A1%2C+quero+ajuda+para+destravar+minhas+vendas+no+Mentorados.');
    }

    return (
        <Container>
            <Content>
                <Block>
                    <h2>Precisa de ajuda para iniciar suas vendas?</h2>
                    <p>Conte com especialistas em marekting do nosso time e destrave agora mesmo suas primeiras vendas.</p>
                    <Cta onClick={() => needExpert()}>
                        <p>
                        Fale com um especialista
                        </p>
                        <BsWhatsapp/>
                    </Cta>
                </Block>
            </Content>
            <ContentImage>
                <img src={Image} alt="Fale com especialista" width={160}/>
            </ContentImage>
        </Container>
    )
}

export default NeedSales;