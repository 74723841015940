import styled from "styled-components";

export const InfoContainer = styled.div`
    display: flex;
    width: 100%;
    border-left: 4px solid #ccc;
    background-color: #f2f2f2;
    border: 1px solid #DDD;
    border-left-width: 4px;
    border-radius: 6px;
    color: #555;
    padding:10px;
    font-size:12px;
    margin-top:10px;
`

export const AlertContainer = styled.div`
    display: flex;
    width: 100%;
    background-color: #FDF7DF;
    border: 1px solid #e4c801;
    border-left-width: 6px;
    border-radius: 6px;
    color: #C9971C;
    padding:10px;
    font-size:12px;
    margin-top:10px;
    font-weight: 400;
    font-size: 14px;
`

export const InfoContent = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img{
        margin-right: 8px;
    }
`

export default InfoContainer;