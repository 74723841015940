import styled from "styled-components";

export const ActionCt = styled.div`
    display: flex;
    justify-content: space-between;
    @media(max-width:768px) {
        flex-direction: column;
    }
`

export const ActionButtons = styled.div`
    display: flex;
    @media(max-width:768px) {
        margin-top:20px;
        justify-content: center;
    }
    
`

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    h1{
        font-size:18px;
        font-weight: 600;
    }
`

export const ScheduleCt = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
        width: 50px;
    }
    color:#bdbdbd;
`

export const BoxText = styled.div`
    display: flex;
    /* background:#ccc; */
    h2{
        font-size:18px;
        font-weight: 600;
    }
    flex-direction: column;
    p{
        font-size:12px;
        margin-bottom:12px;
    }
    h3{
        font-size:16px;
        font-weight: 600;
    }
`

export const BoxHoursExemple = styled.div`
    display: flex;
    margin-left:40px;
`

export const BoxHoursAlert = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background:#444; */
    font-size: 12px;
    p{
        margin-left:10px;
    }
    margin-top:12px;
    margin-bottom: 14px;
`

export const InputHoursCt = styled.div`
    display: flex;
    /* width: 25%; */
    margin-right: 14px;
    @media (max-width:768px) {
        /* flex-direction: row; */
        width: 100%;
        margin-bottom:10px;
        .p-dropdown {
            /* background-color: #444; */
            width: 100%;
            margin-right: 0px;
        }
    }
`

export const InputDaysWeekCt = styled.div`
    display: flex;
    /* width: 25%; */
    margin-right: 14px;
    @media (max-width:768px) {
        margin-bottom:14px;
        width: 100%;
        .p-dropdown{
            width: 100%;
        }
    }
`

export const HourAvailableCt = styled.div`
    display: flex;
    /* justify-content: space-around; */
    width: 100%;
    flex-direction: column;
`

export const HourItem = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 10px 0px;
    &:last-of-type {
        border-bottom: 0px;
    }
    @media (max-width: 768px) {
        /* flex-direction: column; */
        width: 100%;
        font-size: 12px;
        justify-content: space-between;
        align-items: center;
        .mobileHide{
            display: none;
        }
        .trashIcon{
            width: 26px;
        }
    }
`

export const HourColumn = styled.div`
    display: flex;
    width: 25%;
    justify-content: flex-start;
    cursor: pointer;
    img{
        margin-right: 10px;
    }
`

export const ModalContent = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding:30px 0px;
    p{
        margin-top:20px;
        color:#bdbdbd;
    }
`

export const ContainerCalendar = styled.div`
    display: flex;
    @media (max-width:768px) {
        flex-direction: column;
    }
`

export const BoxSessions = styled.div`
    display: flex;
    margin-left: 16px;
    flex-direction: column;
    width: 100%;
    @media (max-width:768px) {
        margin-left: 0px;
        margin-top:20px;
    }
`

export const BoxHourInput = styled.div`
    display: flex;
`

export const BoxHourBtn = styled.div`
    display: flex;
`

export const BtnAddMeetingGroup = styled.div`
    
`

export const MeetingGroupContainer = styled.div`
    display: flex;
    margin-top:14px;
    justify-content: center;
    align-items: flex-start;
    margin-left:6px;
    flex-direction: column;
`

export const MeetingGroupItem = styled.div`
    display: flex;
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
    margin-bottom:8px;
    p{
        margin-left:8px;
    }
    &:last-of-type{
        border-bottom: 0px;
    }
`

export const LineMeetingGroupAction = styled.div`
    display: flex;
    justify-content: space-between;
    button{
        width: 48%;
    }
`

export const EventContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const EventItem = styled.div`
    display: flex;
    flex-direction: column;
`

export const EventTitle = styled.div`
    display: flex;
    justify-content: flex-star;
    font-size:12px;
`

export const EventMeetings = styled.div`
    display: flex;
    margin-left:0px;
    margin-top:2px;
    align-items: center;
    p{
        margin-left:10px;
        font-size:20px;
        color:#222;
    }
    @media (max-width:768px) {
        margin-top:20px;
    }
`

export const EventMeetingsInProgress = styled.div`
    display: flex;
    margin-left:0px;
    margin-top:2px;
    align-items: flex-start;
    flex-direction: column;
    p{
        margin-left:10px;
        font-size:20px;
        color:#222;
    }
`

export const LineMeeting = styled.div`
    display: flex;
`

export const WithouEventCt = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:20px;
    p{
        font-size:18px;
        color:#888;
        margin-top:24px;
        text-align: center;
    }
`

export default ActionCt;