import { Fieldset } from 'primereact/fieldset';
import CalendarEmptyIcon from "../../Assets/icons/calendarEmptyIcon.svg";
import { HourAvailableCt, HourColumn, HourItem, ScheduleCt } from './styles';
import CalendarIcon from "../../Assets/icons/calendarFillIcon.svg";
import TimeIcon from "../../Assets/icons/timeIcon.svg";
import TrashIcon from "../../Assets/icons/trashIcon.svg";

type Props = {
    hours: any,
    removeAvailbleHours:any
}

const AvailableHours = (props: Props) => {

    const hourData = props.hours.map(hour => {
        return (
            <HourItem key={hour.key}>

                <HourColumn>
                <img className='mobileHide' src={CalendarIcon} alt='dia'/>  {hour.weekday.name}
                </HourColumn>
                <HourColumn className='mobileHour'>
                <img src={TimeIcon} alt='dia'/>Início: {hour.start.name}
                </HourColumn>
                <HourColumn className='mobileHour'>
                <img src={TimeIcon} alt='dia'/>Fim: {hour.end.name}
                </HourColumn>
                <HourColumn className='trashIcon' onClick={() => props.removeAvailbleHours(hour.key)} style={{justifyContent: "flex-end"}}>
                <img src={TrashIcon} alt="remover"/>
                </HourColumn>
            </HourItem>
        )
    })
    
    const content = () => {
        if (props.hours.length >= 1) {
            return <HourAvailableCt>{hourData}</HourAvailableCt>
            // return (
            //     <HourAvailableCt>
            //         {props.hours.map(hour => {
            //             return <HourItem key={hour.key}>
            //                 {hour.weekday} / {hour.start} / {hour.end}
            //             </HourItem>
            //         })}
            //     </HourAvailableCt>
            // )
        } else {
            return (
                <ScheduleCt>
                    <img src={CalendarEmptyIcon} alt='nenhum horário'/>
                    <h5>
                        Nenhum horário cadastrado
                    </h5>
                </ScheduleCt>
            )
        }
    }
    
    return (
        <Fieldset legend="Horários disponíveis">
            {content()}
        </Fieldset>
    )
}

export default AvailableHours;

