import LogoImg from '../../Assets/Images/mentorados-icon.png';
import { Container, Content } from './styles';


const SmallCredits = () => {
    const date = new Date();
    return (
        <Container>
            <img src={LogoImg} alt='Mentorados' width={32}/> 
            <Content>
                <p>Mentorados © {date.getFullYear()}</p> 
                <p>Learning Machine Aplicativos LTDA</p>
                <p>CNPJ nº. 40.695.305/0001-80</p>
            </Content>
        </Container> 
    )
}

export default SmallCredits;

