import InfoContainer, { AlertContainer, InfoContent } from "./styles";
import InfoIcon from "../../Assets/icons/messageIcon.svg";
import AlertIcon from "../../Assets/icons/alertIcon.svg";

type Props = {
    type: string;
    content: string;
}

const AlertInfo = (props: Props) => {



    switch(props.type) {
        case "info":
            return (
                <InfoContainer>
                    <InfoContent>
                        <img src={InfoIcon} alt="Aviso"/>
                        {props.content}
                    </InfoContent>
                </InfoContainer>
            )
        case "alert":
            return (
                <AlertContainer>
                        <InfoContent>
                        <img src={AlertIcon} alt="atenção"/>
                        {props.content}
                        </InfoContent>
                </AlertContainer>
            )
        default: 
            return(
                <>
                {props.content}
                </>
            )
    }
}

export default AlertInfo;