import styled from "styled-components";

export const RowInline = styled.div`
    display: flex;
    width: 100%;
    margin: 24px 0px;
    flex-direction: column;
`

export const LineButtonRight = styled.div`
    display: flex;
    margin: 24px 0px;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
`

export const BoxContainer = styled.div`
    background-color: #fff;
    box-shadow: 5px 15px 25px #e5e5e5a0;
    border-radius: 6px;
    padding:10px;
    width: 100%;
`

export const ContentLoading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80px;
    justify-content: center;
`

export const EmptyContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    flex-direction: column;
    img{
        width: 60px;
    }
    p{
        font-size:14px;
        /* color:#8a90a2; */
        color:#d6d6d6;
    }
`

export const FormLine = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin:10px 0px;
    @media(max-width:768px) {
        flex-direction: column;
    }
`

export const FormLineWithText = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin:10px 0px;
    align-items: center;
    p{
        margin-left: 10px;
    }
`

export const FormLineColumnDirection = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    margin:10px 0px;
`

export const RadioCt = styled.div`
    display: flex;
    align-items:center;
    margin-right: 10px;
    margin-left:10px;
    label{
        margin-left:5px;
        cursor: pointer;
    }
    @media(max-width:768px) {
        margin-bottom:10px;
        &:first-child{
            margin-top:10px;
        }
    }
`

export const PageTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /* padding:20px; */
    /* width: 100%; */
    text-align: left;
    h1{
        font-size: 18px;
        font-weight: 600;
    }
    p{
        font-size:14px;
    }
`

export const BoxText = styled.div`
    display: flex;
    /* background:#ccc; */
    h2{
        font-size:18px;
        font-weight: 600;
    }
    flex-direction: column;
    p{
        font-size:12px;
        margin-bottom:12px;
    }
    h3{
        font-size:16px;
        font-weight: 600;
    }
`

export const EmptyTableLine = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    color:#999;
`

export default RowInline;