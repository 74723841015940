import { Table, Row, Col, Text, Badge, Modal, Button, Tooltip } from "@nextui-org/react";
import TimeIcon from '../../Assets/icons/timeIcon.svg';
import { useAuth } from "../../Auth/AuthProvider";
import { useEffect, useState } from "react";
import { ModalAlertCt, ModalLine } from "./styles"
import { FiX, FiEdit3 } from "react-icons/fi"
import { useNavigate } from "react-router-dom";

export const ProductList = (props) => {
      // TODO REFACTOR MODAL
      const navigate = useNavigate();
      const { user } = useAuth();
      const url = process.env.REACT_APP_CALENDAR_URL;
      
      const [ linkVisible, setLinkVisible ] = useState(false);
      const [ link, setLink ] = useState("");
      const [ hasUserCode, setHasUserCode ] = useState(false); 
      const [ selectedProduct, setSelectedProduct ] = useState<any>({});

      useEffect(() => {
        if (user.username !== null) {
          setHasUserCode(true);
        } 
      }, [])  

      const openModal = (item) => {
        setSelectedProduct(item)
        setLink(url + user.username + "?mentoria=" + item.productCode)
        setLinkVisible(true);
      }

      const contentModal = () => {
        if (hasUserCode) {
          return (
            <Modal.Body>
                <Text />
                Mentoria: {selectedProduct.productName}
                <Text/>
                <Text b size={18}/>
                Copie e divulga o link abaixo: 
                <Text/>
                <ModalLine>
                {link}
                </ModalLine>
            </Modal.Body>
          )
        } else {
          return (
            <Modal.Body>
              <ModalAlertCt>
                <FiX size={50}/>
                Você não criou seu nome de usuário ainda.
              </ModalAlertCt>
              <Button color="secondary" onPress={() => navigate("/dashboard/configs")}>Criar agora</Button>
            </Modal.Body>
          )
        }
      }

      const renderModalLink = () => {
        return(
            <Modal
                closeButton
                blur
                aria-labelledby="modal-title"
                open={linkVisible}
                onClose={() => setLinkVisible(false)}
            >
                <Modal.Header>
                <Text id="modal-title" size={18}>
                    Link para
                    <Text b size={18} style={{marginLeft: 8}}>
                    divulgacao
                    </Text>
                </Text>
                </Modal.Header>
                  {contentModal()}
                <Modal.Footer>
                <Button auto color="secondary" bordered onPress={() => setLinkVisible(false)}>
                    Fechar
                </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    
    const columns = [
        { name: "MENTORIA", uid: "mentoria" },
        { name: "TIPO", uid: "tipo" },
        { name: "LINK", uid: "share" },
        { name: "STATUS", uid: "status" },
        { name: "ACTIONS", uid: "actions" },
      ];

    const renderCell = (item, columnKey) => {
        const cellValue = item[columnKey];
        switch (columnKey) {
          case "mentoria":
            return (
                <Col>
                    <Row>
                    <Text b size={14} css={{ tt: "capitalize" }}>
                        {item.productName}
                    </Text>
                    </Row>
                    <Row>
                    <Text b size={13} css={{ tt: "capitalize", color: "$accents7" }}>
                        Code: {item.productCode}
                    </Text>
                    </Row>
                </Col>
            );
          case "tipo":
            return (
              <Col>
                <Row>
                  <Text b size={14} css={{ tt: "capitalize" }}>
                    Tipo: {item.productType}
                  </Text>
                </Row>
                <Row>
                  <Text b size={13} css={{ tt: "capitalize", color: "$accents7", display:"flex" }}>
                    Sessões: {item.productSlots} / Duração: <img src={TimeIcon} alt="duração" width={14}/> {item.meetingDuration}
                  </Text>
                </Row>
              </Col>
            );
          case "share":
            return (
              <Col>
              <Tooltip
                content="Click para ver o link"
                color="default"
                onClick={() => openModal(item)}
              >
                Ver link
              </Tooltip>
            </Col>
            );
          case "status":
            return <>{item.isActive ? <Badge color="success">ativa</Badge>: <Badge color="default">inativa</Badge>}</>
    
          case "actions":
            return (
              <Row justify="center" align="center">
                <Col css={{ d: "flex" }}>
                  <Tooltip content="Editar mentoria">
                    <Button 
                    auto 
                    size="xs" 
                    color="secondary"
                    bordered
                    onPress={() => navigate(`/dashboard/products/edit/${item.id}`)}
                    icon={<FiEdit3 size={16}/>}>
                      Editar
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            );
          default:
            return cellValue;
        }
      };

      return (
        <>
          {renderModalLink()}
          <Table
              striped
              sticked
              bordered={false}
              shadow={false}
              aria-label="Example static striped collection table"
              css={{
                  height: "auto",
                  minWidth: "100%",
              }}
              className="class-border-less"
              >
            <Table.Header columns={columns}>
              {(column) => (
                <Table.Column
                  key={column.uid}
                  hideHeader={column.uid === "actions"}
                  align={column.uid === "actions" ? "center" : "start"}
                >
                  {column.name}
                </Table.Column>
              )}
            </Table.Header>
            <Table.Body items={props.products}>
              {(item) => (
                <Table.Row>
                  {(columnKey) => (
                    <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                  )}
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </>
      );


}

export default ProductList;