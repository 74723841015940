import styled from "styled-components";

export const WalletBox = styled.div`
    display: flex;
    justify-content: space-between;
`

export const WalletCt = styled.div`
    display: flex;
    width: 100%;
    padding:0px 20px;
    justify-content: space-between;
    @media (max-width:768px) {
        box-sizing: border-box;
        /* flex:1; */
        /* flex-wrap: wrap; */
        flex-direction: column;
    }
`

export const WalletItem = styled.div`
    display: flex;
    border:1px solid #eee;
    max-width: 210px;
    padding:10px;
    border-radius: 8px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* background-color: #444; */
    box-sizing: border-box;
    p{
        font-size:12px;
    }
    @media (max-width:768px) {
        width: 100%;
        max-width: 100%;
        margin-bottom:6px;
    }
`

export const DataLoading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 32px;
    justify-content: center;
    width: 100%;
    /* margin-left:6px; */
    margin: auto;
    /* background-color: #222; */
`

export const WalletLine = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    h2{
        font-size:12px;
        font-weight: 600;
        margin-left:8px;
    }
    @media (max-width:768px) {
        justify-content: flex-start;
    }
`

export const WalletIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background:#eee;
    height: 40px;
    width: 40px;
    border-radius: 8px;
`

export const WalletInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    h1{
        font-size:26px;
        font-weight: 600;
    }
`

export const WithdrawCt = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    /* background-color: #444; */
`

export default WalletBox;