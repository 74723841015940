import { Table, Row, Col, Text, Badge } from "@nextui-org/react";
import TimeIcon from '../../Assets/icons/timeIcon.svg';
import { useEffect } from "react";
import { format } from "date-fns";
import { EmptyTableLine } from "../../styles/main";

export const WithdrawalsList = (props) => {
    
    const columns = [
        { name: "DATA", uid: "data" },
        { name: "VALOR", uid: "value" },
        { name: "STATUS", uid: "status" },
      ];

    const renderStatus = (status) => {
        switch(status) {
            case "PENDING":
                return (
                    <Badge variant="bordered" size="sm">pendente</Badge>
                )
            case "COMPLETED":
                return (
                    <Badge color="success" size="sm">completo</Badge>
                )
            case "ERROR": 
                return (
                    <Badge color="error" size="sm" variant="bordered">erro</Badge>
                )
        }
    }

    const renderCell = (item, columnKey) => {
        const cellValue = item[columnKey];
        switch (columnKey) {
          case "data":
            return (
                <Col>
                    <Row>
                    <Text size={14}>
                        {format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                    </Text>
                    </Row>
                </Col>
            );
          case "value":
            return (
              <Col>
                <Row>
                  <Text b size={14} css={{ tt: "capitalize" }}>
                    R${item.amount} 
                  </Text>
                </Row>
              </Col>
            );
          case "status":
            return renderStatus(item.status);
          default:
            return cellValue;
        }
      };

      return (
        <>
        <Table
            striped
            sticked
            lined
            bordered={false}
            shadow={false}
            selectionMode="single"
            aria-label="Example static striped collection table"
            css={{
                height: "auto",
                minWidth: "100%",
            }}
            className="class-border-less"
            >
          <Table.Header columns={columns}>
            {(column) => (
              <Table.Column
                key={column.uid}
                hideHeader={column.uid === "actions"}
                align={column.uid === "actions" ? "center" : "start"}
              >
                {column.name}
              </Table.Column>
            )}
          </Table.Header>
          <Table.Body items={props.withdrawals}>
            {(item) => (
              <Table.Row>
                {(columnKey) => (
                  <Table.Cell>{renderCell(item, columnKey)}</Table.Cell>
                )}
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {props.withdrawals.length < 1 && <EmptyTableLine>
          sem registro
        </EmptyTableLine>} 
        </>
      );


}

export default WithdrawalsList;