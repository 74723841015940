import { useEffect, useLayoutEffect, useState } from "react";
import QRCode from "react-qr-code";
import { BoxText } from "../../styles/main";
import { User } from "../../types/User";
import ContainerCenter, { ContainerLogo, ContentInfo, Item, ItemIcon, ItemText, LineInfo, ModalAlertText, SyncContainer, SyncItem } from "./styles";
import { Button, Badge, Loading, Modal, Text, Input, Spacer } from "@nextui-org/react";
import LogoVeriff from "../../Assets/Images/veriff_logo.svg";
import { BsPersonVcard, BsPhone, BsPersonBoundingBox, BsCheck } from "react-icons/bs";
import { UseApi } from "../../hooks/UseApi";
import Analytics from "../../Analytics";
import { useToast } from "@chakra-ui/react";

type Props = {
    user: User
}

const VerificationPage = ({user}: Props) => {
    const api = UseApi();
    const toast = useToast();
    const analytics = Analytics();
    const [ isReady, setIsReady ] = useState(false);
    const [ verified, setVerified ] = useState(false);
    const [ verifyStatus, setVerifyStatus ] = useState("PENDING");
    // const [ verifyStarted, setVerifyStarted ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ QRCodeLink, setQRCodeLink ] = useState("");
    const [ modalVisible, setModalVisible] = useState(false);
    const [ cpf, setCpf ] = useState("");
    const [ cpfInvalid, setCpfInvalid ] = useState(false);
    const [ documentType, setDocumentType ] = useState("DRIVERS_LICENSE");

    const closeHandler = () => {setModalVisible(false)};

    useEffect(() => {
        getMyVerification();
    },[])

    useEffect(() => {
        if (cpf.length > 1) setCpfInvalid(false);
    }, [cpf])


    const getMyVerification = async () => {
        try { 
            const data = await api.getIdentityVerify();
            if (data?.id){
                if (data?.identityStatus === "APPROVED") {
                    setVerifyStatus("APPROVED");
                    setVerified(true);
                } else {
                    setVerifyStatus("CREATED")
                    setQRCodeLink(data.url);
                }
            }   
            setIsReady(true);    
        } catch(e) {
            console.log('get veriff error');
        }
    }

    const checkStatusVerify = () => {
        const interval = setInterval(async () => {
            const data = await api.getIdentityVerify();
            if (data?.identityStatus === "STARTED") {
                setVerifyStatus("STARTED");
            }  

            if (data?.identityStatus === "SUBMITTED") {
                setVerifyStatus("SUBMITTED");
            }

            if (data?.identityStatus === "APPROVED") {
                setVerifyStatus("APPROVED");
                clearInterval(interval);
                setTimeout(() => {
                    setVerified(true);
                    setModalVisible(false);
                },400)
            }
        },2000);
    }


    const startVerification = async () => {

        if (cpf.length < 11 && verifyStatus === "PENDING") {
            setCpfInvalid(true);
            if (!toast.isActive('cpfError')) {
                toast({
                    id: 'saveError',
                    description: "Precisa preencher seu cpf antes de iniciar a verificação",
                    status: 'error',
                    duration: 2000,
                    isClosable: false,
                    position: 'top',
                })
            }
            return;
        }

        setIsLoading(true);

        if (QRCodeLink.length > 1) {
            setModalVisible(true);
            setIsLoading(false);
            return;
        }

        try {
            analytics.addEvent("InitIdentityVerify");
            const data = await api.initIdentityVerify(cpf);
            if (data?.id) {
                setVerifyStatus("CREATED")
                setQRCodeLink(data.url);
                setModalVisible(true);
                checkStatusVerify();
            }
        } catch(e) {
            console.log('verify error');
        } finally {
            setIsLoading(false);
        }
        
        
    }

    const renderVerifyStatusSync = () => {
        switch(verifyStatus) {
            case 'STARTED':
                return (
                    <SyncContainer>
                        <SyncItem>
                            <BsCheck size={32}/>
                            Verificação iniciada
                        </SyncItem>
                        <SyncItem>
                            - Aguardando documentos
                        </SyncItem>
                        <Spacer y={2}/>
                        <Loading type="points-opacity" color="secondary"/>
                    </SyncContainer>
                )
            case 'SUBMITTED':
                return (
                    <SyncContainer>
                        <SyncItem>
                            <BsCheck size={32}/>
                            Verificação iniciada
                        </SyncItem>
                        <SyncItem>
                            <BsCheck size={32}/>
                            Documentos enviados
                        </SyncItem>
                        <SyncItem>
                            - Validanto sua identidade
                        </SyncItem>
                        <Spacer y={2}/>
                        <Loading type="points-opacity" color="secondary"/>
                    </SyncContainer>
                )
            case 'APPROVED':
                return (
                    <SyncContainer>
                        <SyncItem>
                            <BsCheck size={32}/>
                            Verificação iniciada
                        </SyncItem>
                        <SyncItem>
                            <BsCheck size={32}/>
                            Documentos enviados
                        </SyncItem>
                        <SyncItem>
                            <BsCheck size={32}/>
                            Identidade verificada
                        </SyncItem>
                        <Spacer y={2}/>
                    </SyncContainer>
                )
        }
    }

    const renderQrCode = () => {
        return (
            // <QRCode
            // size={24}
            // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            // value={"https://alchemy.veriff.com/v/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2ODg3NTI4NzcsInNlc3Npb25faWQiOiI2MjdlOWY1Yi1mMWRkLTQzM2YtODk0YS01ZDgwOTk2MDgzZTQiLCJpaWQiOiIzMzAyMTc2OC1lYjU2LTQ5ODEtYjAyZC0yODk4YWI1ZTk1ZmMifQ.oRaxq4pe58HKvYf8tIN9fnkKISdAiozBZ4H-s_izm88"}
            // viewBox={`0 0 256 256`}
            // />
            <></>
        )
    }

    const renderVerificationBox = () => {
        if (verified) {
            return (
                <></>
            )
        } else {
            return (
                <ContainerCenter>
                    <ContainerLogo>
                        <img src={LogoVeriff} alt="Veriff" width={140}/>
                    </ContainerLogo>
                    <LineInfo>
                        <h2>
                            Vamos iniciar a verificação do seu documento.
                        </h2>
                    </LineInfo>
                    <ContentInfo>
                        <Item>
                            <ItemIcon>
                                <BsPersonVcard size={34}/>
                            </ItemIcon>
                            <ItemText>
                                <h2>Pegue seu documento com foto (CNH OU RG)</h2>
                                <p>Certifique-se de que ele não esteja vencido ou fisicamente danificado</p>
                            </ItemText>
                        </Item>
                        <Item>
                            <ItemIcon>
                                <BsPhone size={34} />
                            </ItemIcon>
                            <ItemText>
                                <h2>Pegue seu smartphone</h2>
                                <p>Você precisa de um smartphone para continuar.</p>
                            </ItemText>
                        </Item>
                    </ContentInfo>
                    {verifyStatus === "PENDING" && <>
                    <Input
                        aria-labelledby="documentId"
                        underlined 
                        disabled={isLoading}
                        labelLeft="CPF" 
                        value={cpf}
                        status={cpfInvalid ? 'error' : 'default'}
                        onChange={(e) => setCpf(e.target.value)}
                        placeholder="insira o cpf que vai verificar aqui."
                        />
                    </> }
                    <Spacer y={1}/>
                    { verifyStatus === "APPROVED" ? <> </> : <Button 
                    disabled={isLoading}
                    size="xl" 
                    iconRight={isLoading ? <Loading color="currentColor" size="sm" /> : <BsPersonBoundingBox size={18}/>}
                    auto
                    onClick={() => startVerification()}>
                        {(verifyStatus === "PENDING" ? <>Iniciar Verificação </> : <>Continuar Verificação</>)}
                    </Button>}
                </ContainerCenter>
            )
        }
    }

    const renderStatus = () => {
        switch(verifyStatus) {
            case "PENDING":
                return <Badge variant="bordered">Pendente</Badge>
            case "CREATED":
                return <Badge variant="bordered">Verificação iniciada</Badge>
            case "APPROVED":
                return <Badge color="success">IDENTIDADE VERIFICADA</Badge>
        }
    }

    const renderModal = () => {
        return (
            <Modal
            closeButton
            aria-labelledby="modal-title"
            open={modalVisible}
            onClose={closeHandler}
            >
                {verifyStatus === "CREATED" ? <>
                <Modal.Header>
                <Text b id="modal-title" size={18}>
                    Use a câmera do seu celular para ler esse código QR.
                </Text>
                </Modal.Header>
                <Modal.Body>
                <QRCode
                size={24}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={QRCodeLink}
                viewBox={`0 0 256 256`}
                />
                </Modal.Body>
                <Modal.Footer>
                   <ModalAlertText>
                    Para saber mais sobre a segurança dos seus dados pessoais. <a target="blank" href="https://www.veriff.com/privacy-notice?navigation=slim&lang=pt">clique aqui.</a>
                   </ModalAlertText>
                </Modal.Footer>
                </> : renderVerifyStatusSync()}
            </Modal>
        )
    }

    return (
        <>
        <BoxText>
            <h2>Verificação de identidade:</h2>
            <p>Para poder iniciar suas vendas, precisamos verificar sua identidade.</p>
            <p>Status da sua identidade: {renderStatus()}</p>
            </BoxText>
            {isReady && <>
                {renderVerificationBox()}
                {renderQrCode()}
                {renderModal()}
            </>}
        </>
    )
}

export default VerificationPage;